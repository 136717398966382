import { Col, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Button';
import Footer from '../Footer';
import { H1 } from '../Headings';
import { PageSection } from '../Sections';
import { Text } from '../Text';
import pricing from '../../assets/hintataulukko_v2000.png';

export const Pricing = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageSection className="m-3">
        <Row className="justify-content-center">
          <Col lg={10}>
            <H1>Hinnoittelumme</H1>
            <Image width="1200px" src={pricing} alt="Fixed Legal hinnoittelu" fluid />
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col lg={10}>
            <Text size="md" dimmed>
              Voit kokeilla sitoumuksetta. Tee ensimmäinen sopimus tutustumishintaan 9,90€. Liitteet teet maksutta.
            </Text>
            <Text size="md" dimmed>
              Mikäli haluat tarjouksen lisäpalveluista, lähetä tarjouspyyntö osoitteeseen
              info(at)fixed-legal.com
            </Text>
            <div className="mt-5">
              <Button buttonsize="lg" onClick={() => navigate('/tee-sopimus')}>
                Kokeile nyt
              </Button>
            </div>
          </Col>
        </Row>
      </PageSection>
      <Footer />
    </>
  );
};
