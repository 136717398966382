import {
  ContractContextData,
  ContractScope,
  Lang,
  User,
} from '@smarterbiz/fixedlegal-shared';
import { format } from 'date-fns';
import fi from 'date-fns/locale/fi';
import { Form, Formik, FormikProps } from 'formik';
import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { lazy } from 'yup';
import {
  contractHasTermsSelector,
  contractScopesSelector,
  requireSignaturesSelector,
} from '../../../features/configuration/selectors';
import { useInfoModal } from '../../../features/modal/hooks';
import { dateBasedOnLocale } from '../../../helpers/date';
import {
  contractSchema,
  contractSchemaWithoutSignatures,
  saasContractSchema,
} from '../../../helpers/validationSchemas';
import useSubmitContractForm from '../../../hooks/useSubmitContract';
import { useAppSelector, useAuth } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { Button } from '../../Button';
import StyledErrorMessage from '../../ErrorMessage';
import { FormikCheckInput, StyledLabel } from '../../Form/FormikInput';
import { H4 } from '../../Headings';
import { Modal } from '../../Modal';
import { Text } from '../../Text';
import ContractParty from '../ContractParty';
import ContractOptionCheckboxes from './ContractOptionCheckboxes';
import ContractOptionRadio from './ContractOptionRadio';
import { Party1Signatures } from './Party1Signatures';
import { Party2Signatures } from './Party2Signatures';
import { ContractConfigurationFormType } from './types';
import { ConfigurationInfo } from './ConfigurationInfo';

type Props = {
  user?: User;
  contractData?: ContractContextData;
};

export const ConfigureContract = ({ user, contractData }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { selectedContractData, selectedContractId } = useAppSelector(
    (state: RootState) => state.configuration
  );
  const { isAuthenticated } = useAuth();

  const { authenticatedContractSubmission, unauthenticatedContractSubmission } =
    useSubmitContractForm();

  const contractScopes = useAppSelector(contractScopesSelector);
  const signaturesRequired = useAppSelector(requireSignaturesSelector);
  const contractHasTerms = useAppSelector(contractHasTermsSelector);

  const hasGeneralTermsSelected = (
    props: FormikProps<ContractConfigurationFormType>
  ) => {
    return props.values.scopes?.some(
      (scope) => scope.inclusion === 'GENERAL_T_AND_CS'
    );
  };

  const initialDate = format(new Date(), 'PPPP', { locale: fi });

  const initialValues: ContractConfigurationFormType = useMemo(() => {
    return {
      id: selectedContractData?.id,
      relation: 'supplier',
      lang: Lang.FI,
      date: initialDate,
      scopes: contractScopes,
      party1: {
        fillLater: false,
        validate: false, // false by default because initial relation is supplier (party1) used for validationSchema, not sent in request
        id: '',
        name: '',
        address: '',
        addressCityCode: '',
      },
      party2: {
        fillLater: false,
        validate: true, // true by default because initial relation is supplier (party1)  used for validationSchema, not sent in request
        id: '',
        name: '',
        address: '',
        addressCityCode: '',
      },
      party1Signatures: {
        signeeName: undefined,
        signeeTitle: undefined,
        signeeAddress: undefined,
        signeeTime: initialDate,
        signLater: false,
      },
      party2Signatures: {
        signeeName: undefined,
        signeeTitle: undefined,
        signeeAddress: undefined,
        signeeTime: initialDate,
        signLater: false,
      },
      // these are used for validating correct fields in validation schema
      // e.g. if continuousTerm is selected (=true)
      //  -> then noticePeriodParty1InMonths, noticePeriodParty2InMonths are validated
      continuousTerm: true,
      fixedTerm: false,
      subscriptionTerm: false,
      termsDisabled: false,
      contractHasTerms: contractHasTerms, // if contract's scopes include TERM inclusion type, term contractHasTerms will be set to true for validating terms

      fixedTermInMonths: undefined,
      initialTermInMonths: undefined,
      renewalTermInMonths: undefined,
      noticePeriodMutualInMonths: undefined,
      noticePeriodParty1InMonths: undefined,
      noticePeriodParty2InMonths: undefined,
      noticePeriodParty1InDays: undefined,
      noticePeriodParty2InDays: undefined,
    };
  }, [contractScopes, initialDate, selectedContractData?.id, contractHasTerms]);

  const submitFn = async (values: ContractConfigurationFormType) => {
    values.date = dateBasedOnLocale(values.lang);
    if (!isAuthenticated) {
      unauthenticatedContractSubmission(selectedContractId!, values);
      navigate(`/tee-sopimus/${selectedContractId}/yhteenveto`);
    }
    if (isAuthenticated) {
      const res = await authenticatedContractSubmission(user!, values);
      if (res) {
        navigate(`/tee-sopimus/${res.id}/valmis`);
      }
    }
  };

  const handleSubmitSeparatePurchase = async (
    values: ContractConfigurationFormType
  ) => {
    try {
      if (user) {
        const res = await authenticatedContractSubmission(user, values, true);
        if (res) {
          navigate(`/tee-sopimus/${res.id}/valmis`);
        }
      }
    } catch (error) {
      toast.error('Sopimuksen generointi epäonnistui');
    } finally {
      setIsOpen(false);
    }
  };

  const noCreditsRemaining =
    user && user.contractsLeft === 0 && user.credits === 0;

  const dispatchInfoModal = useInfoModal();

  return (
    <Formik<ContractConfigurationFormType>
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={() =>
        lazy((values: ContractConfigurationFormType) => {
          /**
           * Return a validation schema without signatures if saas agreement and general t and cs is selected
           */
          const isGeneralTermSelected =
            values.scopes &&
            values.scopes?.some(
              (scope) => scope.inclusion === 'GENERAL_T_AND_CS'
            );
          if (
            selectedContractId === 'SaaS_Agreement_B2B_bilateral' &&
            isGeneralTermSelected
          ) {
            return saasContractSchema;
          }
          if (!signaturesRequired) {
            return contractSchemaWithoutSignatures;
          }
          return contractSchema;
        })
      }
      onSubmit={async (values: ContractConfigurationFormType) => {
        if (noCreditsRemaining) {
          setIsOpen(!isOpen);
        } else {
          await submitFn(values);
        }
      }}
    >
      {(props: FormikProps<ContractConfigurationFormType>) => (
        <Form>
          <Row className="mt-5 text-center">
            <H4>LUO DOKUMENTTI</H4>
          </Row>
          <StyledRow>
            <Text weight="bold">
              Räätälöi aluksi dokumentin sisältö vastaamaan tarpeitasi
              vastaamalla alla oleviin kysymyksiin
            </Text>
          </StyledRow>
          {selectedContractId ? (
            <Row className="mt-3">
              <ConfigurationInfo selectedContractId={selectedContractId} />
            </Row>
          ) : null}
          {selectedContractId ===
          'Confidentiality_Agreement_personal' ? null : selectedContractId ===
            'Instructions_on_the_Use_of_AI' ? null : (
            <Row className="mb-5">
              <Col xs={10} md={6}>
                <input
                  id="supplier"
                  type="radio"
                  name="relation"
                  className="form-check-input"
                  checked={props.values.relation === 'supplier'}
                  value="supplier"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.setFieldValue('relation', e.target.value);
                    // set validations for party accordingly to relation
                    props.setFieldValue('party2.validate', true);
                    props.setFieldValue('party1.validate', false);
                  }}
                />
                <StyledLabel htmlFor="supplier">
                  {selectedContractData
                    ? selectedContractData.party1.desc
                    : 'Toimittaja'}
                </StyledLabel>
              </Col>
              <Col xs={10} md={6}>
                <input
                  id="customer"
                  type="radio"
                  name="relation"
                  className="form-check-input"
                  checked={props.values.relation === 'customer'}
                  value="customer"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.setFieldValue('relation', e.target.value);
                    // set validations for party accordingly to relation
                    props.setFieldValue('party2.validate', false);
                    props.setFieldValue('party1.validate', true);
                  }}
                />
                <StyledLabel htmlFor="customer">
                  {selectedContractData
                    ? selectedContractData.party2.desc
                    : 'Asiakas'}
                </StyledLabel>
              </Col>
            </Row>
          )}
          <Row>
            <Text>Minkäkielisen dokumentin haluat?</Text>
          </Row>
          <Row className="mb-3">
            <Col xs={10} md={6}>
              <FormikCheckInput
                id="lang-fi"
                type="radio"
                name="lang"
                checked={props.values.lang === 'FI'}
                value={Lang.FI}
              >
                Suomenkielisen
              </FormikCheckInput>
            </Col>
            <Col xs={10} md={6}>
              <FormikCheckInput
                id="lang-en"
                type="radio"
                name="lang"
                checked={props.values.lang === 'EN'}
                value={Lang.EN}
              >
                Englanninkielisen
              </FormikCheckInput>
            </Col>
          </Row>
          <div style={{ marginBottom: '6rem' }}>
            {contractData &&
              contractData.scopes?.map((scope: ContractScope, index: number) =>
                !scope.multiselect ? (
                  <ContractOptionRadio
                    index={index}
                    key={scope.type}
                    item={scope}
                  />
                ) : null
              )}
          </div>
          {/* TODO: Make contract ID based renderings to be simpler */}
          {selectedContractId === 'SaaS_Agreement_B2B_bilateral' &&
          hasGeneralTermsSelected(props) ? null : !signaturesRequired ? null : (
            // dont return party/signature inputs if SaaS agreement and general terms is selected
            <>
              <StyledRow>
                <Text weight="bold">
                  Seuraavaksi täytä sopimuksen perustiedot
                </Text>
              </StyledRow>
              <Row className="mb-2 mt-4">
                <Text>
                  <u>Lisää toisen sopijapuolen perustiedot</u>
                </Text>
              </Row>
              <Row className="mb-4">
                <Text>Edustamasi organisaation tiedot lisätään myöhemmin.</Text>
              </Row>
              <Row className="mb-4">
                <Text>
                  Voit jättää y-tunnuksen tyhjäksi, jos sopimuskumppanisi ei ole
                  suomalainen. Sisällytä tällöin vain sopimuskumppanisi osoite,
                  mukaan lukien maa, osoitekenttään englanniksi.
                </Text>
              </Row>
              <ContractParty />
              <Row className="mb-4 mt-5">
                <Text>
                  <u>Lisää kummankin sopijapuolen allekirjoittajien tiedot</u>
                </Text>
              </Row>
              <Party1Signatures />
              <Party2Signatures />
            </>
          )}
          {selectedContractData?.scopes?.map((scope: ContractScope) =>
            scope.multiselect ? (
              <React.Fragment key={scope.type}>
                <StyledRow>
                  <Text weight="bold">
                    Lopuksi valitse sopimuksen liiteluetteloon tulevat liitteet
                  </Text>
                </StyledRow>
                <Row>
                  <Text>
                    Valitsemasi sopimusliitteet mainitaan sopimuksen
                    liiteluettelossa, mutta liitteiden sisältö ei generoidu
                    automaattisesti sopimukseen. Valitse vain liitteet, jotka
                    aiot liittää sopimukseen.
                  </Text>
                  <Text>
                    Tarvitset yleensä vähintään liitteen, jossa ilmoitetaan
                    hinnat, kuten hintaliitteen. Hintaliitteessä tulee olla
                    hinta valuuttoineen (esimerkiksi 100 euroa) ja mitä se
                    kattaa (esimerkiksi 100 euroa per tunti konsultin työtä).
                    Saat tarkemmat ohjeet hintaliitteen tekoon Fixed Legalin
                    liitteet-valikosta.
                  </Text>
                  <Text>
                    Mikäli toinen sopijapuolista käsittelee henkilötietoja
                    toisen sopijapuolen lukuun, tarvitset henkilötietojen
                    käsittelyliitteen.
                  </Text>
                  <Text>Muut liiteet ovat valinnaisia.</Text>
                  <Text>
                    Voit luoda osan liitteistä Fixed Legalilla. Loput saat
                    omista erillisistä dokumenteistasi tai toiselta
                    sopijapuolelta.
                  </Text>
                </Row>
                <ContractOptionCheckboxes item={scope} />
              </React.Fragment>
            ) : null
          )}
          <StyledRow>
            <Col xs={6} md={8}>
              {props.submitCount > 0 && !props.isValid ? (
                <div className="mb-4">
                  <StyledErrorMessage>
                    Täydennettäviä tietoja puuttuu. Täytä puuttuvat kohdat yllä
                    jatkaaksesi
                  </StyledErrorMessage>
                </div>
              ) : null}
              <Text>Räätälöinti on valmis tehtyäsi kaikki valinnat.</Text>
              <Text>Paina Jatka-painiketta päästäksesi yhteenvetoon.</Text>
            </Col>
            <Col xs={6} md={4} className="h-25 d-flex justify-content-end">
              <Button type="submit" buttonsize="sm">
                Jatka
              </Button>
            </Col>
          </StyledRow>
          <Modal
            title="Tilauksesi"
            body="Sinulla ei ole luontikertoja jäljellä. Voit ostaa yksittäisen sopimuksen hintaan 99€. Voit hyväksyä maksun painamalla jatka"
            isOpen={isOpen}
            handleClose={() => setIsOpen(!isOpen)}
            withCallback={{
              cb: async () => {
                await handleSubmitSeparatePurchase(props.values);
              },
              buttonText: 'Jatka',
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

const StyledRow = styled(Row)`
  margin-top: 3.5rem;
  margin-bottom: 1rem;
`;
