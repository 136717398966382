import { ContractScope } from '@smarterbiz/fixedlegal-shared';
import { Col, Form, FormCheck, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { FormikNumberInput } from '../Form/FormikInput';
import { Text } from '../Text';
import { ContractDescription } from './Configuration/ContractOptionRadio';

type Props = {
  scope: ContractScope;
};

const PricingOption = ({ scope }: Props) => {
  return (
    <>
      <div className="d-flex flex-row justify-content-between mt-4 mb-4 align-items-center">
        <ContractDescription>{scope.desc}</ContractDescription>
      </div>
      <Form.Group
        as={Row}
        controlId="formBias"
        className="justify-content-center"
      >
        <Col md={10}>
          <div className="my-4">
            <Row className="my-2">
              <Col xs={1} md={2} className="d-flex align-items-center">
                <FormCheck name="asdasd" />
              </Col>
              <Col md={4}>
                <Text>
                  Kokeneen henkilön hinnankorotusprosentti perushinnan päälle
                </Text>
              </Col>
              <StyledCol md={2} className="d-flex align-items-center">
                <FormikNumberInput
                  id="monthValue"
                  key="monthValue"
                  name="basicPriceAppendixHourlyRateSeniorPercentage"
                  placeholder="0"
                />
              </StyledCol>
              <Col md={3} className="d-flex align-items-center">
                <Text>%</Text>
              </Col>
            </Row>
            <Row className="my-2">
              <Col xs={2} className="d-flex align-items-center">
                <FormCheck name="asdasd" />
              </Col>
              <Col md={4} className="flex-1">
                <Text>
                  Junior-tason henkilön hinnanalennusprosentti perushinnasta
                </Text>
              </Col>
              <StyledCol md={2} className="d-flex align-items-center">
                <FormikNumberInput
                  id="monthValue"
                  key="monthValue"
                  name="basicPriceAppendixHourlyRateJuniorPercentage"
                  placeholder="0"
                />
              </StyledCol>
              <Col md={3} className="d-flex align-items-center">
                <Text>%</Text>
              </Col>
            </Row>
            <Row className="my-2">
              <Col xs={2} className="d-flex align-items-center">
                <FormCheck name="asdasd" />
              </Col>
              <Col md={4}>
                <Text>Harjoittelijan hinnanalennusprosentti perushinnasta</Text>
              </Col>
              <StyledCol md={3} className="d-flex align-items-center">
                <FormikNumberInput
                  id="monthValue"
                  key="monthValue"
                  name="basicPriceAppendixHourlyRateTraineePercentage"
                  placeholder="0"
                />
              </StyledCol>
              <Col md={1} className="d-flex align-items-center">
                <Text>%</Text>
              </Col>
            </Row>
          </div>
        </Col>
      </Form.Group>
    </>
  );
};

export default PricingOption;

const StyledCol = styled(Col)`
  width: 10%;
  display: flex;
  align-items: center;
`;
