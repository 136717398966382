import { useAuth } from '../redux/hooks';
import HomePage from '../pages/Home';
import DocumentsPage from '../pages/Documents';

const IndexRouteResolver = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <DocumentsPage />;
  }
  return <HomePage />;
};

export default IndexRouteResolver;
