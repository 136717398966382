import { Col, Row } from 'react-bootstrap';
import { FormikNumberInput } from '../../Form/FormikInput';
import { Text } from '../../Text';

export const FixedPricing = () => {
  return (
    <>
      <Row className="mt-4 justify-content-center mb-2">
        <Col md={4}>
          <Text>Anna kiinteä kokonaishinta</Text>
        </Col>
        <Col md={3}>
          <FormikNumberInput name="asd" id="asd" />
        </Col>
        <Col md={2}>
          <Text>€</Text>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5 mb-2">
        <Col md={9}>
          <Text>Määrittele maksuaikataulu</Text>
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col md={4}>
          <Text>Prosenttiosuus sopimuksen allekirjoitushetkellä</Text>
        </Col>
        <Col md={3}>
          <FormikNumberInput name="asd" id="asd" />
        </Col>
        <Col md={2}>
          <Text>%</Text>
        </Col>
        <Col md={4}>
          <Text>Prosenttiosuus, kun puolet toimituksesta on tehty</Text>
        </Col>
        <Col md={3}>
          <FormikNumberInput name="asd" id="asd" />
        </Col>
        <Col md={2}>
          <Text>%</Text>
        </Col>
        <Col md={4}>
          <Text>Prosenttiosuus, kun toimitus on toimitettu</Text>
        </Col>
        <Col md={3}>
          <FormikNumberInput name="asd" id="asd" />
        </Col>
        <Col md={2}>
          <Text>%</Text>
        </Col>
      </Row>
    </>
  );
};
