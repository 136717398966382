import { Col, Container, Row, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { mediaLargeMax, mediaXXLargeMax } from '../../theme/media';
import { H1, H3 } from '../Headings';
import { useAppSelector } from '../../redux/hooks';
import { DocumentsList } from './DocumentsList';
import { Text } from '../Text';
import {
  useAppendixesQuery,
  useContractsQuery,
  useMyDocumentsQuery,
} from '../../api/fixed';
import {
  generatedAppendixesSelector,
  generatedContractsSelector,
} from '../../features/generated/selectors';

const HeadingRow = styled(Row)`
  justify-content: center;
  align-items: center;
  margin-top: 10rem;

  @media ${mediaLargeMax} {
    margin-top: 1rem;
  }

  @media ${mediaXXLargeMax} {
    margin-top: 3rem;
  }
`;

const DocumentsContainer = styled(Row)`
  justify-content: center;
  margin-top: 3rem;
`;

export const Documents = () => {
  const { data: contractsData } = useContractsQuery();
  const { data: appendixesData } = useAppendixesQuery();
  const { isLoading: documentsLoading } = useMyDocumentsQuery();

  const generatedContracts = useAppSelector(generatedContractsSelector);
  const generatedAppendixes = useAppSelector(generatedAppendixesSelector);

  return (
    <Container fluid>
      <HeadingRow>
        <Col xl={10} className="d-flex flex-column gap-5">
          <H1>LUOMASI DOKUMENTIT</H1>
          <Text size="md">
            Kukin luomasi dokumentti säilyy vähintään 30 päivää
          </Text>
        </Col>
      </HeadingRow>
      <DocumentsContainer>
        <Col xl={5} md={6}>
          <H3>Luomasi sopimukset</H3>
          <div className="pt-4">
            {documentsLoading ? (
              <Spinner animation="border" variant="primary" />
            ) : null}
            {generatedContracts.length === 0 ? (
              <Text size="sm">Sinulla ei ole sopimuksia</Text>
            ) : (
              <DocumentsList documents={generatedContracts} />
            )}
          </div>
        </Col>
        <Col xl={5} md={6} className="pt-sm-5 pt-md-0 pt-xl-0">
          <H3>Luomasi sopimusliitteet</H3>
          <div className="pt-4">
            {documentsLoading ? (
              <Spinner animation="border" variant="primary" />
            ) : null}
            {generatedAppendixes.length === 0 ? (
              <Text size="sm">Sinulla ei ole liitteitä</Text>
            ) : (
              <DocumentsList documents={generatedAppendixes} />
            )}
          </div>
        </Col>
      </DocumentsContainer>
    </Container>
  );
};
