import React, { useRef, useState } from 'react';
import {
  Formik,
  Form,
  Field,
  FormikProps,
  ErrorMessage as FormikErrorMessage,
  FieldProps,
} from 'formik';
import { Col, Container, FormGroup, FormLabel, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { mediaLargeMax } from '../../theme/media';
import { Button, TextButton } from '../Button';
import { FormikCheckInput, FormikTextInput } from '../Form/FormikInput';
import { H1, H4 } from '../Headings';
import { Text } from '../Text';
import Toggle from '../Toggle';
import { useNavigate } from 'react-router-dom';
import { AccountSchema } from '../../helpers/validationSchemas';
import {
  UserAgreementType,
  SignUp,
  UserRole,
  UserStatus,
} from '@smarterbiz/fixedlegal-shared';
import toast from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import { hashData } from '../../helpers/crypto';
import StyledErrorMessage from '../ErrorMessage';
import { useSignUpMutation } from '../../api/auth';
import { useAppDispatch } from '../../redux/hooks';
import { setConfigurationOrder } from '../../features/configuration/configurationSlice';
import {
  useDataProtectionModal,
  useTermModal,
} from '../../features/modal/hooks';
import { clone } from 'lodash';
import { EInvoicingFields } from './EInvoicingFields';
import { Honeypot } from '../Form/Honeypot';

export type RegistrationFormType = {
  companyId: string;
  companyName: string;
  address: string;
  addressPostcodeCity: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  repeatPassword: string;
  billingEmail: string;
  eInvoiceOperatorId?: string;
  eInvoiceAddress?: string;
  paymentInAdvance: boolean;
  agreementType: UserAgreementType;
  userRole: UserRole;
  agreeTerms: boolean;
  agreeDataProcessing: boolean;
  wantsEmailInvoice: boolean;
};

export const Registration = () => {
  const [signUp] = useSignUpMutation();
  const dispatch = useAppDispatch();
  const captchaKey = '6LfuRGkgAAAAAAsSlfiXafQdD94_jV62mrAbcf02';
  const [honeypotValue, setHoneypotValue] = useState('');
  const [checked, setChecked] = useState(false);
  const handlePaymentCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };
  const navigate = useNavigate();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const dispatchTermModal = useTermModal();
  const dispatchDataProtectionModal = useDataProtectionModal();

  const initialValues: RegistrationFormType = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    companyId: '',
    companyName: '',
    address: '',
    addressPostcodeCity: '',
    password: '',
    repeatPassword: '',
    billingEmail: '',
    eInvoiceAddress: '',
    eInvoiceOperatorId: '',
    paymentInAdvance: false,
    agreementType: UserAgreementType.NO_PACKAGE,
    userRole: UserRole.BASIC,
    agreeTerms: false,
    agreeDataProcessing: false,
    wantsEmailInvoice: false,
  };

  const handleSubmit = async (userData: SignUp) => {
    try {
      // create hashed email
      const hash = hashData(userData.user.email);
      // handle storing contract data under email hash in localStorage
      dispatch(setConfigurationOrder({ id: hash }));
      await signUp(userData).unwrap();
      navigate('/tilaus-lahetetty');
    } catch (error: any) {
      // Quick and dirty error handling, TODO: return appropriate error code from api
      if (error && error.data.error === 'Duplicate email') {
        toast.error('Antamasi sähköpostiosoite on jo käytössä.');
        return;
      }
      toast.error('Odottamaton virhe rekisteröinnissä.');
    }
  };

  // quick and dirty sanitization to get rid of einvoice properties
  const sanitizeRequestData = (values: SignUp) => {
    const formData = clone(values);
    delete formData.user.eInvoiceOperatorId;
    delete formData.user.eInvoiceAddress;
    formData.user.billingEmail = values.user.email;
    return formData;
  };

  return (
    <StyledContainer fluid>
      <Formik
        enableReinitialize={false}
        initialValues={initialValues}
        validationSchema={AccountSchema}
        validateOnChange={true}
        onSubmit={async (values: RegistrationFormType) => {
          // if honeypot is filled, do nothing
          if (honeypotValue !== '') {
            return;
          }
          const captchaToken = await captchaRef.current?.executeAsync();
          captchaRef.current?.reset();
          const data: SignUp & { token: string } = {
            user: {
              name: `${values.firstName} ${values.lastName}`,
              email: values.email,
              phone: values.phone,
              companyAddress: [values.address, values.addressPostcodeCity],
              companyId: values.companyId,
              companyName: values.companyName,
              eInvoiceOperatorId: values.eInvoiceOperatorId,
              eInvoiceAddress: values.eInvoiceAddress,
              paymentInAdvance: checked,
              agreementType: values.agreementType,
              status: UserStatus.PENDING,
              role: UserRole.BASIC,
            },
            password: values.password,
            token: captchaToken!,
          };
          // if user wants email invoice, we need to sanitize the request data and remove eInvoice properties
          if (values.wantsEmailInvoice) {
            const sanitized = sanitizeRequestData(data);
            handleSubmit(sanitized);
            return;
          }
          handleSubmit(data);
        }}
      >
        {(props: FormikProps<RegistrationFormType>) => (
          <Form>
            <StyledSecondaryDiv>
              <FormRow
                css={`
                  padding-top: 5rem;
                `}
              >
                <H1>REKISTERÖI YRITYSTILI</H1>
              </FormRow>
              <FormRow className="mb-5">
                <Col sm={12} md={6}>
                  <div className="pb-5">
                    <H4>Täytä yrityksen perustiedot</H4>
                  </div>
                  <FormGroup
                    as={Row}
                    className="mb-3"
                    controlId="formBasicCompanyName"
                  >
                    <FormLabel column md={5}>
                      <Text size="md">
                        <RequiredSpan>Organisaation nimi:</RequiredSpan>
                      </Text>
                    </FormLabel>
                    <Col md={6} sm={12}>
                      <FormikTextInput placeholder="Nimi" name="companyName" />
                    </Col>
                  </FormGroup>
                  <FormGroup
                    as={Row}
                    className="mb-3"
                    controlId="formBasicCompanyId"
                  >
                    <FormLabel column md={5}>
                      <Text size="md">
                        <RequiredSpan>Y-tunnus:</RequiredSpan>
                      </Text>
                    </FormLabel>
                    <Col md={6} sm={12}>
                      <FormikTextInput
                        placeholder="Y-tunnus"
                        name="companyId"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row} className="mb-3">
                    <FormLabel column md={5}>
                      <Text size="md">
                        <RequiredSpan>Yrityksen postiosoite:</RequiredSpan>
                      </Text>
                    </FormLabel>
                    <Col md={6} sm={12}>
                      <div>
                        <FormikTextInput
                          id="address"
                          placeholder="Katuosoite tai postilokero"
                          name="address"
                        />
                      </div>
                      <div className="mt-3">
                        <FormikTextInput
                          id="addressPostcode"
                          placeholder="Postinumero ja kaupunki"
                          name="addressPostcodeCity"
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <EInvoicingFields />
                </Col>
                <StyledCol sm={12} md={6}>
                  <StyledSpan>
                    <Text size="md">Olet vain muutaman askeleen päässä</Text>
                  </StyledSpan>
                  <StyledSpan>
                    <Text size="md">
                      1. Täytä yrityksen perus- ja laskutustiedot
                    </Text>
                  </StyledSpan>
                  <StyledSpan>
                    <Text size="md">2. Täytä käyttäjän tiedot</Text>
                  </StyledSpan>
                  <StyledSpan>
                    <Text size="md">
                      3. Valitse tutustumistarjous tai palvelupaketti
                    </Text>
                  </StyledSpan>
                  <StyledSpan>
                    <Text size="md">4. Vahvista sähköposti</Text>
                  </StyledSpan>
                  <StyledSpan>
                    <Text size="md">... ja olet valmis!</Text>
                  </StyledSpan>
                </StyledCol>
              </FormRow>
            </StyledSecondaryDiv>
            <div>
              <FormRow>
                <H4>Lisää käyttäjän tiedot</H4>
              </FormRow>
              <FormRow>
                <Col sm={12} md={6}>
                  <FormGroup as={Row} className="mb-3">
                    <FormLabel column md={5}>
                      <Text size="md">
                        <RequiredSpan>Käyttäjän nimi:</RequiredSpan>
                      </Text>
                    </FormLabel>
                    <Col md={6} sm={12}>
                      <div>
                        <FormikTextInput
                          id="firstName"
                          placeholder="Etunimi"
                          name="firstName"
                        />
                      </div>
                      <div className="mt-3">
                        <FormikTextInput
                          id="lastName"
                          placeholder="Sukunimi"
                          name="lastName"
                        />
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup
                    as={Row}
                    className="mb-3"
                    controlId="formBasicUserPhone"
                  >
                    <FormLabel column md={5}>
                      <Text size="md">
                        <RequiredSpan>Puhelinnumero:</RequiredSpan>
                      </Text>
                    </FormLabel>
                    <Col md={6} sm={12}>
                      <FormikTextInput
                        placeholder="Puhelinnumero"
                        name="phone"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup
                    as={Row}
                    className="mb-3"
                    controlId="formBasicUserEmail"
                  >
                    <FormLabel column md={5}>
                      <Text size="md">
                        <RequiredSpan>Sähköposti:</RequiredSpan>
                      </Text>
                    </FormLabel>
                    <Col md={6} sm={12}>
                      <FormikTextInput placeholder="Sähköposti" name="email" />
                    </Col>
                    <Honeypot honeySetter={setHoneypotValue} />
                  </FormGroup>
                  <FormGroup
                    as={Row}
                    className="mb-3"
                    controlId="formBasicUserPassword"
                  >
                    <FormLabel column md={5}>
                      <Text size="md">
                        <RequiredSpan>Luo salasana:</RequiredSpan>
                      </Text>
                    </FormLabel>
                    <Col md={6} sm={12}>
                      <FormikTextInput
                        type="password"
                        placeholder="Salasana"
                        name="password"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup
                    as={Row}
                    className="mb-3"
                    controlId="formBasicUserRePassword"
                  >
                    <FormLabel column md={5}>
                      <Text size="md">
                        <RequiredSpan>Salasana uudelleen:</RequiredSpan>
                      </Text>
                    </FormLabel>
                    <Col md={6} sm={12}>
                      <FormikTextInput
                        type="password"
                        placeholder="Salasana"
                        name="repeatPassword"
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={6}>
                  <Text size="md">
                    Antamasi sähköpostiosoite toimii käyttäjätunnuksenasi.
                  </Text>
                </Col>
              </FormRow>
            </div>
            <StyledDiv>
              <FormRow>
                <H4>Valitse sopiva palvelu</H4>
              </FormRow>
              <FormRow>
                <Col sm={12} md={6}>
                  <RadioInputGroup>
                    <Col xs={1}>
                      <FormikCheckInput
                        id="noPackage"
                        type="radio"
                        name="agreementType"
                        checked={
                          props.values.agreementType ===
                          UserAgreementType.NO_PACKAGE
                        }
                        value={UserAgreementType.NO_PACKAGE}
                        style={{ transform: 'scale(1.3)' }}
                      />
                    </Col>
                    <Col xs={8}>
                      <Text size="md">
                        Yksittäinen sopimus tutustumishintaan 9,90€
                      </Text>
                    </Col>
                  </RadioInputGroup>
                  <RadioInputGroup>
                    <Col xs={1}>
                      <FormikCheckInput
                        id="litePackage"
                        type="radio"
                        name="agreementType"
                        checked={
                          props.values.agreementType === UserAgreementType.LITE
                        }
                        value={UserAgreementType.LITE}
                        style={{ transform: 'scale(1.3)' }}
                      />
                    </Col>
                    <Col xs={8}>
                      <Text size="md">
                        {!checked
                          ? 'Lite Fix 24,90€/kk + 99€ aloitusmaksu'
                          : 'Lite Fix 273,90€/vuosi eli vain 22,80/kk + 99e aloitusmaksu'}
                      </Text>
                    </Col>
                  </RadioInputGroup>
                  <RadioInputGroup>
                    <Col xs={1}>
                      <FormikCheckInput
                        id="essentialPackage"
                        type="radio"
                        name="agreementType"
                        checked={
                          props.values.agreementType ===
                          UserAgreementType.ESSENTIAL
                        }
                        value={UserAgreementType.ESSENTIAL}
                        style={{ transform: 'scale(1.3)' }}
                      />
                    </Col>
                    <Col xs={8}>
                      <Text size="md">
                        {!checked
                          ? 'Essential Fix 34,90€/kk + 99€ aloitusmaksu'
                          : 'Essential Fix 383,90€ eli vain 32,00/kk + 99e aloitusmaksu'}
                      </Text>
                    </Col>
                  </RadioInputGroup>
                  <RadioInputGroup>
                    <Col xs={1}>
                      <FormikCheckInput
                        id="unlimitedPackage"
                        type="radio"
                        name="agreementType"
                        checked={
                          props.values.agreementType ===
                          UserAgreementType.UNLIMITED
                        }
                        value={UserAgreementType.UNLIMITED}
                        style={{ transform: 'scale(1.3)' }}
                      />
                    </Col>
                    <Col xs={8}>
                      <Text size="md">
                        {!checked
                          ? 'Unlimited Fix 34,90€/kk + 99€ aloitusmaksu'
                          : 'Unlimited Fix 383,90€ eli vain 32,00/kk + 99e aloitusmaksu'}
                      </Text>
                    </Col>
                  </RadioInputGroup>
                  <Row className="mt-5 mb-5">
                    <Col sm={2} className="float-left">
                      <Toggle
                        checked={checked}
                        handleCheck={handlePaymentCheck}
                        name="paymentInAdvance"
                      />
                    </Col>
                    <Col md={10}>
                      <Text size="md">
                        Haluan maksaa vuoden etukäteen ja säästää yhden
                        kuukauden maksun
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={6} className="mt-3 align-self-end">
                  <FormGroup as={Row} controlId="formTerms">
                    <Col xs={1}>
                      <div style={{ minWidth: '20rem' }}>
                        <Field name="agreeTerms">
                          {({ field }: FieldProps) => (
                            <input
                              {...field}
                              type="checkbox"
                              className="form-check-input"
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={11} md={6}>
                      <Text size="md" dimmed>
                        Hyväksyn
                        <TextButton
                          type="button"
                          text="käyttöehdot"
                          onClick={dispatchTermModal}
                        />
                      </Text>
                      <div>
                        <FormikErrorMessage name="agreeTerms">
                          {(message) => (
                            <StyledErrorMessage>{message}</StyledErrorMessage>
                          )}
                        </FormikErrorMessage>
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row} controlId="formDataProcessing">
                    <Col xs={1}>
                      <div style={{ width: '20rem' }}>
                        <Field name="agreeDataProcessing">
                          {({ field }: FieldProps) => (
                            <input
                              {...field}
                              type="checkbox"
                              className="form-check-input"
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={11} md={6}>
                      <Text size="md" dimmed>
                        Olen lukenut
                        <TextButton
                          type="button"
                          text="tietosuojaselosteen"
                          onClick={dispatchDataProtectionModal}
                        />
                      </Text>
                      <div>
                        <FormikErrorMessage name="agreeDataProcessing">
                          {(message) => (
                            <StyledErrorMessage>{message}</StyledErrorMessage>
                          )}
                        </FormikErrorMessage>
                      </div>
                    </Col>
                  </FormGroup>
                  <Row
                    className="gap-5"
                    style={{
                      marginBottom: '4rem',
                      marginTop: '3rem',
                    }}
                  >
                    <Col md={2} className="align-self-end">
                      <Button
                        type="submit"
                        buttonsize="md"
                        disabled={props.isSubmitting}
                        aria-disabled={props.isSubmitting}
                      >
                        Tilaa
                      </Button>
                    </Col>
                    <Col
                      md={8}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {props.submitCount > 0 &&
                      !AccountSchema.isValidSync(props.values) ? (
                        <StyledErrorMessage>
                          Täydennettäviä tietoja puuttuu. Täytä puuttuvat kohdat
                          yllä jatkaaksesi
                        </StyledErrorMessage>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </FormRow>
            </StyledDiv>
          </Form>
        )}
      </Formik>
      <div className="mt-3">
        <ReCAPTCHA size="invisible" ref={captchaRef} sitekey={captchaKey} />
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  /* padding-right: 0px;
  margin-right: 0px; */
`;

const StyledDiv = styled.div``;

const StyledSecondaryDiv = styled.div`
  width: 100%;
  background-color: var(--fl-bg-secondary);
`;

const RequiredSpan = styled.span`
  &:after {
    content: '*';
  }
`;

const FormRow = styled(Row)`
  padding-top: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;

  @media ${mediaLargeMax} {
    padding: 1rem;
  }
`;

const StyledCol = styled(Col)`
  padding: 2.5rem;
  padding-top: 2rem;
  margin-bottom: 5rem;
  margin-top: 5rem;
  border-radius: 15px;
  background-color: var(--fl-white);
`;

const StyledSpan = styled.span`
  margin: 1rem;
`;

const RadioInputGroup = styled(Row)`
  margin-top: 1rem;
`;
