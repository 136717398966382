import { Text } from '../Text';
import { H1, H3 } from '../Headings';
import styled, { css } from 'styled-components/macro';
import { Container } from 'react-bootstrap';
import {
  mediaLargeMax,
  mediaXXLargeMax,
  mediaXXLargeMin,
} from '../../theme/media';
import { FixedLink, ExternalLink } from '../Link';
import {
  useDataProtectionModal,
  useTermModal,
} from '../../features/modal/hooks';

const StyledContainer = styled(Container)`
  @media ${mediaLargeMax} {
    padding-top: 0;
  }

  @media ${mediaXXLargeMax} {
    padding-top: 1rem;
  }

  @media ${mediaXXLargeMin} {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
`;

const StyledDiv = styled.div`
  margin-bottom: 40px;
`;

const LinkStyle = css`
  color: var(--dark-blue);
  text-decoration: underline;
  font-weight: 400;
`;

const StyledBtn = styled.button`
  border: 0;
  background: none;
  font-weight: 600;
  ${LinkStyle};
`;

export const UserInfo = () => {
  const linkedinLink = 'https://www.linkedin.com/company/72721578';
  const dispatchTermModal = useTermModal();
  const dispatchDataProtectionModal = useDataProtectionModal();

  return (
    <StyledContainer>
     
      <StyledDiv>
        <H3>Tahdotko vinkkejä sopimusten tekoon?</H3>
        <Text size="md">
          Käy lukemassa Fixed Legalin <FixedLink to="/blogi">blogia.</FixedLink>{' '}
          Seuraa meitä myös{' '}
          <ExternalLink
            href={linkedinLink}
            target="_blank"
            rel="nofollow noopener"
          >
            LinkedInissä
          </ExternalLink>{' '}
          ja saat aina viimeisimmät vinkit. Tietoa sopimusten sisällöstä saat Profiilini-valikon tietopankista. 
        </Text>
      </StyledDiv>
      <StyledDiv>
        <H3>
          Puuttuuko Fixed Legalista jotakin? Onko sinulla toiveita tai
          kehityspyyntöjä?
        </H3>
        <Text size="md">
          Tavoitteenamme on auttaa asiakkaitamme menestymään. Voimme
          mahdollisesti toteuttaa toiveesi. Ole meihin yhteydessä lähettämällä
          sähköpostia osoitteeseen info(at)fixed-legal.com.
        </Text>
      </StyledDiv>
      <StyledDiv>
        <H3>Haluatko irtisanoa tilauksesi?</H3>
        <Text size="md">
          Ikävää, että haluat lähteä. Jos sinulla on tarpeita, joiden
          toteuttaminen saisi sinut jäämään, esitä pyyntösi meille. On
          mahdollista, että saamme vastattua toiveisiisi vastattua. Jos
          kuitenkin haluat irtisanoa tilauksen, voit tehdä sen lähettämällä
          irtisanomisilmoituksen sähköpostitse osoitteeseen
          info@fixed-legal.com.
        </Text>
      </StyledDiv>
      <StyledDiv>
        <H3>
          Haluatko lukea Fixed Legalin ehdot tai tutustua henkilötietojen
          käsittelykäytäntöihimme?
        </H3>
        <Text size="md">
          Fixed Legalin ehdot ovat
          <StyledBtn type="button" onClick={dispatchTermModal}>
            täällä
          </StyledBtn>{' '}
          ja tietosuojaseloste on
          <StyledBtn type="button" onClick={dispatchDataProtectionModal}>
            täällä
          </StyledBtn>
          .
        </Text>
      </StyledDiv>
    </StyledContainer>
  );
};
