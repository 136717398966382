import { createListenerMiddleware } from '@reduxjs/toolkit';
import { setConfigurationOrder } from './configurationSlice';
import { RootState } from '../../redux/store';
import localforage from 'localforage';

export const configurationListenerMiddleware = createListenerMiddleware();

/**
 * Listens for `setConfigurationOrder` action from Registration form
 * - whenever `setConfigurationOrder` is dispatched from registration form, form content is saved to localStorage under hashed email
 */
configurationListenerMiddleware.startListening({
  actionCreator: setConfigurationOrder,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as RootState;
    // hashed email as id of contract order
    const id = action.payload.id;
    // get configured contract
    const contract = state.configuration.configuredContract;
    // save contract to localStorage under hashed email
    await localforage.setItem(id, contract);
  },
});
