import React from "react";
import { CSSProp, DefaultTheme } from 'styled-components';
import { StyledH1, StyledH2, StyledH3, StyledH4 } from "./styled";
import { HeadingType } from "./types";

type Props = HeadingType & {
  css?: CSSProp;
  children: React.ReactNode;
}

export const H1 = ({ children, ...props }: Props) => {
  return <StyledH1 {...props}>{children}</StyledH1>
}

export const H2 = ({ children, ...props }: Props) => {
  return <StyledH2 {...props}>{children}</StyledH2>
}

export const H3 = ({ children, ...props }: Props) => {
  return <StyledH3 {...props}>{children}</StyledH3>;
}

export const H4 = ({ children, ...props }: Props) => {
  return <StyledH4 {...props}>{children}</StyledH4>;
}
