import { Form, Formik } from 'formik';
import { Col, FormLabel, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { ChangePasswordSchema } from '../../helpers/validationSchemas';
import { Button } from '../Button';
import { FormikTextInput } from '../Form/FormikInput';
import { H1 } from '../Headings';
import { PageSection } from '../Sections';
import { Text } from '../Text';
import { useResetPasswordMutation } from '../../api/auth';
import ErrorMessage from '../ErrorMessage';

type FormValues = {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

export const PasswordReset = () => {
  const [resetPassword, { error }] = useResetPasswordMutation();
  const handleSubmit = async (values: FormValues) => {
    try {
      await resetPassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      }).unwrap();
      toast.success('Salasanan vaihto onnistui');
    } catch (error) {
    }
  };

  return (
    <PageSection>
      <Formik
        validateOnChange
        validationSchema={ChangePasswordSchema}
        initialValues={{
          currentPassword: '',
          newPassword: '',
          repeatNewPassword: '',
        }}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form className="m-2">
          <Row className="text-center my-4">
            <H1>Vaihda salasanasi</H1>
          </Row>
          <Row className="justify-content-center">
            <Col md={6}>
              <FormLabel as={Text} htmlFor="currentPassword">
                Salasanasi
              </FormLabel>
              <FormikTextInput
                id="currentPassword"
                name="currentPassword"
                placeholder="Salasanasi"
                type="password"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={6}>
              <FormLabel as={Text} htmlFor="newPassword">
                Valitse uusi salasana
              </FormLabel>
              <FormikTextInput
                id="newPassword"
                name="newPassword"
                placeholder="Uusi salasana"
                type="password"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={6}>
              <FormLabel as={Text} htmlFor="repeatNewPassword">
                Kirjoita uusi salasana uudelleen
              </FormLabel>
              <FormikTextInput
                id="repeatNewPassword"
                name="repeatNewPassword"
                placeholder="Uusi salasana uudelleen"
                type="password"
              />
            </Col>
          </Row>
          <div className="text-center">
            {error ? (
              <ErrorMessage>
                Annoit väärän salasanan
              </ErrorMessage>
            ) : null}
          </div>
          <Row className="mt-4 text-center">
            <Col md={12}>
              <Button type="submit">Vaihda salasana</Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </PageSection>
  );
};
