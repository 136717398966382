import { useCallback } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { ModalProps, openModal } from './modalSlice';

export const useTermModal = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    return dispatch(
      openModal({
        modalType: 'STRAPI_MODAL',
        modalProps: {
          visible: true,
          title: 'Käyttöehdot',
          strapiId: 8,
        },
      })
    );
  }, [dispatch]);
};

export const useDataProtectionModal = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    return dispatch(
      openModal({
        modalType: 'STRAPI_MODAL',
        modalProps: {
          visible: true,
          title: 'Tietosuojaseloste',
          strapiId: 7,
        },
      })
    );
  }, [dispatch]);
};

type OmittedModalHookProps = Omit<ModalProps, 'visible'>;

export const useInfoModal = () => {
  const dispatch = useAppDispatch();
  return useCallback(
    (props: OmittedModalHookProps) => {
      return dispatch(
        openModal({
          modalType: 'INFO_MODAL',
          modalProps: {
            visible: true,
            title: props.title,
            content: props.content,
          },
        })
      );
    },
    [dispatch]
  );
};
