import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollToTop from './components/ScrollToTop';
import { GlobalStyles } from './theme/config';
import { persistor, storeV2 } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import PiwikPro from '@piwikpro/react-piwik-pro';
import ModalRoot from './features/modal/ModalRoot';

const container = document.getElementById('root')!;
const root = createRoot(container);

PiwikPro.initialize(
  '9bfde3d7-b622-485a-bec5-7d4c7fc5efce',
  'https://fixed-legal.containers.piwik.pro'
);

root.render(
  <React.StrictMode>
    <Provider store={storeV2}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <GlobalStyles />
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </PersistGate>
      <ModalRoot />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
