import { Link } from 'react-router-dom';
import { LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

type Props = LinkProps & {
  bold?: 'true' | 'false';
  children: React.ReactNode;
};

type ExternalLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: React.ReactNode;
};

const linkStyles = css<Pick<Props, 'bold'>>`
  color: var(--dark-blue);
  font-weight: ${(props) => (props.bold === 'true' ? '700' : '400')};
`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const StyledExternalLink = styled.a`
  ${linkStyles}
`;

const FixedLink = ({ children, ...rest }: Props) => {
  return <StyledLink {...rest}>{children}</StyledLink>;
};

const ExternalLink = ({ children, ...rest }: ExternalLinkProps) => {
  return <StyledExternalLink {...rest}>{children}</StyledExternalLink>;
};

export { FixedLink, ExternalLink }
