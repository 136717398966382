import { ContractGenerationData } from '@smarterbiz/fixedlegal-shared';
import { Text } from '../../Text';
import { BsCheck2 } from 'react-icons/bs';
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from '../../../redux/hooks';
import {
  appendixOverviewsSelector,
  contractAppendixesSelector,
  contractOverviewsSelector,
  selectedAppendixesSelector,
} from '../../../features/configuration/selectors';

type Props = {
  contractGenerationParams: ContractGenerationData;
};

export const SummaryChecklist = ({ contractGenerationParams }: Props) => {
  const { configurationMode } = useAppSelector((state) => state.configuration);
  const selectedAppendices = useAppSelector(selectedAppendixesSelector);
  const contractAppendices = useAppSelector(contractAppendixesSelector);
  const contractScopeOverviews = useAppSelector(contractOverviewsSelector);
  const appendixScopeOverviews = useAppSelector(appendixOverviewsSelector);

  return (
    <Row>
      <Col className="pt-3" md={6}>
        {contractGenerationParams ? (
          <Row>
            <Col xs={2}>
              <BsCheck2 size="2rem" color="#2675f9" className="float-right" />
            </Col>
            <Col>
              <Text size="md">
                {contractGenerationParams.lang === 'EN'
                  ? 'Kieli on englanti'
                  : contractGenerationParams.lang === 'FI'
                  ? 'Kieli on suomi'
                  : null}
              </Text>
            </Col>
          </Row>
        ) : null}
        {configurationMode === 'CONTRACT'
          ? contractScopeOverviews?.map((overview, i) => {
              if (!overview) {
                return null;
              }
              return (
                <Row key={i}>
                  <Col xs={2}>
                    <BsCheck2 size="2rem" color="#2675f9" />
                  </Col>
                  <Col>
                    <Text size="md">{overview}</Text>
                  </Col>
                </Row>
              );
            })
          : configurationMode === 'APPENDIX'
          ? appendixScopeOverviews?.map((overview, i) => {
              if (!overview) {
                return null;
              }
              return (
                <Row key={i}>
                  <Col xs={2}>
                    <BsCheck2 size="2rem" color="#2675f9" />
                  </Col>
                  <Col>
                    <Text size="md">{overview}</Text>
                  </Col>
                </Row>
              );
            })
          : null}
      </Col>
      <Col className="pt-3" md={6}>
        {selectedAppendices?.map((inclusion) => {
          // find the matching appendice
          const selectedAppendice = contractAppendices?.find(
            (appendice) => appendice.key === inclusion
          );
          return (
            <Row key={inclusion}>
              <Col xs={2}>
                <BsCheck2 size="2rem" color="#2675f9" className="float-right" />
              </Col>
              <Col>
                <Text size="md">{selectedAppendice?.desc}</Text>
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};
