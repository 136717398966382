import { ProtectedContracts } from '../../components/Contract/Contracts';
import { Head } from '../../components/Seo';

const ProtectedContractsPage = () => {
  return (
    <>
      <Head title="Luo sopimus" />
      <ProtectedContracts />
    </>
  );
};

export default ProtectedContractsPage;
