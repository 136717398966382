import React from 'react';
import { Form, Row, Col, FormCheck } from 'react-bootstrap';
import { FormikTextInput } from '../Form/FormikInput';
import { useFormikContext, getIn } from 'formik';
import { ContractConfigurationFormType } from './Configuration/types';
import { Label } from '../Form/Label';

const ContractParty = () => {
  const context = useFormikContext<ContractConfigurationFormType>();

  const relation = context.values.relation === 'supplier' ? 'party2' : 'party1';

  const partyFillLater = getIn(context.values, `${relation}.fillLater`);

  const handleFillPartyLater = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    context.setFieldValue(
      `${relation}.fillLater`,
      !getIn(context.values, `${relation}.fillLater`)
    );
    context.setFieldValue(
      `${relation}.name`,
      checked ? 'Add the name of the organization' : ''
    );
    context.setFieldValue(
      `${relation}.id`,
      checked ? 'Add the business ID of the organization (if any)' : ''
    );
    context.setFieldValue(
      `${relation}.address`,
      checked ? 'Add the address of the organization' : ''
    );
    context.setFieldValue(
      `${relation}.addressCityCode`,
      checked
        ? 'Add the postal code, city of registration and country of the organization'
        : ''
    );
    // set touched state for party field to false
    // this is done because touched state is left on if fields are touched and are set to be filled later..
    if (context.values.relation === 'supplier') {
      context.setTouched(
        {
          party2: {
            name: false,
            id: false,
            address: false,
            addressCityCode: false,
          },
        },
        false
      );
    } else if (context.values.relation === 'customer') {
      context.setTouched(
        {
          party1: {
            name: false,
            id: false,
            address: false,
            addressCityCode: false,
          },
        },
        false
      );
    }
  };

  return (
    <>
      <Form.Group as={Row} className="mt-3" controlId="organizationName">
        <Col xs={4}>
          <Label>Organisaation nimi:</Label>
        </Col>
        <Col xs={7} sm={5}>
          <FormikTextInput
            disabled={partyFillLater}
            hideValue={partyFillLater}
            name={`${relation}.name`}
            placeholder="Nimi"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mt-3" controlId="organizationId">
        <Col xs={4}>
          <Label>Y-tunnus:</Label>
        </Col>
        <Col xs={7} sm={5}>
          <FormikTextInput
            disabled={partyFillLater}
            hideValue={partyFillLater}
            name={`${relation}.id`}
            placeholder="Y-tunnus"
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mt-3" controlId="partyAddress">
        <Col xs={4}>
          <Label>Osoite:</Label>
        </Col>
        <Col xs={7} sm={5}>
          <FormikTextInput
            disabled={partyFillLater}
            hideValue={partyFillLater}
            name={`${relation}.address`}
            placeholder="Osoite"
          />
        </Col>
        <Form.Group
          as={Col}
          xs={{ span: 5, offset: 4 }}
          controlId="partyAddressCityCode"
          className="mt-3"
        >
          <FormikTextInput
            disabled={partyFillLater}
            hideValue={partyFillLater}
            name={`${relation}.addressCityCode`}
            placeholder="Postinumero ja kaupunki"
          />
        </Form.Group>
      </Form.Group>
      <Form.Group
        as={Row}
        style={{ marginTop: '2rem', marginBottom: '4rem' }}
        controlId="checkPartyDetailsForLater"
      >
        <Col xs={4} className="text-center">
          <FormCheck
            type="checkbox"
            className="btn-group-vertical"
            checked={partyFillLater}
            onChange={handleFillPartyLater}
          />
        </Col>
        <Col xs={6} md={8} style={{ marginBottom: '1rem' }}>
          <Label>
            Haluan jättää toisen sopijapuolen tiedot tyhjäksi ja täydentää
            myöhemmin
          </Label>
        </Col>
      </Form.Group>
    </>
  );
};

export default ContractParty;
