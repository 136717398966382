import { Blog } from '../components/Blog';
import { PageViews } from '@piwikpro/react-piwik-pro';
import { Head } from '../components/Seo';

const BlogPage = () => {
  PageViews.trackPageView('BlogPage');
  return (
    <>
      <Head title="Blogi" />
      <Blog />
    </>
  );
};

export default BlogPage;
