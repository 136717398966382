import { Nav, Navbar, Image, Container, Accordion } from 'react-bootstrap';
import { Button } from '../Button';
import styled from 'styled-components';
import Logo from '../../assets/logo_valkoinen_85_20.png';
import { UserMenu } from './UserDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { mediaLargeMax, mediaMediumMin } from '../../theme/media';
import { MdMenu } from 'react-icons/md';
import { useAuth } from '../../redux/hooks';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';
import { BlockButton } from '../Button/Button';

const Navigation = () => {
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const { isAuthenticated } = useAuth();
  const isMobile = useMedia(mediaLargeMax);

  const goTo = (route: string) => {
    if (routerLocation.pathname === route) {
      window.location.reload();
    }
    navigate(route);
  };

  return (
    <StyledNavBar collapseOnSelect expand="lg" variant="light">
      <StyledNavbarContentContainer fluid>
        <StyledNavBarBrand>
          <Link to="/">
            <Image
              src={Logo}
              width="250px"
              style={{ minWidth: '150px' }}
              alt="Fixed Legal sopimukset logo"
              fluid
            />
          </Link>
        </StyledNavBarBrand>
        <StyledNavbarToggle>
          <MdMenu size="2rem" />
        </StyledNavbarToggle>
        <StyledNavBarCollapse id="fixed-legal-navbar">
          <StyledNav>
            <StyledNavLinksContainer>
              <StyledNavLink eventKey={1} onClick={() => goTo('/palvelu')}>
                PALVELU
              </StyledNavLink>
              <StyledNavLink eventKey={2} onClick={() => goTo('/hinnoittelu')}>
                HINNAT
              </StyledNavLink>
              <StyledNavLink eventKey={3} onClick={() => goTo('/meista')}>
                MEISTÄ
              </StyledNavLink>
              <StyledNavLink eventKey={4} onClick={() => goTo('/blogi')}>
                BLOGI
              </StyledNavLink>
            </StyledNavLinksContainer>
            {!isMobile ? (
              <ButtonContainer>
                <Button buttonsize="xs" onClick={() => goTo('/tee-sopimus')}>
                  {isAuthenticated ? 'Tee sopimus' : 'Kokeile'}
                </Button>
                {isAuthenticated ? (
                  <Button buttonsize="xs" onClick={() => goTo('/dokumentit')}>
                    Dokumenttini
                  </Button>
                ) : (
                  <Button buttonsize="xs" onClick={() => goTo('/rekisteroidy')}>
                    Luo tili
                  </Button>
                )}
                {isAuthenticated ? (
                  <UserMenu
                    isMobile={false}
                    isAuthenticated={isAuthenticated}
                  />
                ) : (
                  <Button buttonsize="xs" onClick={() => goTo('/kirjaudu')}>
                    Kirjaudu
                  </Button>
                )}
              </ButtonContainer>
            ) : null}
          </StyledNav>
          {isMobile ? (
            <MobileButtonContainer>
              <BlockButton onClick={() => goTo('/tee-sopimus')}>
                {isAuthenticated ? 'Tee sopimus' : 'Kokeile'}
              </BlockButton>
              {isAuthenticated ? (
                <BlockButton>Dokumenttini</BlockButton>
              ) : (
                <BlockButton onClick={() => goTo('/rekisteroidy')}>
                  Luo tili
                </BlockButton>
              )}
              <UserMenu isMobile={true} isAuthenticated={false} />
            </MobileButtonContainer>
          ) : null}
        </StyledNavBarCollapse>
      </StyledNavbarContentContainer>
    </StyledNavBar>
  );
};

const StyledNavBar = styled(Navbar)`
  top: 0;
  padding: 1.5rem;
  background-color: var(--blue);
`;

const StyledNavbarToggle = styled(Navbar.Toggle)``;

const StyledNavBarBrand = styled(Navbar.Brand)`
  @media ${mediaLargeMax} {
    width: auto;
  }
`;

const StyledNavbarContentContainer = styled(Container)``;

const StyledNavBarCollapse = styled(Navbar.Collapse)``;

const StyledNav = styled(Nav)`
  /* width: 100%; */
  display: flex;
  display: inline-flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 2.5rem;
  gap: 2rem;

  @media ${mediaLargeMax} {
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    justify-content: start;
  }
`;

const StyledNavLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  @media ${mediaLargeMax} {
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: start;
  }
`;

const StyledNavLink = styled(Nav.Link)`
  color: var(--fl-white) !important;
  font-size: 20px;
  font-family: 'Avenir';
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  max-height: 40px;
  gap: 1rem;
  @media ${mediaLargeMax} {
    gap: 1rem;
    justify-content: start;
  }
`;

const MobileButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media ${mediaMediumMin} {
    width: 50%;
    gap: 1rem;
    justify-content: start;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

export default Navigation;
