import { Col, Row, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { H1 } from '../../Headings';
import { Text } from '../../Text';
import { Button } from '../../Button';
import styled from 'styled-components/macro';
import agreement from '../../../assets/example_agreement.png';
import { SummaryChecklist } from '.';
import { mediaXLargeMax } from '../../../theme/media';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';

const StyledRow = styled(Row)`
  margin: 3rem;

  @media ${mediaXLargeMax} {
    margin: 0;
  }
`;

const HeadingContainer = styled.div`
  margin-bottom: 6rem;

  @media ${mediaXLargeMax} {
    margin-bottom: 2rem;
  }
`;

const Instructions = styled(Col)`
  padding: 4rem;
`;

const ContractContainer = styled(Col)`
  display: flex;
  justify-content: center;
  padding-top: 6rem;
`;

export const ContractSummary = () => {
  const navigate = useNavigate();
  const { configuration } = useAppSelector((state: RootState) => state);
  const configurationData = configuration.configuredContract;

  return (
    <StyledRow>
      <Instructions>
        <HeadingContainer>
          <H1>LÄHES VALMISTA!</H1>
        </HeadingContainer>
        <Text size="md">
          Olet luonut sopimuksen alla olevilla ominaisuuksilla:
        </Text>
        {configurationData ? (
          <SummaryChecklist contractGenerationParams={configurationData} />
        ) : null}
        <Row md={10} className="mt-5">
          <Col>
            <Text size="md">Seuraavaksi sinun tulee rekisteröityä.</Text>
            <Text size="md">
              Tällöin sopimuksesi säilyy, vaikka sulkisit ikkunan.
            </Text>
          </Col>
          <Col md={4}>
            <Button
              css={`
                width: 150px;
                float: right;
                font-size: 20px;
                padding: 20px;
                border-radius: 143px;
              `}
              onClick={() => navigate('/rekisteroidy')}
            >
              Jatka
            </Button>
          </Col>
        </Row>
      </Instructions>
      <ContractContainer md={5}>
        <Image
          style={{
            maxWidth: '400px',
            maxHeight: '600px',
          }}
          alt="Sopimusmalli"
          src={agreement}
          fluid
        />
      </ContractContainer>
    </StyledRow>
  );
};
