import { Home } from '../components/Home';
import { PageViews } from '@piwikpro/react-piwik-pro';
import { Head } from '../components/Seo';

const HomePage = () => {
  PageViews.trackPageView('HomePage');
  return (
    <>
      <Head />
      <Home />
    </>
  );
};

export default HomePage;
