import { Suspense } from 'react';
import LoadingScreen from '../components/LoadingScreen';

type Props = {
  children: React.ReactNode;
};

const RouteLoader = ({ children }: Props) => {
  return <Suspense fallback={<LoadingScreen />}>{children}</Suspense>;
};

export default RouteLoader;
