import { Container } from "react-bootstrap";
import styled from "styled-components";

export const LoginContainer = styled(Container)`
  height: 100%;
  max-width: 1096px;
  max-height: 694px;
  margin-top: 5rem;
  padding: 1rem;
`;

export const LoginActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
  }
`;

export const ErrorMessage = styled.p`
  color: var(--fl-warning-primary);
`;