import { Row, Col, Container } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components/macro';
import { H3 } from '../../Headings';
import { Text } from '../../Text';
import { ContractsList } from '../ContractList';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { ConfigureContract, ConfigureAppendix } from '../Configuration';
import { ContractInfo } from '../ContractInfo';
import {
  useContractInfoQuery,
  usePageContentsQuery,
} from '../../../api/strapi';
import {
  useAppendixesQuery,
  useContractsQuery,
  useLazyContractQuery,
} from '../../../api/fixed';

const StyledRow = styled(Row)`
  height: 100vh;
`;

const SidebarWrapper = styled(Col)`
  min-height: 100vh !important;
  transition: margin 0.25s ease-out;
`;

const LeftContent = styled.div`
  height: 100vh !important;
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  padding-left: 1rem;
  background-color: var(--fl-bg-secondary);
  overflow-y: scroll;
  /* scrollbar-width: thin; */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* safari */
  }
`;

const StyledMarkdown = styled(ReactMarkdown)`
  margin-top: 4rem;
`;

export const Contracts = () => {
  const { selectedContractId, configurationMode } = useAppSelector(
    (state: RootState) => state.configuration
  );

  const { data: contractInfoData } = useContractInfoQuery(
    selectedContractId ?? ''
  );
  const { data: pageContent } = usePageContentsQuery();
  const { data: contractsData } = useContractsQuery();
  const { data: appendixesData } = useAppendixesQuery();
  const [fetchContract, { data: contractData }] = useLazyContractQuery();

  const leftContent = pageContent?.find(
    (page) => page.Page === 'contracts_left'
  );
  const centerContent = pageContent?.find(
    (page) => page.Page === 'contracts_center'
  );

  const needsToDisplayContractInfo =
    selectedContractId !== undefined && !!contractData;

  const ConfigurationComponent = () => {
    if (
      configurationMode === 'CONTRACT' &&
      selectedContractId &&
      contractData
    ) {
      return <ConfigureContract contractData={contractData} />;
    } else if (
      configurationMode === 'APPENDIX' &&
      selectedContractId &&
      contractData
    ) {
      return <ConfigureAppendix appendixData={contractData} />;
    }
    return (
      <StyledMarkdown
        children={centerContent?.Content ?? ''}
        components={{
          p: (props: any) => <Text size="sm" {...props} />,
        }}
      />
    );
  };

  return (
    <Container className="h-100" fluid>
      <StyledRow>
        <SidebarWrapper xs={12} lg={3} xl={3} className="order-lg-1">
          <LeftContent>
            {needsToDisplayContractInfo ? (
              <ContractInfo
                selectedContractInfo={
                  contractInfoData ? contractInfoData[0] : undefined
                }
                showPrice={configurationMode === 'CONTRACT' ? 'YES' : 'NO'}
              />
            ) : (
              <div className="text-center">
                <StyledMarkdown
                  children={leftContent?.Content ?? ''}
                  components={{
                    p: (props: any) => <Text dimmed size="sm" {...props} />,
                    h3: (props: any) => <H3 dimmed {...props} />,
                  }}
                />
              </div>
            )}
          </LeftContent>
        </SidebarWrapper>
        <Col sm={12} lg={6} xl={6} className="order-2">
          <ConfigurationComponent />
        </Col>
        <SidebarWrapper sm={12} lg={3} xl={3} className="order-md-1 order-lg-3">
          <ContractsList
            fetchFn={fetchContract}
            contracts={contractsData ?? []}
            appendices={appendixesData ?? []}
          />
        </SidebarWrapper>
      </StyledRow>
    </Container>
  );
};
