import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Col, Row, FormCheck } from 'react-bootstrap';
import { Text } from '../../Text';
import styled from 'styled-components';
import { useAppSelector } from '../../../redux/hooks';
import { ContractConfigurationFormType } from '../Configuration/types';
import { ContinuousTerm } from './ContinuousTerm';
import { FixedTerm } from './FixedTerm';
import { SubscriptionTerm } from './SubscriptionTerm';

export type Parties = {
  party1: string | undefined;
  party2: string | undefined;
};

type Props = {
  term?: string;
};

const StyledCol = styled(Col)`
  margin: 0.8rem;
  margin-bottom: 1rem;
`;

export const ContractTerm = ({ term }: Props) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const {
    values: formValues,
    setValues,
    setTouched,
  } = useFormikContext<ContractConfigurationFormType>();
  const { selectedContractData } = useAppSelector(
    (state) => state.configuration
  );

  const parties = {
    party1: selectedContractData?.party1.desc,
    party2: selectedContractData?.party2.desc,
  };

  const handleSetDefaultTermValues = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { target } = e;
    setIsDisabled(target.checked);

    if (target.checked) {
      setValues(
        {
          ...formValues,
          fixedTermInMonths: selectedContractData?.fixedTermInMonths,
          initialTermInMonths: selectedContractData?.initialTermInMonths,
          renewalTermInMonths: selectedContractData?.renewalTermInMonths,
          noticePeriodMutualInMonths:
            selectedContractData?.noticePeriodMutualInMonths,
          noticePeriodParty1InMonths:
            selectedContractData?.noticePeriodParty1InMonths,
          noticePeriodParty2InMonths:
            selectedContractData?.noticePeriodParty2InMonths,
          noticePeriodParty1InDays:
            selectedContractData?.noticePeriodParty1InDays,
          noticePeriodParty2InDays:
            selectedContractData?.noticePeriodParty2InDays,
        },
        true
      );
    } else {
      setValues(
        {
          ...formValues,
          fixedTermInMonths: 0,
          initialTermInMonths: 0,
          renewalTermInMonths: 0,
          noticePeriodMutualInMonths: 0,
          noticePeriodParty1InMonths: 0,
          noticePeriodParty2InMonths: 0,
          noticePeriodParty1InDays: 0,
          noticePeriodParty2InDays: 0,
        },
        true
      );
    }
    // set field touched state to false to make falsy errors disappear
    setTouched({
      fixedTermInMonths: false,
      initialTermInMonths: false,
      renewalTermInMonths: false,
      noticePeriodMutualInMonths: false,
      noticePeriodParty1InMonths: false,
      noticePeriodParty2InMonths: false,
      noticePeriodParty1InDays: false,
      noticePeriodParty2InDays: false,
    });
  };

  return (
    <>
      <Row className="d-flex justify-content-end">
        {term === 'CONTINUOUS' ? (
          <ContinuousTerm parties={parties} isDisabled={isDisabled} />
        ) : null}
        {term === 'FIXED' ? <FixedTerm isDisabled={isDisabled} /> : null}
        {term === 'SUBSCRIPTION' ? (
          <SubscriptionTerm parties={parties} isDisabled={isDisabled} />
        ) : null}
      </Row>
      <StyledCol md={10}>
        <Row className="d-flex justify-content-end">
          <Col xs={1} md={2} className="text-center">
            <FormCheck
              name="useDefaultTerms"
              className="btn-group-vertical"
              onChange={handleSetDefaultTermValues}
            />
          </Col>
          <Col xs={10} md={8}>
            <Text>Vaihtoehtoisesti käytä Fixed Legalin oletusarvoja.</Text>
          </Col>
        </Row>
      </StyledCol>
    </>
  );
};
