import { Col, Container, Row, FormGroup, FormLabel } from 'react-bootstrap';
import styled from 'styled-components/macro';
import { Button } from '../Button';
import { H1 } from '../Headings';
import { Text } from '../Text';
import { Form, Formik, FormikProps } from 'formik';
import { ProfileSchema } from '../../helpers/validationSchemas';
import { FormikTextInput } from '../Form/FormikInput';
import {
  mediaLargeMax,
  mediaXXLargeMax,
  mediaXXLargeMin,
} from '../../theme/media';
import { FixedLink } from '../Link';
import { isEqual } from 'lodash';
import { useMeQuery, useUpdateProfileMutation } from '../../api/auth';
import toast from 'react-hot-toast';
import { UserRole } from '@smarterbiz/fixedlegal-shared';

export type TProfile = {
  companyName?: string;
  companyId?: string;
  address: string;
  addressCityPostCode: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

export const Profile = () => {
  const [updateProfile] = useUpdateProfileMutation();
  const { data: user } = useMeQuery();

  const isAdminUser = () => {
    if (user?.role === UserRole.ADMIN) {
      return true;
    }
    return false;
  };

  const userAddress = user?.companyAddress.toString().split('\n') ?? [
    '',
    '',
    '',
  ];

  const initialValues: TProfile = {
    companyName: user?.companyName,
    companyId: user?.companyId,
    address: userAddress[0],
    addressCityPostCode: userAddress[1],
    firstName: user?.name.split(' ')[0],
    lastName: user?.name.split(' ').slice(1).join(' '),
    email: user?.email,
  };

  return (
    <>
      {isAdminUser() ? (
        <div>
          <FixedLink to="/laskutustapahtumat">Laskutustapahtumat</FixedLink>
          <br />
          <FixedLink to="/kayttajalistaus">Käyttäjälistaus</FixedLink>
          <br />
          <FixedLink to="/admin">Virhelistaus</FixedLink>
        </div>
      ) : null}
      <StyledContainer fluid>
        <Formik<TProfile>
          enableReinitialize={true}
          validateOnMount={true}
          validateOnChange={true}
          initialValues={initialValues}
          validationSchema={ProfileSchema}
          onSubmit={async (values) => {
            await updateProfile(values).unwrap();
            toast.success('Profiilin päivitys onnistui');
          }}
        >
          {(props: FormikProps<TProfile>) => (
            <>
              <Row className="justify-content-center mt-5">
                <Col md={12} xl={10}>
                  <H1>TILITIETOSI</H1>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <ProfileColumn xs={12} md={6} lg={6}>
                  <div className="pb-4">
                    <Text size="md">Alla voit päivittää tietojasi</Text>
                  </div>
                  <Form>
                    <FormGroup
                      as={Row}
                      className="mb-3"
                      controlId="formBasicCompanyName"
                    >
                      <FormLabel column md={5}>
                        <Text size="md">Yrityksen nimi:</Text>
                      </FormLabel>
                      <Col md={6} sm={12}>
                        <FormikTextInput
                          name="companyName"
                          placeholder="Nimi"
                          disabled
                          aria-disabled="true"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup
                      as={Row}
                      className="mb-3"
                      controlId="formBasicYtunnus"
                    >
                      <FormLabel column md={5}>
                        <Text size="md">Y-tunnus:</Text>
                      </FormLabel>
                      <Col md={6} sm={12}>
                        <FormikTextInput
                          name="companyId"
                          placeholder="Y-tunnus"
                          disabled
                          aria-disabled="true"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row} className="mb-3">
                      <FormLabel column md={5} htmlFor="address">
                        <Text size="md">Yrityksen postiosoite:</Text>
                      </FormLabel>
                      <Col md={6} sm={12}>
                        <div className="mb-4">
                          <FormikTextInput
                            id="address"
                            name="address"
                            placeholder="Katuosoite tai postilokero"
                          />
                        </div>
                        <div className="mb-4">
                          <FormikTextInput
                            id="addressCity"
                            name="addressCityPostCode"
                            placeholder="Postinumero ja kaupunki"
                          />
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row} className="mb-3">
                      <FormLabel column md={5} htmlFor="firstName">
                        <Text size="md">Oma nimesi:</Text>
                      </FormLabel>
                      <Col md={6} sm={12}>
                        <FormikTextInput
                          id="firstName"
                          name="firstName"
                          placeholder="Etunimi"
                        />
                        <FormikTextInput
                          id="lastName"
                          name="lastName"
                          placeholder="Sukunimi"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup
                      as={Row}
                      className="mb-3"
                      controlId="formBasicUserEmail"
                    >
                      <FormLabel column md={5}>
                        <Text size="md">Sähköpostisi:</Text>
                      </FormLabel>
                      <Col md={6} sm={12}>
                        <FormikTextInput
                          name="email"
                          placeholder="Sähköpostiosoite"
                          disabled
                          aria-disabled="true"
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup as={Row} className="mb-3">
                      <Col md={5}></Col>
                      <StyledCol md={6} sm={12}>
                        <Button
                          type="submit"
                          disabled={isEqual(props.initialValues, props.values)}
                          buttonsize="md"
                        >
                          Tallenna muutokset
                        </Button>
                      </StyledCol>
                    </FormGroup>
                  </Form>
                </ProfileColumn>
                <ProfileColumn xs={12} md={6} xl={4}>
                  <Text size="md">Haluatko vaihtaa salasanasi?</Text>
                  <FixedLink to="/vaihda-salasana" bold="true">
                    Vaihda salasanasi
                  </FixedLink>
                </ProfileColumn>
              </Row>
            </>
          )}
        </Formik>
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled(Container)`
  @media ${mediaLargeMax} {
    padding-top: 0;
  }

  @media ${mediaXXLargeMax} {
    padding-top: 1rem;
  }

  @media ${mediaXXLargeMin} {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ProfileColumn = styled(Col)`
  padding-top: 5rem;
`;
