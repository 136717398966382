import { format, addDays } from 'date-fns';
import fi from 'date-fns/locale/fi';

export const fiFormatDate = (date: string) => {
  const parsedDate = Date.parse(date);
  return format(parsedDate, 'P', { locale: fi });
};

export const incrementedDateByDay = (date: string, days: number) => {
  const parsedDate = Date.parse(date);
  const incremented = addDays(parsedDate, days);
  return format(incremented, 'P', { locale: fi });
};

export const dateBasedOnLocale = (lang: string) => {
  if (lang === 'FI') {
    return format(new Date(), 'PPPP', { locale: fi });
  }
  return format(new Date(), 'PPPP');
};