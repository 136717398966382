import { ErrorMessage } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { FormikNumberInput } from '../../Form/FormikInput';
import { Text } from '../../Text';
import { Label } from '../../Form/Label';
import { useInfoModal } from '../../../features/modal/hooks';
import { Parties } from './ContractTerm';

const ErrorText = styled.p`
  color: var(--fl-warning-primary);
`;

type Props = {
  parties: Parties;
  isDisabled: boolean;
};

export const ContinuousTerm = ({ parties, isDisabled }: Props) => {
  const dispatchInfoModal = useInfoModal();

  return (
    <Col md={10} className="m-2">
      <Form.Group as={Row}>
        <Col className="pt-4" md={10}>
          <Text>
            Määritä irtisanomisaika kummallekin sopijapuolelle. Kun kumpi
            tahansa on antanut irtisanomisilmoituksen, sopimus päättyy
            irtisanomisajan kuluttua.
          </Text>
        </Col>
        <Col className="pt-4" md={2}>
          <AiOutlineInfoCircle
            size="2rem"
            color="#2675f9"
            onClick={() =>
              dispatchInfoModal({
                title: 'Toistaiseksi voimassaoleva',
                content:
                  'Sopimussuhteen toimittajan irtisanomisaika voi olla esim. 12 kuukautta ja asiakkaan 6 kuukautta. Kun toimittaja on tässä tapauksessa antanut asiakkaalle irtisanomisilmoituksen, sopimus päättyy 12 kuukauden kuluttua. Asiakkaan antaessa irtisanomisilmoituksen sopimus päättyy 6 kuukauden kuluttua. Sopimus säilyy voimassa, kunnes jompi kumpi on ilmoittanut irtisanomisesta.',
              })
            }
          />
        </Col>
      </Form.Group>
      <Form.Group
        as={Row}
        controlId="noticePeriodParty1InMonths"
        className="mb-4"
      >
        <Col md={12} lg={4}>
          <Label>{parties.party1}: syötä irtisanomisaika</Label>
        </Col>
        <Col xs={8} md={8} lg={2}>
          <FormikNumberInput
            name="noticePeriodParty1InMonths"
            disabled={isDisabled}
          />
        </Col>
        <Col className="d-flex align-items-center">
          <Text>Kuukautta</Text>
        </Col>
        <ErrorMessage component={ErrorText} name="noticePeriodParty1InMonths" />
      </Form.Group>
      <Form.Group as={Row} controlId="noticePeriodParty2InMonths">
        <Col md={12} lg={4}>
          <Label>{parties.party2}: syötä irtisanomisaika</Label>
        </Col>
        <Col xs={8} md={8} lg={2}>
          <FormikNumberInput
            name="noticePeriodParty2InMonths"
            disabled={isDisabled}
          />
        </Col>
        <Col className="d-flex align-items-center">
          <Text>Kuukautta</Text>
        </Col>
        <ErrorMessage component={ErrorText} name="noticePeriodParty2InMonths" />
      </Form.Group>
    </Col>
  );
};
