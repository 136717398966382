import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { H1 } from '../Headings';
import { PageSection } from '../Sections';
import { Text } from '../Text';

export const OrderConfirmation = () => {
  return (
    <PageSection>
      <Row className="text-center">
        <H1>KIITOS TILAUKSESTASI</H1>
      </Row>
      <Row className="justify-content-center">
        <StyledCol sm={8} xl={5} className="text-center mt-5">
          <Text size="md" dimmed>
            Lähetimme sähköpostiisi vahvistussähköpostin. Käy klikkaamassa
            sähköpostin vahvistuslinkkiä viimeistelläksesi rekisteröinnin. Muista
            tarkistaa roskapostisi, jos et saanut sitä. Käytä ensimmäiseen kirjautumiseen samaa selainta, jota käytit rekisteröinnissä.
          </Text>
          <Text size="md" dimmed>
            Pääset lataamaan dokumenttisi vahvistamisen jälkeen.
          </Text>
          <Text size="md" dimmed>
            Jos tarvitset apua, voit lähettää sähköpostia osoitteeseen
            info(at)fixed-legal.com.
          </Text>
        </StyledCol>
      </Row>
    </PageSection>
  );
};

const StyledCol = styled(Col)`
  padding: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--light-grey);
  border-radius: 15px;
`;
