import { Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import agreement from '../assets/fixed_agreement.png';
import { mediaXLargeMax } from '../theme/media';
import { H2 } from './Headings';
import { Text } from './Text';
import { PageSection } from './Sections';

const StyledSpan = styled.span`
  margin: 2rem;
`;

const StyledDiv = styled.div`
  margin-bottom: 2rem;

  @media ${mediaXLargeMax} {
    text-align: center;
  }
`;

const StyledLink = styled(Link)`
  color: var(--blue);
`;

const Steps = () => {
  return (
    <PageSection className="m-3">
      <Row className="justify-content-center">
        <Col xs={{ order: 2 }} md={{ span: 5, order: 1 }} className="text-center">
          <Image fluid src={agreement} width="350px" alt="An image of a drawn contract" />
        </Col>
        <Col xs={{ order: 1 }} md={{ span: 5, order: 2 }}>
          <StyledDiv>
            <H2>Miten Fixed Legal toimii?</H2>
          </StyledDiv>
          <Col md={12} className="ml-5">
            <StyledSpan>
              <StyledLink to="/tee-sopimus">
                <Text size="lg">1. Valitse sopimustyyppi</Text>
              </StyledLink>
            </StyledSpan>
            <StyledSpan>
              <Text size="lg">2. Vastaa kysymyksiin</Text>
            </StyledSpan>
            <StyledSpan>
              <Text size="lg">3. Valitse kertamaksu tai palvelupaketti</Text>
            </StyledSpan>
            <StyledSpan>
              <Text size="lg">4. Lataa sopimus</Text>
            </StyledSpan>
          </Col>
        </Col>
      </Row>
    </PageSection>
  );
};

export default Steps;
