import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ContractContextData,
  ContractGenerationData,
} from '@smarterbiz/fixedlegal-shared';
import { fixedApi } from '../../api/fixed';

export type Mode = 'CONTRACT' | 'APPENDIX';
type Relation = 'supplier' | 'customer' | null;

export interface ConfigurationState {
  // is a contract or appendix being configured
  configurationMode: Mode;
  // which relation is selected in configuration. used to determine which party gets populated from signup flow
  configurationRelation: Relation;
  // id of the contract that is currently being configured
  selectedContractId: string | undefined;
  // data of the selected contract
  selectedContractData: ContractContextData | undefined;
  // contract that is currently being configured
  // -> is set when configuration is finished (form submitted)
  configuredContract: ContractGenerationData | undefined;
  // id of the contract order (hashed email), indicates that user has submitted registration form
  orderId: string | undefined;
}

const initialState: ConfigurationState = {
  configurationMode: 'CONTRACT',
  configurationRelation: null,
  selectedContractId: undefined,
  selectedContractData: undefined,
  configuredContract: undefined,
  orderId: undefined,
};

/**
 * `configurationSlice` is used to store data of a contract that is being configured
 */
export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setSelectedContract(
      state: ConfigurationState,
      action: PayloadAction<string>
    ) {
      state.selectedContractId = action.payload;
    },
    setConfigurationMode(
      state: ConfigurationState,
      action: PayloadAction<Mode>
    ) {
      state.configurationMode = action.payload;
      state.selectedContractId = undefined;
    },
    setConfigurationRelation(
      state: ConfigurationState,
      action: PayloadAction<Relation>
    ) {
      state.configurationRelation = action.payload;
    },
    setSelectedContractData(
      state: ConfigurationState,
      action: PayloadAction<ContractContextData>
    ) {
      state.selectedContractData = action.payload;
    },
    setContractGenerationParams(
      state: ConfigurationState,
      action: PayloadAction<ContractGenerationData>
    ) {
      state.configuredContract = action.payload;
    },
    setConfigurationOrder: (state, action: PayloadAction<{ id: string }>) => {
      state.orderId = action.payload.id;
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      fixedApi.endpoints.contract.matchFulfilled,
      (state, { payload }) => {
        state.selectedContractId = payload.id;
        state.selectedContractData = payload;
      }
    );
  },
});

export const {
  setConfigurationMode,
  setSelectedContract,
  setContractGenerationParams,
  setConfigurationOrder,
  setConfigurationRelation,
  resetState,
} = configurationSlice.actions;

export default configurationSlice.reducer;
