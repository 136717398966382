import { Navigate, RouteProps } from 'react-router';
import { useAuth } from '../redux/hooks';

type AuthRouteProps = RouteProps & {
  children: JSX.Element;
};

const AuthRoute = ({ children, ...rest }: AuthRouteProps) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/kirjaudu" replace />;
  }

  return children;
};

export default AuthRoute;
