import { Modal as BootstrapModal } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { ModalProps, closeModal } from './modalSlice';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { StyledModal, StyledModalTitle } from './Styled';

const InfoModal = ({ ...rest }: ModalProps) => {
  const dispatch = useAppDispatch();

  return (
    <StyledModal
      aria-labelledby="modal"
      show={rest.visible}
      onHide={() => dispatch(closeModal())}
      size="lg"
      centered
    >
      <BootstrapModal.Header closeButton className="border-0">
        <StyledModalTitle>{rest.title ?? null}</StyledModalTitle>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <ReactMarkdown
          children={rest.content ?? ''}
          components={{
            p: (props: any) => <Text size="md" dimmed {...props} />,
          }}
        />
      </BootstrapModal.Body>
      <BootstrapModal.Footer className="border-0 d-flex flex-row justify-content-between">
        <Button onClick={() => dispatch(closeModal())}>Sulje</Button>
      </BootstrapModal.Footer>
    </StyledModal>
  );
};

export default InfoModal;
