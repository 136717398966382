import { ContractScope } from '@smarterbiz/fixedlegal-shared';
import { useFormikContext, FormikContextType } from 'formik';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { FormikNumberInput } from '../../Form/FormikInput';
import { Text } from '../../Text';
import { AppendiceConfigurationFormType } from './types';
import { ContractDescription } from './ContractOptionRadio';

type InputProps = {
  formikProps: FormikContextType<AppendiceConfigurationFormType>;
  checkboxName: string;
  labelText: string;
  fieldName: string;
};

const ResourceInputs = ({
  formikProps,
  checkboxName,
  labelText,
  fieldName,
}: InputProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFieldState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setIsChecked(e.target.checked);

    const objectToBePushed = {
      type: 'BASIC_PRICE_APPENDIX_INCLUDED_RESOURCE_TYPES',
      inclusion: checkboxName,
    };

    if (checked) {
      formikProps.setFieldValue('scopes', [
        ...formikProps.values.scopes!,
        objectToBePushed,
      ]);
    } else {
      const filtered = formikProps.values.scopes?.filter(
        (scope) => scope.inclusion !== checkboxName
      );
      formikProps.setFieldValue('scopes', filtered);
      formikProps.setFieldValue(fieldName, '');
    }
  };

  // use useLayoutEffect because we are updating a DOM node
  useLayoutEffect(() => {
    if (isChecked) {
      inputRef.current?.focus();
    }
  }, [isChecked]);

  return (
    <>
      <Col xs={3}>
        <input
          ref={checkboxRef}
          id={checkboxName}
          type="checkbox"
          name={checkboxName}
          className="form-check-input"
          onChange={handleFieldState}
        />
      </Col>
      <Col xs={4}>
        <Text>{labelText}</Text>
      </Col>
      <Col xs={2}>
        <FormikNumberInput
          ref={inputRef}
          id={fieldName}
          name={fieldName}
          disabled={!isChecked}
        />
      </Col>
      <Col>€ / tunti</Col>
    </>
  );
};

type Props = {
  index: number;
  scope: ContractScope;
};

const ContractResourceOption = ({ scope, index }: Props) => {
  const props = useFormikContext<AppendiceConfigurationFormType>();

  return (
    <FormGroup as={Row} controlId="formCheckboxes" className="my-5 gap-2">
      <ContractDescription>
        <Text>{scope.desc}</Text>
      </ContractDescription>
      <ResourceInputs
        formikProps={props}
        checkboxName="MANAGER"
        labelText="Johtaja"
        fieldName="basicPriceAppendixHourlyRateManager"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="TEAM_LEAD"
        labelText="Lähiesimies"
        fieldName="basicPriceAppendixHourlyRateTeamLead"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="PROJECT_MANAGER"
        labelText="Projektipäällikkö"
        fieldName="basicPriceAppendixHourlyRateProjectManager"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="PROJECT_COORDINATOR"
        labelText="Projektikoordinaattori"
        fieldName="basicPriceAppendixHourlyRateProjectCoordinator"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="ASSISTANT"
        labelText="Assistentti"
        fieldName="basicPriceAppendixHourlyRateAssistant"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="CONSULTANT"
        labelText="Asiantuntija"
        fieldName="basicPriceAppendixHourlyRateConsultant"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="SPECIALIST"
        labelText="Erityisasiantuntija"
        fieldName="basicPriceAppendixHourlyRateSpecialist"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="EMPLOYEE"
        labelText="Työntekijä"
        fieldName="basicPriceAppendixHourlyRateEmployee"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="EMPLOYEE_A"
        labelText="Kategorian A Työntekijä"
        fieldName="basicPriceAppendixHourlyRateEmployeeA"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="EMPLOYEE_B"
        labelText="Kategorian B Työntekijä"
        fieldName="basicPriceAppendixHourlyRateEmployeeB"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="EMPLOYEE_C"
        labelText="Kategorian C Työntekijä"
        fieldName="basicPriceAppendixHourlyRateEmployeeC"
      />
      <ResourceInputs
        formikProps={props}
        checkboxName="EMPLOYEE_D"
        labelText="Kategorian D Työntekijä"
        fieldName="basicPriceAppendixHourlyRateEmployeeD"
      />
    </FormGroup>
  );
};

export default ContractResourceOption;
