import { ToastOptions, ToasterProps } from 'react-hot-toast';
import { createGlobalStyle } from 'styled-components';
import '../../src/assets/fonts/fonts.css';

export const GlobalStyles = createGlobalStyle`
  :root {
    /* color variables */
    --fl-bg-secondary: #fafafa;

    --fl-white: #ffffff;
    --fl-offset-white: #c0c0c0;

    --black: #000000;
    --light-grey: #f2f2f2;
    --grey: #686363;
    --medium-grey: #686363;
    --dark-grey: #4d5357;
    --dark-grey-2: #ebebeb;
    --blue: #2f9efc;
    --dark-blue: #2675f9;
    --dark-blue2: #1432b0;

    /* dimmed */
    --fl-dimmed: #3b3838;

    /* positives */
    --fl-positive-primary: #408A4D;
    --fl-positive-secondary: #B4DF70;
    --fl-positive-tertiary: #E8F6CD;

    /* warnings */
    --fl-warning-primary: #96120B;
    --fl-warning-secondary: #FDA587;
    --fl-warning-tertiary: #FEE8DD;

    --shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

    /* font variables */
    --font-spartan: 'Spartan Medium';
    --font-avenir: 'Avenir';

    /* font size variables */
    --text: 10px;
    /* font weight variables */
    --extra-light: 200;
    --light: 300;
    --regular: 400;
    --medium: 500;
    --bold: 800;
  }

  html,
  body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 100%;

    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }
`;

const toastOptions: ToastOptions = {
  duration: 5000,
  style: {
    padding: '20px',
    borderRadius: '10px',
    border: '1px solid #e3e3e3',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
  },
};

export const toasterConfig: ToasterProps = {
  position: 'bottom-right',
  containerStyle: {
    top: 60,
    left: 60,
    bottom: 60,
    right: 60,
  },
  toastOptions: toastOptions,
};
