import {
  ContractScope,
  Lang,
  Inclusiontype,
  ContractContextData,
  User,
} from '@smarterbiz/fixedlegal-shared';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components/macro';
import { useAppSelector } from '../../../redux/hooks';
import { FormikCheckInput } from '../../Form/FormikInput';
import { H4 } from '../../Headings';
import { Text } from '../../Text';
import { Form, Formik, FormikProps } from 'formik';
import { RootState } from '../../../redux/store';
import { Button } from '../../Button';
import { useNavigate } from 'react-router-dom';
import { AppendiceConfigurationFormType } from './types';
import { contractScopesSelector } from '../../../features/configuration/selectors';
import PricingOption from '../PricingOption';
import ContractResourceOption from './ContractResourceOption';
import HourlyPrice from '../HourlyPrice';
import AppendixOptionRadio from './AppendixOptionRadio';
import ContractOptionCheckboxes from './ContractOptionCheckboxes';
import { Modal } from '../../Modal';
import { InclusionInfo } from './InclusionInfo';
import useSubmitAppendix from '../../../hooks/useSubmitAppendix';

type Props = {
  user?: User | null;
  appendixData?: ContractContextData;
};

export const ConfigureAppendix = ({ user, appendixData }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { configuration } = useAppSelector((state: RootState) => state);
  const navigate = useNavigate();
  const appendiceScopes = useAppSelector(contractScopesSelector);

  const initialValues: AppendiceConfigurationFormType = {
    id: configuration.selectedContractId,
    lang: Lang.FI,
    scopes: appendiceScopes,
  };

  const handleSubmit = useSubmitAppendix();

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={true}
      initialValues={initialValues}
      onSubmit={async (values, helpers) => {
        if (!user) {
          setIsOpen(true);
          return;
        }
        if (configuration.selectedContractId === 'Price_Appendix_Frelancer') {
          helpers.validateField('basicPriceAppendixHourlyRateFreelancer');
        }
        const res = await handleSubmit(user, values);
        if (res) {
          navigate(`/tee-sopimus/${res.id}/valmis`);
        }
      }}
    >
      {(props: FormikProps<AppendiceConfigurationFormType>) => (
        <>
          <Form>
            <Row className="mt-5 text-center">
              <H4>LUO SOPIMUSLIITE</H4>
            </Row>
            <Row className="mt-5 mb-3">
              <Text>
                Aluksi voit räätälöidä sopimusliitteen sisällön vastaamaan
                tarpeitasi vastaamalla alla oleviin kysymyksiin
              </Text>
            </Row>
            <Row className="mt-5 mb-3">
              <Text>Minkäkielisen sopimusliitteen haluat?</Text>
            </Row>
            <Row className="mb-3">
              <Col xs={10} md={6}>
                <FormikCheckInput
                  id="lang-fi"
                  type="radio"
                  name="lang"
                  checked={props.values.lang === 'FI'}
                  value={Lang.FI}
                >
                  Suomenkielisen
                </FormikCheckInput>
              </Col>
              <Col xs={10} md={6}>
                <FormikCheckInput
                  id="lang-en"
                  type="radio"
                  name="lang"
                  checked={props.values.lang === 'EN'}
                  value={Lang.EN}
                >
                  Englanninkielisen
                </FormikCheckInput>
              </Col>
            </Row>
            <div>
              {appendixData?.scopes
                ?.filter(
                  (scope: ContractScope) =>
                    !scope.multiselect &&
                    scope.type !==
                      Inclusiontype.BASIC_PRICE_APPENDIX_FIXED_PRICE_PAYMENT_SCHEDULES
                )
                .map((scope: ContractScope, index: number) =>
                  scope.type ===
                  Inclusiontype.BASIC_PRICE_APPENDIX_SENIORITY_BASED_PRICING_PERCENTAGES ? (
                    <PricingOption key={scope.type} scope={scope} />
                  ) : scope.type ===
                    Inclusiontype.BASIC_PRICE_APPENDIX_INCLUDED_RESOURCE_TYPES ? (
                    <ContractResourceOption
                      key={scope.type}
                      index={index}
                      scope={scope}
                    />
                  ) : (
                    <AppendixOptionRadio
                      index={index}
                      key={scope.type}
                      item={scope}
                      name={scope.type}
                    />
                  )
                )}
            </div>
            {appendixData?.scopes
              ?.filter((scope: ContractScope) => !!scope.multiselect)
              .map((scope: ContractScope) => (
                <React.Fragment key={scope.type}>
                  <div className="d-flex flex-row justify-content-between mt-4 align-items-center">
                    <ContractDescription>
                      <Text>{scope.desc}</Text>
                    </ContractDescription>
                    <InclusionInfo inclusion={scope.type} />
                  </div>
                  <ContractOptionCheckboxes item={scope} />
                </React.Fragment>
              ))}
            <>
              {configuration.selectedContractId === 'Price_Appendix_Frelancer' ? (
                <HourlyPrice />
              ) : null}
            </>
            <StyledRow>
              <Col xs={6} md={8}>
                <Text>
                  Paina Jatka-painiketta päästäksesi sopimusliitteen
                  yhteenvetoon.
                </Text>
              </Col>
              <Col xs={6} md={4} className="h-25 d-flex justify-content-end">
                <Button type="submit" buttonsize="sm">
                  Jatka
                </Button>
              </Col>
            </StyledRow>
          </Form>
          <Modal
            title="LIITTEEN LUONTI"
            body="Liitteiden luonti edellyttää kirjautumista. Kirjautumisen jälkeen pääset tekemään liitteitä veloituksetta"
            isOpen={isOpen}
            handleClose={() => setIsOpen(!isOpen)}
          />
        </>
      )}
    </Formik>
  );
};

const StyledRow = styled(Row)`
  margin-top: 6rem;
  margin-bottom: 1rem;
`;

const ContractDescription = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 0.5em;
`;
