export enum BillingEvent {
    INITIAL_CONTRACT_NO_PACKAGE = 0,
    EXTRA_CONTRACT = 10,
    INITIAL_FEE_LITE = 100,
    INITIAL_FEE_ESSENTIAL = 110,
    INITIAL_FEE_UNLIMITED = 120,
    MONTHLY_FEE_LITE = 200,
    MONTHLY_FEE_ESSENTIAL = 210,
    MONTHLY_FEE_UNLIMITED = 220,
    YEARLY_FEE_LITE = 300,
    YEARLY_FEE_ESSENTIAL = 310,
    YEARLY_FEE_UNLIMITED = 320,
    MONTHLY_FEE_UPGRADE_TO_ESSENTIAL = 410,
    MONTHLY_FEE_UPGRADE_TO_UNLIMITED = 420,
    YEARLY_FEE_UPGRADE_TO_ESSENTIAL = 510,
    YEARLY_FEE_UPGRADE_TO_UNLIMITED = 520,
}

export interface BillingEventData {
    id: string,
    companyName: string,
    companyId: string,
    email: string,
    einvoiceAddress: string,
    einvoiceOperator: string,
    event: BillingEvent,
    billedOn?: string,
    createdOn: string,
    price?: string,
    billingPeriodStart?: string,
    billingPeriodEnd?: string,
    usagePeriodEnd?: string,
}

export interface BillingEventRequest {
    from: string,
    alsoBilled: boolean,
}