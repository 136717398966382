import {
  ContractUpdate,
  User,
  UserAgreementType,
} from '@smarterbiz/fixedlegal-shared';
import { Form, Formik, FormikProps } from 'formik';
import { isEqual } from 'lodash';
import {
  Col,
  Container,
  FormGroup,
  FormLabel,
  Row,
  Spinner,
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { BillingPeriod } from '../../fe-common';
import { fiFormatDate } from '../../helpers/date';
import {
  mediaLargeMax,
  mediaXXLargeMax,
  mediaXXLargeMin,
} from '../../theme/media';
import { Button } from '../Button';
import { FormikCheckInput } from '../Form/FormikInput';
import { H1 } from '../Headings';
import { ListItem, UnorderedList } from '../ListItem';
import { Text } from '../Text';
import { isDowngradingPackage } from './PackageInfo';
import { usePageContentQuery } from '../../api/strapi';
import { useMeQuery, useUpdateAgreementMutation } from '../../api/auth';
import { toast } from 'react-hot-toast';

const isUpgradingPackage = (
  initial?: UserAgreementType,
  selected?: UserAgreementType
) => {
  if (!initial || !selected) {
    return false;
  }
  return parseInt(initial) < parseInt(selected);
};

const ContractsRemainingText = (user: User) => {
  if (user.agreementType === UserAgreementType.NO_PACKAGE) {
    return null;
  }
  if (user.usagePeriodEnd) {
    return (
      <ListItem>
        <Text size="md">
          Sopimuksia on jäljellä {user.contractsLeft} käyttöjaksolla, joka
          päättyy {fiFormatDate(user.usagePeriodEnd)}
        </Text>
      </ListItem>
    );
  }
  return null;
};

const userPackageInUse = (packageType: UserAgreementType) => {
  switch (packageType) {
    case '0':
      return null;
    case '1':
      return 'Lite Fix -paketti';
    case '2':
      return 'Essential Fix -paketti';
    case '3':
      return 'Unlimited FIX -paketti';
    default:
      return '';
  }
};

export type TEditAgreement = {
  agreementType?: UserAgreementType;
  billingPeriod?: BillingPeriod;
};

export const EditAgreement = () => {
  const { isLoading, data: strapiData } = usePageContentQuery('9');
  const { data: user } = useMeQuery();
  const [doUpdate] = useUpdateAgreementMutation();

  const initialAgreementType = () => {
    if (!!user?.agreementTypeAfterExpiry) {
      return user.agreementTypeAfterExpiry as UserAgreementType;
    }
    return user?.agreementType === UserAgreementType.NO_PACKAGE
      ? undefined
      : user?.agreementType;
  };

  const initialValues: TEditAgreement = {
    agreementType: initialAgreementType(),
    billingPeriod: user?.paymentInAdvance
      ? BillingPeriod.YEARLY
      : BillingPeriod.MONTHLY,
  };

  const handleEditAgreement = async (data: ContractUpdate) => {
    try {
      await doUpdate(data);
      toast.success('Muutokset vahvistettu!');
    } catch (error) {
      toast.error('Muutosten vahvistaminen epäonnistui!');
    }
  };

  const monthlyBillingDisabled = (
    userData: User,
    formProps: FormikProps<TEditAgreement>
  ) => {
    // TODO: mitä tämä oli? poista???
    return false;
  };

  const packageInfoText = (
    inputTouched: boolean,
    formValues: TEditAgreement
  ) => {
    let text = '';
    const IMMEDIATE_EFFECT_TEXT = 'Palvelupaketti tulee voimaan heti';
    const EFFECT_ON_RENEWAL_TEXT = 'Muutos tulee voimaan jakson uusiutuessa';

    // if the user has registered with a single contract purchase
    // -> user is a new user
    const isNewUser = user?.agreementType === '0' && !user.paymentInAdvance;

    // T___T this somewhat works for now
    if (isNewUser) {
      if (isUpgradingPackage(user?.agreementType, formValues.agreementType)) {
        text = IMMEDIATE_EFFECT_TEXT;
      }
      if (isNewUser && inputTouched) {
        text = IMMEDIATE_EFFECT_TEXT;
      }
    } else if (!isNewUser) {
      if (isDowngradingPackage(user?.agreementType, formValues.agreementType)) {
        text = EFFECT_ON_RENEWAL_TEXT;
      }
      if (isUpgradingPackage(user?.agreementType, formValues.agreementType)) {
        text = IMMEDIATE_EFFECT_TEXT;
      }
      if (
        (user?.paymentInAdvance && formValues.billingPeriod === 'monthly') ||
        (!user?.paymentInAdvance && formValues.billingPeriod === 'yearly')
      ) {
        text = EFFECT_ON_RENEWAL_TEXT;
      }
    }

    return text;
  };

  const billingInfoText = (user: User) => {
    if (user.agreementType === UserAgreementType.NO_PACKAGE) {
      return 'Sinulla ei ole käytössäsi kuukausipakettia';
    }
    return `${userPackageInUse(user.agreementType)} laskutusjaksolla ${fiFormatDate(
      user.billingPeriodStart ?? ''
    )} - ${fiFormatDate(user.billingPeriodEnd ?? '')}`;
  };

  return (
    <StyledContainer fluid>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={async (values) => {
          await handleEditAgreement({
            agreementType: values.agreementType!,
            paymentInAdvance:
              values.billingPeriod === BillingPeriod.YEARLY ? true : false,
          });
        }}
      >
        {(props) => (
          <Form>
            <Row className="justify-content-center mt-5">
              <Col sm={12} lg={10}>
                <H1>PALVELUSI</H1>
              </Col>
            </Row>
            <FormGroup as={Row} className="justify-content-center">
              <StyledCol lg={{ span: 10 }}>
                <div className="mb-5">
                  <Text size="md">Käytössäsi on seuraavat palvelut</Text>
                </div>
                {!!user ? (
                  <UnorderedList>
                    <ListItem>
                      <Text size="md">{billingInfoText(user)}</Text>
                    </ListItem>
                    <ContractsRemainingText {...user} />
                    <ListItem>
                      <Text size="md">
                        Lisäksi sinulla on {user.credits} krediittiä, joista
                        kukin vastaa yhtä sopimusta
                      </Text>
                    </ListItem>
                    <ListItem>
                      <Text size="md">Liitteitä voit tehdä rajoituksetta</Text>
                    </ListItem>
                  </UnorderedList>
                ) : null}
              </StyledCol>
            </FormGroup>
            <FormGroup
              as={Row}
              className="justify-content-center"
              style={{ marginBottom: '3rem' }}
            >
              <RadioSection xs={12} lg={6}>
                <RadioSectionTitleTextContainer>
                  <Text size="md" dimmed>
                    Valitse palvelupaketti seuraavalle jaksolle
                  </Text>
                </RadioSectionTitleTextContainer>
                <FormGroup as={RadioInputGroup} controlId="liteAgreement">
                  <Col xs={1}>
                    <FormikCheckInput
                      id="liteAgreement"
                      type="radio"
                      name="agreementType"
                      value={UserAgreementType.LITE}
                      checked={
                        props.values.agreementType === UserAgreementType.LITE
                      }
                    />
                  </Col>
                  <Col xs={8}>
                    <FormLabel>
                      <Text size="md" dimmed>
                        Lite Fix 24,90€/kk
                      </Text>
                    </FormLabel>
                  </Col>
                </FormGroup>
                <FormGroup as={RadioInputGroup} controlId="essentialAgreement">
                  <Col xs={1}>
                    <FormikCheckInput
                      id="essentialAgreement"
                      type="radio"
                      name="agreementType"
                      value={UserAgreementType.ESSENTIAL}
                      checked={
                        props.values.agreementType ===
                        UserAgreementType.ESSENTIAL
                      }
                    />
                  </Col>
                  <Col xs={8}>
                    <FormLabel>
                      <Text size="md" dimmed>
                        Essential Fix 34,90€/kk
                      </Text>
                    </FormLabel>
                  </Col>
                </FormGroup>
                <FormGroup as={RadioInputGroup} controlId="unlimitedAgreement">
                  <Col xs={1}>
                    <FormikCheckInput
                      id="unlimitedAgreement"
                      type="radio"
                      name="agreementType"
                      value={UserAgreementType.UNLIMITED}
                      defaultChecked={
                        props.values.agreementType ===
                        UserAgreementType.UNLIMITED
                      }
                    />
                  </Col>
                  <Col xs={8}>
                    <FormLabel>
                      <Text size="md" dimmed>
                        Unlimited Fix 99,90€/kk
                      </Text>
                    </FormLabel>
                  </Col>
                </FormGroup>
              </RadioSection>
              <RadioSection xs={12} lg={4}>
                <RadioSectionTitleTextContainer>
                  <Text size="md">
                    Valitse laskutuskausi seuraavalle jaksolle
                  </Text>
                </RadioSectionTitleTextContainer>
                <FormGroup
                  as={RadioInputGroup}
                  controlId="billingPeriodMonthly"
                >
                  <Col xs={1}>
                    <FormikCheckInput
                      id="billingPeriodMonthly"
                      type="radio"
                      name="billingPeriod"
                      value={BillingPeriod.MONTHLY}
                      checked={
                        props.values.billingPeriod === BillingPeriod.MONTHLY
                      }
                      disabled={user && monthlyBillingDisabled(user, props)}
                      aria-disabled={
                        user && monthlyBillingDisabled(user, props)
                      }
                    />
                  </Col>
                  <Col xs={3}>
                    <FormLabel>
                      <Text size="md" dimmed>
                        Kuukausilaskutus
                      </Text>
                    </FormLabel>
                  </Col>
                </FormGroup>
                <FormGroup as={RadioInputGroup} controlId="billingPeriodYearly">
                  <Col xs={1}>
                    <FormikCheckInput
                      id="billingPeriodYearly"
                      type="radio"
                      name="billingPeriod"
                      value={BillingPeriod.YEARLY}
                      checked={
                        props.values.billingPeriod === BillingPeriod.YEARLY
                      }
                    />
                  </Col>
                  <Col xs={5}>
                    <FormLabel>
                      <Text size="md" dimmed>
                        Vuosilaskutus
                      </Text>
                    </FormLabel>
                  </Col>
                </FormGroup>
              </RadioSection>
              <Row className="justify-content-center">
                <Col xs={12} lg={8}>
                  <StyledRow>
                    <Button
                      type="submit"
                      buttonsize="md"
                      disabled={isEqual(props.initialValues, props.values)}
                      aria-disabled={isEqual(props.initialValues, props.values)}
                    >
                      Vahvista muutokset
                    </Button>
                    <TempMarginFix>
                      {packageInfoText(props.dirty, props.values)}
                    </TempMarginFix>
                  </StyledRow>
                </Col>
                <Col xs={12} lg={2}></Col>
              </Row>
            </FormGroup>
            <StyledStrapiContentRow>
              <Col xs={12} lg={10}>
                <div className="mb-5">
                  <Text size="md">
                    Tietoa pakettien vaihdosta ja yksittäisten sopimusten
                    tilaamisesta
                  </Text>
                </div>
                {isLoading ? <Spinner animation="border" /> : null}
                {!strapiData ? null : (
                  <ReactMarkdown
                    children={strapiData.Content}
                    components={{
                      p: (props: any) => <Text size="md" {...props} />,
                    }}
                  />
                )}
              </Col>
            </StyledStrapiContentRow>
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};

const TempMarginFix = styled.p`
  margin-bottom: -1px;
  color: var(--fl-positive-primary);
`;

const StyledContainer = styled(Container)`
  @media ${mediaLargeMax} {
    padding-top: 0;
  }

  @media ${mediaXXLargeMax} {
    padding-top: 1rem;
  }

  @media ${mediaXXLargeMin} {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
`;

const StyledCol = styled(Col)`
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const RadioSectionTitleTextContainer = styled.div`
  padding-bottom: 1rem;
`;

const RadioSection = styled(Col)`
  padding-top: 0.5rem;
`;

const RadioInputGroup = styled(Row)`
  margin-top: 1rem;
  margin-left: 1rem;
`;

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  margin-top: 2.5rem;
`;

const StyledStrapiContentRow = styled(Row)`
  justify-content: center;
  margin-bottom: 5rem;
`;
