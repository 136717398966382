import React from 'react';
import { Col, Nav, Tab } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { ContractOverview } from '@smarterbiz/fixedlegal-shared';
import { Text } from '../../Text';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  setConfigurationMode,
  setSelectedContract,
} from '../../../features/configuration/configurationSlice';
import useContractTags from '../../../hooks/useContractTags';

type IsActive = {
  isactive: 0 | 1;
};

const bgColor = css`
  background-color: var(--light-grey);
`;

const border = css`
  border: 1px solid var(--fl-offset-white);
  border-radius: 4px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
`;

const ListContainer = styled.div`
  height: 100vh !important;
  position: sticky;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  scrollbar-width: thin;
  background-color: var(--fl-bg-secondary);
`;

const ContractListItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const ContractListItem = styled.button`
  width: 100%;
  display: block;
  text-align: left;
  border: 0;
  background: none;
  margin-left: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  color: var(--dark-blue2);
  font-family: 'Avenir';
  :hover {
    text-decoration: underline;
  }
`;

const StyledTab = styled(Col)<IsActive>`
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 0.5rem;
  ${(props) => props.isactive === 1 && bgColor}
  ${(props) => props.isactive === 1 && border}
`;

const StyledTabContent = styled(Tab.Content)`
  padding-left: 0.8rem;
`;

const StyledTabLink = styled(Nav.Link)`
  cursor: pointer;
  color: var(--grey);
  font-family: 'Avenir';
  :hover {
    color: var(--grey);
    text-decoration: underline;
  }
`;

const EventKeys = {
  CONTRACT: 'CONTRACT',
  APPENDIX: 'APPENDIX',
} as const;

type Props = {
  fetchFn: ({ id, lang }: { id: string; lang: string }) => void;
  contracts: ContractOverview[];
  appendices: ContractOverview[];
};

export const ContractsList = ({ fetchFn, contracts, appendices }: Props) => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector((state) => state.configuration.configurationMode);

  const contractTags = useContractTags(contracts);
  const appendiceTags = useContractTags(appendices);

  const handleSetSelectedContract = (contract: ContractOverview) => {
    const { id, lang } = contract;
    dispatch(setSelectedContract(id));
    fetchFn({ id, lang });
  };

  const handleSetConfigurationMode = (selected: any) => {
    if (mode === selected) return;
    dispatch(setConfigurationMode(selected));
  };

  return (
    <ListContainer>
      <Tab.Container id="tab-container" defaultActiveKey={mode}>
        <Nav onSelect={handleSetConfigurationMode}>
          <StyledTab
            id="contracts-tab-pane"
            isactive={mode === 'CONTRACT' ? 1 : 0}
          >
            <Nav.Item>
              <StyledTabLink
                eventKey={EventKeys.CONTRACT}
                data-testid="list-tab-contracts"
              >
                SOPIMUKSET JA MUUT
              </StyledTabLink>
            </Nav.Item>
          </StyledTab>
          <StyledTab
            id="appendices-tab-pane"
            isactive={mode === 'APPENDIX' ? 1 : 0}
          >
            <Nav.Item>
              <StyledTabLink
                eventKey={EventKeys.APPENDIX}
                data-testid="list-tab-appendices"
              >
                LIITTEET
              </StyledTabLink>
            </Nav.Item>
          </StyledTab>
        </Nav>
        <Col>
          <StyledTabContent>
            <Tab.Pane eventKey={'CONTRACT'}>
              {contractTags.sort().map((tag: string) => {
                return (
                  <div key={tag} data-testid="contract-list-item">
                    <div className="mt-4 mb-4">
                      <Text
                        size="sm"
                        dimmed
                        data-testid="contract-list-category"
                      >
                        {tag.toUpperCase()}
                      </Text>
                    </div>
                    {contracts
                      .filter((contract: ContractOverview) =>
                        contract.tags.includes(tag)
                      )
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((c: ContractOverview, i) => (
                        <ContractListItemContainer key={i}>
                          <ContractListItem
                            aria-labelledby="select-contract-button"
                            onClick={() => handleSetSelectedContract(c)}
                            className="background-offwhite"
                          >
                            {c.name}
                          </ContractListItem>
                        </ContractListItemContainer>
                      ))}
                  </div>
                );
              })}
            </Tab.Pane>
            <Tab.Pane eventKey={'APPENDIX'}>
              {appendiceTags
                .sort((a, b) => a.localeCompare(b))
                .map((tag: string) => {
                  return (
                    <React.Fragment key={tag}>
                      <div className="mt-4 mb-4">
                        <Text size="sm">{tag.toUpperCase()}</Text>
                      </div>
                      {appendices
                        .filter((appendix) => appendix.tags.includes(tag))
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((c: ContractOverview, i) => (
                          <ContractListItemContainer key={i}>
                            <ContractListItem
                              aria-labelledby="select-appendice-button"
                              onClick={() => handleSetSelectedContract(c)}
                              className="background-offwhite"
                            >
                              {c.name}
                            </ContractListItem>
                          </ContractListItemContainer>
                        ))}
                    </React.Fragment>
                  );
                })}
            </Tab.Pane>
          </StyledTabContent>
        </Col>
      </Tab.Container>
    </ListContainer>
  );
};
