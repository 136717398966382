import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { ContractScope, Inclusiontype } from '@smarterbiz/fixedlegal-shared';

export const contractScopesSelector = createSelector(
  (state: RootState) => state.configuration.selectedContractData,
  (contract) => {
    if (!contract?.scopes) {
      return [];
    }
    return contract.scopes.reduce((pre: any, cur: ContractScope) => {
      const { multiselect, defaultValue, type } = cur;
      if (!multiselect) {
        pre.push({ type: type, inclusion: defaultValue });
      } else {
        (defaultValue as string[]).map((value) =>
          pre.push({ type: type, inclusion: value })
        );
      }
      return pre;
    }, []) as { type: string; inclusion: string }[];
  }
);

// gets the selected appendices of the configured contract
export const selectedAppendixesSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const { configuredContract } = state.configuration;
    const scopes = configuredContract?.scopes ?? [];
    const appendixes = scopes
      .filter((scope) => scope.type === 'APPENDICES')
      .map((inclusions) => inclusions.inclusion);

    return appendixes;
  }
);

// gets the appendixes of selected contract
export const contractAppendixesSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const { selectedContractData } = state.configuration;
    const scopes = selectedContractData?.scopes ?? [];
    // find documents that are type of APPENDICES
    const appendixes = scopes.find((scope) => scope.type === 'APPENDICES');
    return appendixes?.values.map((value) => value);
  }
);

export const contractOverviewsSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const {
      configuration: { selectedContractData, configuredContract },
    } = state;

    if (!selectedContractData && !configuredContract) {
      return [];
    }

    const contractScopeOverviews = configuredContract?.scopes
      ?.filter((a) => a.type !== Inclusiontype.APPENDICES)
      .map((inclusion) => {
        // find contract scopes
        const contractScopes = selectedContractData?.scopes?.find(
          (cs) => cs.type === inclusion.type
        );
        // find configured contract scope values
        const scopeValues = contractScopes?.values.find(
          (val) => val.key === inclusion.inclusion
        );

        return scopeValues?.overviewDesc;
      });

    return contractScopeOverviews;
  }
);

export const appendixOverviewsSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const {
      configuration: { selectedContractData, configuredContract },
    } = state;

    if (!selectedContractData && !configuredContract) {
      return [];
    }

    const contractScopeOverviews = configuredContract?.scopes
      ?.filter((a) => a.type !== Inclusiontype.APPENDICES)
      .map((inclusion) => {
        // find contract scopes
        const appendixScopes = selectedContractData?.scopes?.find(
          (cs) => cs.type === inclusion.type
        );
        // find configured contract scope values
        const scopeValues = appendixScopes?.values.find(
          (val) => val.key === inclusion.inclusion
        );

        return scopeValues?.overviewDesc;
      });

    return contractScopeOverviews;
  }
);

// returns a boolean depending on selected contracts skipSignature property
export const requireSignaturesSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const {
      inventory,
      configuration: { selectedContractId },
    } = state;

    const contractRequiresSignatures = inventory.contracts.some(
      (ctr) => ctr.id === selectedContractId && !ctr.skipSignatures
    );

    return contractRequiresSignatures;
  }
);

export const contractHasTermsSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const {
      inventory,
      configuration: { selectedContractData },
    } = state;
    const hasTerms = selectedContractData?.scopes?.some(
      (scope) => scope.type === Inclusiontype.TERM
    );
    return hasTerms || false;
  }
);
