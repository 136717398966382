import { Registration } from '../components/Registration';
import { PageViews } from '@piwikpro/react-piwik-pro';
import { Head } from '../components/Seo';

const RegistrationPage = () => {
  PageViews.trackPageView('RegistrationPage');
  return (
    <>
      <Head title="Luo tili" />
      <Registration />
    </>
  );
};

export default RegistrationPage;
