import { Login } from '../components/Login';
import { PageViews } from '@piwikpro/react-piwik-pro';
import { Head } from '../components/Seo';

const LoginPage = () => {
  PageViews.trackPageView('LoginPage');
  return (
    <>
      <Head title="Kirjaudu" />
      <Login />
    </>
  );
};

export default LoginPage;
