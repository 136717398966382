import {
  ContractUpdate,
  Login,
  PasswordChange,
  ResetPasswordInit,
  ResetPasswordRequest,
  SignUp,
  User,
} from '@smarterbiz/fixedlegal-shared';
import { rootApi } from '.';

type UsersUser = {
  nameofuser: string;
  nameofcompany: string;
  agreement_type: string;
  agreement_type_after_expiry: string;
  payment_in_advance: boolean;
  billing_email: string;
  billing_period_start: string;
  billing_period_end: string;
  usage_period_end: string;
  credits: number;
  phone: string;
  email: string;
  last_login_on: string;
  status: string;
  role: string;
  idofuser: string;
};

export const tags = {
  users: 'Users',
};

const usersApiWithTags = rootApi.enhanceEndpoints({
  addTagTypes: Object.values(tags),
});

export const usersApi = usersApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    users: builder.query<UsersUser[], void>({
      providesTags: [tags.users],
      query: () => ({
        url: '/admin/users',
        method: 'GET',
      }),
    }),
  }),
});

export const { useUsersQuery } = usersApi;
