import { ErrorInfo } from 'react';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { Text } from '../Text';

const AlertContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
  width: 100%;
`;

const StyledAlert = styled(Alert)`
  width: 100%;
  margin: 1rem;
`;

const AlertContent = styled.div`
  padding: 1rem;
  border-radius: 5px;
  background-color: white;
  color: black;
`;

type Props = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  resetError: () => void;
};

export const ErrorComponent = ({ error, errorInfo, resetError }: Props) => {
  return (
    <AlertContainer>
      <StyledAlert variant="danger" onClose={resetError} dismissible>
        <Alert.Heading>Error</Alert.Heading>
        <AlertContent>
          <Text size="md">{error?.message}</Text>
          <Text>{errorInfo?.componentStack}</Text>
        </AlertContent>
      </StyledAlert>
    </AlertContainer>
  );
};
