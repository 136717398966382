import { Form, Row, Col, Alert } from 'react-bootstrap';
import { useContractErrorsQuery } from '../api/fixed';

const Admin = () => {
  const { data } = useContractErrorsQuery();

  return (
    <Form>
      <Row>
        <Col>
          <Alert variant="success">
            <Alert.Heading>Errors in content</Alert.Heading>
            {data ? (
              <>
                Errorlist: <pre>{JSON.stringify(data, null, 2)}</pre>
              </>
            ) : (
              <b>Content ok!</b>
            )}
          </Alert>
        </Col>
      </Row>
    </Form>
  );
};

export default Admin;
