import { Inclusion } from './scope';
import { Lang } from './common';

export enum ParagraphStyle {
    NUMBERED = "NUMBERED",
    BULLETS = "BULLETS",
}

export enum Textstyle {
    BOLD = "BOLD",
}

export interface Text {
    text: string;
    style?: Textstyle[];
}

export interface Clause {
    id: string;
    title: string;
    notes: string;
    lang: Lang;
    pageBreakBefore?: boolean;
    inclusions?: Inclusion[];
    paragraphs: {
        paragraphStyle?: ParagraphStyle;
        level?: number;
        blocks: {
            content: Text;
            inclusions?: Inclusion[];
        }[];
        inclusions?: Inclusion[];
    }[];
}