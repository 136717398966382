import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  cursor: pointer;
`;

const Switch = styled.div`
  width: 4.2rem;
  height: 2.1rem;
  position: relative;
  padding: 1rem;
  background: #b3b3b3;
  border-radius: 2.2rem;

  &:before {
    top: 50%;
    left: 0.25rem;
    width: 1.7rem;
    height: 1.7rem;
    position: absolute;
    transform: translate(0, -50%);
    transition: 300ms all;
    background: white;
    border-radius: 2rem;
    content: '';
  }
`;

const Input = styled.input`
  display: none;

  &:checked + ${Switch} {
    background: var(--blue);

    &:before {
      transform: translate(32px, -50%);
    }
  }
`;

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  checked: boolean;
  handleCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Toggle = ({ name, handleCheck, checked, ...props }: Props) => {
  return (
    <Label>
      <Input type="checkbox" name={name} checked={checked} onChange={handleCheck} {...props} />
      <Switch />
    </Label>
  );
};

export default Toggle;
