import { Col, FormGroup, Row } from 'react-bootstrap';
import { FormikNumberInput } from '../Form/FormikInput';
import { Text } from '../Text';

const validation = (value: number) => {
  let err;
  if (!value) {
    err = 'Anna tuntihinta';
  }
  return err;
};

const HourlyPrice = () => {
  return (
    <FormGroup
      as={Row}
      controlId="basicPriceAppendixHourlyRateFreelancer"
      className="mt-5"
    >
      <Col sm={2} md={3}>
        <Text>Määritä tuntihinta</Text>
      </Col>
      <Col sm={6} md={5}>
        <FormikNumberInput
          name="basicPriceAppendixHourlyRateFreelancer"
          validate={validation}
        />
      </Col>
      <Col sm={2} md={2} className="d-flex align-items-center">
        <Text>€ / tunti</Text>
      </Col>
    </FormGroup>
  );
};

export default HourlyPrice;
