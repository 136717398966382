import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { Inclusiontype } from '@smarterbiz/fixedlegal-shared';

export const generatedContractsSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const {
      generated: { generatedContracts },
      inventory: { contracts },
    } = state;
    const myContracts = generatedContracts.filter((doc) =>
      contracts.find(
        (contract) => contract.id === doc.contractId && !contract.isAppendix
      )
    );
    return myContracts;
  }
);

export const generatedAppendixesSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const {
      generated: { generatedContracts },
      inventory: { appendices },
    } = state;
    const myAppendixes = generatedContracts.filter((doc) =>
      appendices.find(
        (appendice) => appendice.id === doc.contractId && appendice.isAppendix
      )
    );
    return myAppendixes;
  }
);

export const selectedAppendixesSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const { contractGenerationParams } = state.inventory;
    const scopes = contractGenerationParams?.scopes ?? [];
    const appendixes = scopes
      .filter((scope) => scope.type === 'APPENDICES')
      .map((inclusions) => inclusions.inclusion);

    return appendixes;
  }
);

export const contractAppendixesSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const { selectedContract } = state.inventory;
    const scopes = selectedContract?.scopes ?? [];
    const appendixes = scopes.find((scope) => scope.type === 'APPENDICES');
    return appendixes?.values.map((value) => value);
  }
);

export const contractOverviewsSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const {
      inventory: { selectedContract, contractGenerationParams },
    } = state;

    if (!selectedContract && !contractGenerationParams) {
      return [];
    }
    const contractScopeOverviews = contractGenerationParams?.scopes
      ?.filter((a) => a.type !== Inclusiontype.APPENDICES)
      .map((inclusion) => {
        // find contract scopes
        const contractScopes = selectedContract?.scopes?.find(
          (cs) => cs.type === inclusion.type
        );

        // find configured contract scope values
        const scopeValues = contractScopes?.values.find(
          (val) => val.key === inclusion.inclusion
        );

        return scopeValues?.overviewDesc;
      });

    return contractScopeOverviews;
  }
);

export const appendixOverviewsSelector = createSelector(
  (state: RootState) => state,
  (state) => {
    const {
      inventory: { selectedContract, contractGenerationParams },
    } = state;

    if (!selectedContract && !contractGenerationParams) {
      return [];
    }

    const contractScopeOverviews = contractGenerationParams?.scopes
      ?.filter((a) => a.type !== Inclusiontype.APPENDICES)
      .map((inclusion) => {
        // find contract scopes
        const appendixScopes = selectedContract?.scopes?.find(
          (cs) => cs.type === inclusion.type
        );

        // find configured contract scope values
        const scopeValues = appendixScopes?.values.find(
          (val) => val.key === inclusion.inclusion
        );

        return scopeValues?.overviewDesc;
      });

    return contractScopeOverviews;
  }
);
