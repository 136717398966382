import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import { FormikTextInput } from '../../Form/FormikInput';
import { ContractConfigurationFormType } from './types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import fi from 'date-fns/locale/fi';
import { StyledInput } from '../../Form/Input';
import { Label } from '../../Form/Label';
import { Text } from '../../Text';

registerLocale('fi', fi);

export const Party2Signatures = () => {
  const [signeeTime, setSigneeTime] = useState<Date>(new Date());
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<ContractConfigurationFormType>();

  const relation =
    values.relation === 'customer' ? 'party1Signatures' : 'party2Signatures';

  const disabledControl =
    values.relation === 'customer'
      ? values.party1Signatures.signLater
      : values.party2Signatures.signLater;

  const fiTime = (date: Date) => format(date, 'PPPP', { locale: fi });
  const enTime = (date: Date) => format(date, 'PPPP');

  const handleSetSignaturesForLater = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;

    setFieldValue(`${relation}.signLater`, checked);
    setFieldValue(
      `${relation}.signeeName`,
      checked ? ' Add the name of the representative of the organization' : ''
    );
    setFieldValue(
      `${relation}.signeeTitle`,
      checked ? ' Add the title of the representative' : ''
    );
    setFieldValue(
      `${relation}.signeeAddress`,
      checked ? 'Add the city where the signing will take place' : ''
    );
    setFieldValue(
      `${relation}.signeeTime`,
      checked ? 'Add the signing place and date' : ''
    );
    setFieldTouched(`${relation}.signeeName`, false);
    setFieldTouched(`${relation}.signeeTitle`, false);
    setFieldTouched(`${relation}.signeeAddress`, false);
  };

  return (
    <>
      <Row className="mb-4">
        <Text>Lisää toisen sopijapuolen edustajan allekirjoitustiedot</Text>
      </Row>
      <FormGroup as={Row} controlId={`${relation}.signeeName`}>
        <Label column xs={4}>
          Toisen sopijapuolen edustajan nimi:
        </Label>
        <Col xs={5}>
          <FormikTextInput
            name={`${relation}.signeeName`}
            placeholder="Etu- ja sukunimi"
            disabled={disabledControl}
            hideValue={disabledControl}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row} controlId={`${relation}.signeeTitle`}>
        <Label column xs={4}>
          Toisen sopijapuolen edustajan titteli:
        </Label>
        <Col xs={5}>
          <FormikTextInput
            name={`${relation}.signeeTitle`}
            placeholder="Titteli esim. myyntijohtaja"
            disabled={disabledControl}
            hideValue={disabledControl}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row} controlId={`${relation}.signeeAddress`}>
        <Label column xs={4}>
          Allekirjoituspaikka:
        </Label>
        <Col xs={5}>
          <FormikTextInput
            name={`${relation}.signeeAddress`}
            placeholder="Kaupunki"
            disabled={disabledControl}
            hideValue={disabledControl}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row} controlId={`${relation}.signeeTime`}>
        <Label column xs={4}>
          Allekirjoitusaika:
        </Label>
        <Col xs={3}>
          <DatePicker
            name={`${relation}.signeeTime`}
            selected={signeeTime}
            onChange={(date: Date) => {
              // check if date exists, prevents input from crashing the app
              if (date) {
                setSigneeTime(date);
                setFieldValue(
                  `${relation}.signeeTime`,
                  values.lang === 'FI' ? fiTime(date) : enTime(date)
                );
              }
            }}
            dateFormat="dd.MM.yyyy"
            locale="fi"
            customInput={<StyledInput />}
          />
        </Col>
      </FormGroup>
      <FormGroup
        as={Row}
        style={{ marginTop: '3rem', marginBottom: '4rem' }}
        controlId="checkParty2SignatureForLater"
      >
        <Col xs={4} className="text-center">
          <FormCheck
            type="checkbox"
            className="btn-group-vertical"
            checked={disabledControl}
            onChange={handleSetSignaturesForLater}
          />
        </Col>
        <Col xs={8}>
          <Label>
            Haluan jättää tiedot tyhjäksi. Täydennän ne myöhemmin suoraan
            sopimukseen.
          </Label>
        </Col>
      </FormGroup>
    </>
  );
};
