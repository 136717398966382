import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from '../../api/auth';

export interface AuthState {
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthStatus(state: AuthState, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.me.matchFulfilled, (state) => {
      state.isAuthenticated = true;
    });
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state) => {
      state.isAuthenticated = true;
    });
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.isAuthenticated = false;
    });
  },
});
export const { setAuthStatus } = authSlice.actions;

export default authSlice.reducer;
