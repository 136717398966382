export enum Lang {
    FI = "FI",
    EN = "EN",
}

export enum PartyType {
    RECEIVING_PARTY = "RECEIVING_PARTY",
    DISCLOSING_PARTY = "DISCLOSING_PARTY",
    SUPPLIER = "SUPPLIER",
    CUSTOMER = "CUSTOMER",
    PARTNER = "PARTNER",
    COMPANY = "COMPANY",
    INFLUENCER = "INFLUENCER",
    CONSULTANT = "CONSULTANT",
    SUBCONTRACTOR = "SUBCONTRACTOR",
    EMPLOYER = "EMPLOYER",
    EMPLOYEE = "EMPLOYEE",
    FREELANCER = "FREELANCER",
    BUYER = "BUYER",
}

export enum PartyCategory {
    ORGANIZATION = "ORGANIZATION",
    PERSON = "PERSON",
}

export interface Party {
    type: PartyType;
    category: PartyCategory;
    desc: string;
}

export interface PartyDetails {
    name?: string,
    id?: string,
    birthDate?: string;
    address?: string[];
    signeeName?: string;
    signeeTitle?: string;
    signeeTime?: string;
    signeeAddress?: string;
}

export enum ObjectType {
    DELIVERY_OBJECTIVE = "DELIVERY_OBJECTIVE",
    DELIVERABLES = "DELIVERABLES",
    SERVICES = "SERVICES",
}

export enum NdaType {
    AGREEMENT = "AGREEMENT",
    UNDERTAKING = "UNDERTAKING",
}