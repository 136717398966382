import { Col, Container, Row } from 'react-bootstrap';
import { useUsersQuery } from '../../api/users';
import { H1 } from '../Headings';
import { PageSection } from '../Sections';
import { Text } from '../Text';
import { fiFormatDate } from '../../helpers/date';

const UserListing = () => {
  const { data: users, isLoading } = useUsersQuery();

  return (
    <PageSection className="m-5">
      <H1>Käyttäjälistaus</H1>
      {isLoading ? <div>Ladataan...</div> : null}
      <Container fluid className="mt-4">
        {users &&
          users?.map((user) => (
            <Row key={user.idofuser} className="p-2">
              <Col>
                <Text weight="bold">{user.email}</Text>
                <ul>
                  <li>Käyttäjä ID: {user.idofuser}</li>
                  <li>Yritys: {user.nameofcompany}</li>
                  <li>Puhelinnumero: {user.phone}</li>
                  <li>Laskutusosoite: {user.billing_email ?? 'Ei laskutusosoitetta'}</li>
                  <li>Sopimustyyppi: {user.agreement_type}</li>
                  <li>Krediittejä jäljellä: {user.credits}</li>
                  <li>Viimeksi kirjautunut: {user.last_login_on}</li>
                </ul>
              </Col>
            </Row>
          ))}
      </Container>
    </PageSection>
  );
};

export default UserListing;
