import { Row, Col } from 'react-bootstrap';
import { FormikNumberInput } from '../../Form/FormikInput';
import { Text } from '../../Text';

export const SubscriptionPricing = () => {
  return (
    <Row className="mt-4 justify-content-center mb-2">
      <Col md={4}>
        <Text>Anna kuukausihinta</Text>
      </Col>
      <Col md={3}>
        <FormikNumberInput
          id="basicPriceAppendixSubscriptionPrice"
          name="basicPriceAppendixSubscriptionPrice"
        />
      </Col>
      <Col md={2}>
        <Text>€</Text>
      </Col>
    </Row>
  );
};
