import { Navigate, RouteProps } from 'react-router';
import { useAuth } from '../redux/hooks';

type PublicRouteProps = {
  redirectTo: string;
  children: JSX.Element;
};

export const PublicRoute = ({ children, redirectTo }: PublicRouteProps) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
};
