import { ErrorMessage } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { FormikNumberInput } from '../../Form/FormikInput';
import { Text } from '../../Text';
import { Label } from '../../Form/Label';
import { useInfoModal } from '../../../features/modal/hooks';

const ErrorText = styled.p`
  color: var(--fl-warning-primary);
`;

type Props = {
  isDisabled: boolean;
};

export const FixedTerm = ({ isDisabled }: Props) => {
  const dispatchInfoModal = useInfoModal();

  return (
    <Col md={10} className="m-2">
      <Form.Group as={Row}>
        <Col className="pt-4" md={10}>
          <Text>
            Määritä sopimuksen kestoaika, jonka jälkeen sopimus päättyy
            automaattisesti ilman irtisanomisilmoitusta.
          </Text>
        </Col>
        <Col className="pt-4" md={2}>
          <AiOutlineInfoCircle
            size="2rem"
            color="#2675f9"
            onClick={() =>
              dispatchInfoModal({
                title: 'Määräaikainen',
                content: `Sopimus voi olla voimassa esim. 24 kuukautta allekirjoittamispäivästä. 
              Tässä esimerkissä 24 kuukauden kuluttua sopimus päättyy automaattisesti ilman, että kummankaan 
              sopijapuolen tarvitsee antaa irtisanomisilmoitusta.`,
              })
            }
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="fixedTermInMonths">
        <Col md={12} lg={6} xl={4}>
          <Label>Syötä määräajan kesto allekirjoittamispäivästä:</Label>
        </Col>
        <Col xs={8} md={8} lg={2}>
          <FormikNumberInput name="fixedTermInMonths" disabled={isDisabled} />
        </Col>
        <Col className="d-flex align-items-center">
          <Text>Kuukautta</Text>
        </Col>
        <ErrorMessage component={ErrorText} name="fixedTermInMonths" />
      </Form.Group>
    </Col>
  );
};
