import { Container, Spinner } from 'react-bootstrap';
import { styled } from 'styled-components';

const LoadingScreen = () => {
  return (
    <StyledFullHeightContainer fluid>
      <Spinner variant="primary" animation="border" role="status" />
    </StyledFullHeightContainer>
  );
};

const StyledFullHeightContainer = styled(Container)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LoadingScreen;
