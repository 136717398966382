import { Navigate, RouteProps, useLocation } from 'react-router';
import { UserRole } from '@smarterbiz/fixedlegal-shared';
import { useMeQuery } from '../api/auth';

type AdminRouteProps = RouteProps & {
  children: JSX.Element;
};

const AdminRoute = ({ children }: AdminRouteProps) => {
  const location = useLocation();
  const { data: user } = useMeQuery();

  if (user && user?.role !== UserRole.ADMIN) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default AdminRoute;
