import { FormLabel, FormLabelProps } from 'react-bootstrap';
import { styled } from 'styled-components';

export const Label = ({ ...rest }: FormLabelProps) => {
  return <StyledFormLabel {...rest} />;
};

const StyledFormLabel = styled(FormLabel)`
  font-family: var(--font-avenir);
`;
