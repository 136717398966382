import { User, UserAgreementType } from '@smarterbiz/fixedlegal-shared';
import { FormikProps } from 'formik';
import { TEditAgreement } from './EditAgreement';
import { isEqual } from 'lodash';
import { Text } from '../Text';
import { fiFormatDate, incrementedDateByDay } from '../../helpers/date';
import styled from 'styled-components';

export const isUpgradingPackage = (
  initial?: UserAgreementType,
  selected?: UserAgreementType
) => {
  if (!initial || !selected) {
    return false;
  }
  return parseInt(initial) < parseInt(selected);
};

export const isDowngradingPackage = (
  initial?: UserAgreementType,
  selected?: UserAgreementType
) => {
  if (!initial || !selected) {
    return false;
  }
  return parseInt(initial) > parseInt(selected);
};

const MonthlyPackageInfo = ({
  userData,
  props,
}: {
  userData?: User;
  props: FormikProps<TEditAgreement>;
}) => {
  return null;
};

const YearlyPackageInfo = ({
  userData,
  props,
}: {
  userData: User;
  props: FormikProps<TEditAgreement>;
}) => {
  const { agreementType } = userData;
  const isInitialValues = isEqual(
    props.initialValues.agreementType,
    props.values.agreementType
  );
  if (isInitialValues) {
    return null;
  }
  // if current package is being upgraded
  if (
    isUpgradingPackage(
      props.initialValues.agreementType!,
      props.values.agreementType!
    )
  ) {
  }
  // if
  return null;
};

export const PackageInfo = ({
  userData,
  props,
}: {
  userData: User;
  props: FormikProps<TEditAgreement>;
}) => {
  const isInitialValues = isEqual(
    props.initialValues.agreementType,
    props.values.agreementType
  );

  {
    /*
      Ei mitään tekstiä kun ei ole tehnyt muutoksia (DONE)
      Kun vaihtaa esim. Lite Fixiin "pienempään", tulee teksti "Paketti tulee voimaan"
      Jos vaihtaa isompaan, tulee teksti "Muutos astuu voimaan heti"
      Kun vaihtaa esim. KK paketista Vuosi pakettiin = paymentInAdvance === true
        - Muutos astuu voimaan PVM
    */
  }
  // if (userData.paymentInAdvance) {
  //   return <YearlyPackageInfo userData={userData} props={props} />;
  // }
  // return <MonthlyPackageInfo userData={userData} props={props} />;
  return (
    <Text>
      <StyledSpan>
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
      </StyledSpan>
    </Text>
  );
};

const TextWrapper = styled.div`
  margin-top: 12px;
`;

const StyledSpan = styled.span`
  color: var(--fl-warning-primary);
`;

// switch (props.values.agreementType) {
//   case UserAgreementType.LITE:
//     if (isDowngradingAgreement(props.values.agreementType)) {
//       return (
//         <TextWrapper>
//           <Text>
//             <StyledSpan>
//               Muutos astuu voimaan{' '}
//               {incrementedDateByDay(billingPeriodEnd!, 1)}
//             </StyledSpan>
//           </Text>
//         </TextWrapper>
//       );
//     }
//     return (
//       <Text>
//         <StyledSpan>Muutos astuu voimaan heti</StyledSpan>
//       </Text>
//     );
//   case UserAgreementType.ESSENTIAL:
//     if (isDowngradingAgreement(props.values.agreementType)) {
//       return (
//         <TextWrapper>
//           <Text>
//             <StyledSpan>
//               Paketti tulee voimaan{' '}
//               {incrementedDateByDay(billingPeriodEnd!, 1)}
//             </StyledSpan>
//           </Text>
//         </TextWrapper>
//       );
//     }
//     return (
//       <TextWrapper>
//         <Text>
//           <StyledSpan>Muutos astuu voimaan heti</StyledSpan>
//         </Text>
//       </TextWrapper>
//     );
//   case UserAgreementType.UNLIMITED:
//     return (
//       <TextWrapper>
//         <Text>
//           <StyledSpan>
//             Olemme sinuun yhteydessä ja paketti tulee voimaan sopimuksen
//             mukaan
//           </StyledSpan>
//         </Text>
//       </TextWrapper>
//     );
//   default:
//     return null;
// }
