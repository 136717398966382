import styled from "styled-components"
import { Text } from "./Text"

const StyledContainer = styled.div`
	margin: 5rem;
`;

const ErrorHeading = styled.h1`
	font-size: 64px;
`;

export const NotFound = () => {
	return (
		<StyledContainer>
			<ErrorHeading>404</ErrorHeading>
			<Text>Page not found</Text>
		</StyledContainer>
	)
}
