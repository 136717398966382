import { ContractScope } from '@smarterbiz/fixedlegal-shared';
import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import {
  AppendiceConfigurationFormType,
  ContractConfigurationFormType,
} from './types';
import { Text } from '../../Text';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import useAppendixPricingModel from '../../../hooks/useAppendixPricingModel';
import { AppendixPricingModel } from '../../../fe-common';
import { InclusionInfo } from './InclusionInfo';

type Props = {
  index: number;
  item: ContractScope;
  name: string;
};

const AppendixOptionRadio = ({ index, item, name }: Props) => {
  const { selectedContractData } = useAppSelector(
    (state: RootState) => state.configuration
  );

  const { pricingComponent, setPricingModel } = useAppendixPricingModel();

  const { setFieldValue, values } =
    useFormikContext<AppendiceConfigurationFormType>();

  const hasPricingScope = () => {
    if (!values.scopes) {
      return false;
    }
    return values.scopes.some(
      (scope) => scope.type === 'BASIC_PRICE_APPENDIX_PRICING_TYPE'
    );
  };

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFieldValue(`scopes[${index}].inclusion`, e.target.id);

    if (!!selectedContractData) {
      // only id appendice is selected, then a pricing model is chosen
      setPricingModel(e.target.id as AppendixPricingModel);
    }
  }

  return (
    <Form.Group as={Row} controlId="formBias">
      <Col md={12}>
        <div className="d-flex flex-row justify-content-between mt-4 align-items-center">
          <ContractDescription>
            <Text>{item.desc}</Text>
          </ContractDescription>
          <InclusionInfo inclusion={item.type} />
        </div>
        <Row>
          {item.values.map((value) => {
            return (
              <InputContainer key={value.key} xs={10} md={6}>
                <Form.Check>
                  <Form.Check.Input
                    type="radio"
                    name={name}
                    id={value.key}
                    defaultChecked={item.defaultValue.indexOf(value.key) !== -1}
                    onChange={handleOnChange}
                  />
                  <StyledFormLabel htmlFor={value.key}>
                    {value.desc}
                  </StyledFormLabel>
                </Form.Check>
              </InputContainer>
            );
          })}
          {hasPricingScope() ? pricingComponent() : null}
        </Row>
      </Col>
    </Form.Group>
  );
};

export default AppendixOptionRadio;

export const ContractDescription = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 0.5em;
`;

export const InputContainer = styled(Col)`
  padding-top: 5px;
  padding-bottom: 12px;
`;

export const StyledFormLabel = styled(Form.Check.Label)`
  font-family: var(--font-avenir);
  padding-left: 8px;
`;
