import {
  ContractUpdate,
  Login,
  PasswordChange,
  ResetPasswordInit,
  ResetPasswordRequest,
  SignUp,
  User,
} from '@smarterbiz/fixedlegal-shared';
import { rootApi } from '.';
import { TProfile } from '../components/Profile/Profile';
import { fixedApi, fixedTags } from './fixed';

type PickedUser = Pick<
  TProfile,
  'firstName' | 'lastName' | 'companyName' | 'address' | 'addressCityPostCode'
>;

export const authTags = {
  user: 'User',
};

const authApiWithTags = rootApi.enhanceEndpoints({
  addTagTypes: Object.values(authTags),
});

export const authApi = authApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    me: builder.query<User, void>({
      providesTags: [authTags.user],
      query: () => ({
        url: '/checkAuth',
        method: 'GET',
      }),
    }),
    login: builder.mutation<User, Login>({
      invalidatesTags: [authTags.user],
      query: (data: Login) => ({
        url: '/login',
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(arg, api) {
        await api.queryFulfilled;
        // force to refetch user documents
        api.dispatch(fixedApi.util.invalidateTags([fixedTags.documents]));
      },
    }),
    logout: builder.query<string, void>({
      query: () => ({
        url: '/logout',
        method: 'GET',
      }),
    }),
    signUp: builder.mutation<void, SignUp>({
      query: (data) => ({
        url: '/signup',
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation<void, PasswordChange>({
      query: (data) => ({
        url: '/login/password',
        method: 'POST',
        body: data,
      }),
    }),
    initUpdatePassword: builder.mutation<void, ResetPasswordInit>({
      query: (data) => ({
        url: '/passwordreset/init',
        method: 'POST',
        body: data,
      }),
    }),
    updatePasswordRequest: builder.mutation<void, ResetPasswordRequest>({
      query: (data) => ({
        url: '/passwordreset/req',
        method: 'POST',
        body: data,
      }),
    }),
    updateProfile: builder.mutation<User, PickedUser>({
      invalidatesTags: [authTags.user],
      query: (data) => ({
        url: '/profile',
        method: 'POST',
        body: {
          name: `${data.firstName} ${data.lastName}`,
          companyName: data.companyName,
          companyAddress: [data.address, data.addressCityPostCode],
        },
      }),
    }),
    updateAgreement: builder.mutation<User, ContractUpdate>({
      invalidatesTags: [authTags.user],
      query: (data) => ({
        url: '/agreement',
        method: 'POST',
        body: data,
      }),
    }),
    confirmEmail: builder.mutation<void, { confirmId: string }>({
      query: (data) => ({
        url: '/signup/confirm',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useMeQuery,
  useLoginMutation,
  useLogoutQuery,
  useLazyLogoutQuery,
  useSignUpMutation,
  useResetPasswordMutation,
  useUpdateAgreementMutation,
  useUpdateProfileMutation,
  useInitUpdatePasswordMutation,
  useUpdatePasswordRequestMutation,
  useConfirmEmailMutation,
} = authApi;
