import { Pricing } from '../components/Pricing';
import { Head } from '../components/Seo';
import { PageViews } from '@piwikpro/react-piwik-pro';

const PricingPage = () => {
  PageViews.trackPageView('PricingPage');
  return (
    <>
      <Head title="Hinnoittelu" />
      <Pricing />
    </>
  );
};

export default PricingPage;
