import { Contracts } from '../../components/Contract';
import { Head } from '../../components/Seo';

const ContractsPage = () => {
  return (
    <>
      <Head title="Luo sopimus" />
      <Contracts />
    </>
  );
};

export default ContractsPage;
