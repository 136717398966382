import './App.css';
import { Router } from './routes/routes';
import { Toaster } from 'react-hot-toast';
import { ErrorBoundary } from './components/ErrorBoundary';
import { HelmetProvider } from 'react-helmet-async';
import { toasterConfig } from './theme/config';

const App = () => {
  return (
    <HelmetProvider>
      <ErrorBoundary>
        <Router />
        <Toaster {...toasterConfig} />
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
