import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from './Text';
import { RiCopyrightLine } from 'react-icons/ri';
import { SocialButton } from './SocialButton';
import { mediaMediumMax } from '../theme/media';
import { useDataProtectionModal, useTermModal } from '../features/modal/hooks';

const Footer = () => {
  const dispatchDataProtectionModal = useDataProtectionModal();
  const dispatchTermModal = useTermModal();

  return (
    <StyledFooter>
      <FooterItemContainer>
        <Text mdGrey>
          <StyledLink to="/tee-sopimus">Luo sopimus</StyledLink>
        </Text>
        <Text mdGrey>
          <StyledLink to="/rekisteroidy">Luo tili</StyledLink>
        </Text>
      </FooterItemContainer>
      <Wrapper>
        <FooterItemContainer>
          <Text mdGrey>
            <StyledSpan onClick={dispatchDataProtectionModal}>
              Tietosuojaseloste
            </StyledSpan>
          </Text>
          <Text mdGrey>
            <StyledSpan onClick={dispatchTermModal}>Käyttöehdot</StyledSpan>
          </Text>
        </FooterItemContainer>
        <FooterItemContainer>
          <Text mdGrey>info@fixed-legal.com</Text>
          <SocialButton />
        </FooterItemContainer>
        <FooterItemContainer className="centered">
          <Text mdGrey>
            <span className="mr-3">
              <RiCopyrightLine />
            </span>
            SmarterBiz Oy
          </Text>
        </FooterItemContainer>
      </Wrapper>
    </StyledFooter>
  );
};

export default Footer;

const StyledLink = styled(Link)`
  color: var(--blue);
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: var(--blue);
  }
`;

const StyledSpan = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--light-grey);
  padding: 1rem;
  padding-left: 5rem;
  padding-right: 5rem;

  @media ${mediaMediumMax} {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const FooterItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  &.centered {
    justify-content: center;
    color: red;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5rem;

  @media ${mediaMediumMax} {
    flex-direction: column;
    justify-content: space-around;
    gap: 2rem;
  }
`;
