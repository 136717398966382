import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllBlogs, fetchSingleBlog } from './actions';

export type BlogType = {
  id: number;
  Content: string;
  Order: number;
  PostId: string;
  published_at: string;
  created_at: string;
  updated_at: string;
  Image: any;
};

export interface BlogState {
  blogs: BlogType[];
  status: 'idle' | 'loading' | 'failed';
  selectedBlog: BlogType | undefined;
  error: string | undefined;
}

const initialState: BlogState = {
  blogs: [],
  status: 'idle',
  selectedBlog: undefined,
  error: undefined,
};

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllBlogs.pending, (state) => {
      return { ...state, status: 'loading' };
    });
    builder.addCase(fetchAllBlogs.fulfilled, (state, action) => {
      return { ...state, status: 'idle', blogs: action.payload };
    });
    builder.addCase(fetchAllBlogs.rejected, (state) => {
      return { ...state, status: 'failed', error: 'VIRHE!!! VIRHE!!!!' };
    });
    builder.addCase(fetchSingleBlog.pending, (state) => {
      return { ...state, status: 'loading' };
    });
    builder.addCase(fetchSingleBlog.fulfilled, (state, action) => {
      return { ...state, status: 'idle', selectedBlog: action.payload };
    });
    builder.addCase(fetchSingleBlog.rejected, (state) => {
      return { ...state, status: 'failed', error: 'VIRHE!!! VIRHE!!!!' };
    });
  },
});
export default blogSlice.reducer;
