import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ModalTypes = 'INFO_MODAL' | 'STRAPI_MODAL';

type InfoModalProps = {
  visible: boolean;
  title: string;
  content: string;
};

type InfoModalState = {
  modalType: 'INFO_MODAL';
  modalProps: InfoModalProps;
};

type StrapiModalProps = {
  visible: boolean;
  title: string;
  strapiId?: number;
};

type StrapiModalState = {
  modalType: 'STRAPI_MODAL';
  modalProps: StrapiModalProps;
};

// TODO: make type safe, do not use this any method
type InitialAnyState = {
  modalType: any;
  modalProps: any;
};

export type ModalState = InfoModalState | StrapiModalState | InitialAnyState;
export type ModalProps = ModalState['modalProps'];

const initialState: ModalState = {
  modalType: null,
  modalProps: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState: { ...initialState },
  reducers: {
    openModal(state: ModalState, action: PayloadAction<ModalState>) {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
    },
    closeModal(state: ModalState) {
      state.modalType = null;
      state.modalProps = null;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
