import React, { useState } from 'react';
import { Col, Row, FormGroup, FormLabel } from 'react-bootstrap';
import { Button } from '../Button';
import { Text } from '../Text';
import { H2 } from '../Headings';
import { Formik, Form } from 'formik';
import {
  ContractGenerationData,
  Login as LoginType,
  User,
} from '@smarterbiz/fixedlegal-shared';
import { FormikTextInput } from '../Form/FormikInput';
import { LoginSchema } from '../../helpers/validationSchemas';
import { useNavigate } from 'react-router-dom';
import { FixedLink } from '../Link';
import { LoginActionsContainer, LoginContainer, ErrorMessage } from './styled';
import { useLoginMutation } from '../../api/auth';
import { hashData } from '../../helpers/crypto';
import forage from '../../helpers/localForage/config';
import { useCreateContractMutation } from '../../api/fixed';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { resetState } from '../../features/configuration/configurationSlice';
import { Honeypot } from '../Form/Honeypot';
import { RootState } from '../../redux/store';
import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';

type TLoginError = {
  status: number;
  data: string;
  originalStatus?: number;
};

export const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const [createContract] = useCreateContractMutation();
  const [loginError, setLoginError] = useState(false);
  const [honeypotValue, setHoneypotValue] = useState('');

  const initialValues: LoginType = {
    email: '',
    password: '',
  };

  const findHashedEmail = async (
    email: string
  ): Promise<string | undefined> => {
    // generate hash from email
    const hashedEmail = hashData(email);
    // find contract generation params by hashed email
    const storedHash = (await forage.keys()).find((key) =>
      key.includes(hashedEmail)
    );
    return storedHash;
  };

  const getGenerationParams = async (hash: string, userData: User) => {
    const generationParams = await forage.getItem<ContractGenerationData>(hash);
    const companyId = userData.companyId;
    const companyName = userData.companyName;
    const companyAddress = userData.companyAddress as unknown as string;
    const addressArray = companyAddress.split('\n');
    const data = cloneDeep(generationParams!);

    if (!data.relation) {
      toast.error('Failed to get stored contract data!');
      throw new Error('Failed to get stored contract data!');
    }

    if (data.relation === 'customer') {
      console.log(
        'Users data goes to: party2. Selected relation is:',
        data.relation
      );
      data.party2.id = companyId;
      data.party2.name = companyName;
      data.party2.address = [addressArray[0], addressArray[1]];
    } else {
      console.log(
        'Users data goes to: party1. Selected relation is:',
        data.relation
      );
      data.party1.id = companyId;
      data.party1.name = companyName;
      data.party1.address = [addressArray[0], addressArray[1]];
    }
    console.log({ data });

    return data;
  };

  const handleLogin = async (values: LoginType) => {
    if (honeypotValue !== '') {
      return;
    }
    try {
      setLoginError(false);
      const response = await login(values).unwrap();

      const foundHash = await findHashedEmail(values.email);
      if (foundHash) {
        const generationParams = await getGenerationParams(foundHash, response);
        if (generationParams) {
          await createContract(generationParams).unwrap();
          // reset configuration state and clear hash from localStorage if contract generation is successful
          dispatch(resetState());
          await forage.removeItem(foundHash);
        }
      }
      navigate('/');
    } catch (error: unknown) {
      const apiError = error as TLoginError;
      if (apiError && apiError.status === 401) {
        setLoginError(true);
      }
    }
  };

  return (
    <LoginContainer>
      <Row className="w-100 mt-4 mb-5 justify-content-center text-center">
        <H2>KIRJAUDU FIXED LEGAL -TILILLESI</H2>
      </Row>
      <Formik<LoginType>
        enableReinitialize
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={LoginSchema}
        onSubmit={handleLogin}
      >
        {() => (
          <Form>
            <FormGroup
              controlId="loginEmail"
              as={Row}
              className="w-100 justify-content-center align-items-center"
            >
              <Col sm={12} md={3}>
                <FormLabel>
                  <Text size="md">
                    Anna käyttäjätunnuksesi (sähköpostiosoite):
                  </Text>
                </FormLabel>
              </Col>
              <Col sm={12} md={4}>
                <FormikTextInput name="email" placeholder="Sähköpostiosoite" />
                <Honeypot honeySetter={setHoneypotValue} />
              </Col>
            </FormGroup>
            <FormGroup
              controlId="loginPassword"
              as={Row}
              className="w-100 justify-content-center align-items-center"
            >
              <Col sm={12} md={3}>
                <FormLabel>
                  <Text size="md">Anna salasanasi:</Text>
                </FormLabel>
              </Col>
              <Col sm={12} md={4}>
                <FormikTextInput
                  type="password"
                  name="password"
                  placeholder="Salasana"
                />
              </Col>
            </FormGroup>
            <div className="text-center">
              {loginError ? (
                <ErrorMessage>
                  Käyttäjätunnusta ei löydy tai salasana on väärin
                </ErrorMessage>
              ) : null}
            </div>
            <LoginActionsContainer>
              <div>
                <Button type="submit">Kirjaudu</Button>
                <FixedLink to="/unohtunut-salasana">
                  Unohditko salasanasi?
                </FixedLink>
              </div>
            </LoginActionsContainer>
          </Form>
        )}
      </Formik>
    </LoginContainer>
  );
};
