import { useCallback } from 'react';
import { AppendiceConfigurationFormType } from '../components/Contract/Configuration/types';
import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';
import {
  SelectedInclusion,
  Inclusiontype,
  AgreementType,
  User,
} from '@smarterbiz/fixedlegal-shared';
import { useCreateContractMutation } from '../api/fixed';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { parseAppendixConfigurationData } from '../helpers/contracts';
import { setContractGenerationParams } from '../features/configuration/configurationSlice';

const useSubmitAppendix = () => {
  const dispatch = useAppDispatch();
  const [createContract] = useCreateContractMutation();
  const { configuration } = useAppSelector((state) => state);

  const handleSubmitAppendix = useCallback(
    async (user: User, values: AppendiceConfigurationFormType) => {
      const appendiceInclusion: SelectedInclusion = {
        type: Inclusiontype.AGREEMENT_TYPE,
        inclusion: AgreementType.GENERAL_T_AND_CS,
      };
      const formValues = cloneDeep(values);

      try {
        if (user) {
          const userAddress = user.companyAddress as unknown as string;
          const addressArray = userAddress.split('\n');

          if (formValues) {
            formValues.party1 = {
              id: user.companyId,
              name: user.companyName,
              address: addressArray[0],
              addressCityCode: addressArray[1],
            };
          }
          const scopes = formValues.scopes
            ? [...formValues.scopes, appendiceInclusion]
            : [appendiceInclusion];
          formValues.scopes = scopes;

          const generationData = parseAppendixConfigurationData({
            ...formValues,
            id: configuration.selectedContractId,
          });
          dispatch(setContractGenerationParams(generationData));
          const res = await createContract({
            ...generationData,
          }).unwrap();
          return res;
        }
      } catch (error) {
        toast.error('Virhe liitteen generoinnissa!');
      }
    },
    []
  );

  return handleSubmitAppendix;
};

export default useSubmitAppendix;
