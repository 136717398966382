import { Col, Image as ImageComponent } from 'react-bootstrap';
import styled from 'styled-components';
import { Text } from '../Text';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import profit from '../../assets/profit.png';
import { fiFormatDate } from '../../helpers/date';
import { StrapiBlog } from '../../fe-common';

const BlogTitleContainer = styled.div`
  height: 4rem;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 150px;
  border: 2px solid var(--light-grey);
  border-radius: 5px;
`;

type Props = {
  blog?: StrapiBlog;
};

export const BlogListItem = ({ blog }: Props) => {
  const navigate = useNavigate();

  return (
    <Col
      md={6}
      lg={4}
      onClick={() => navigate(`/blogi/${blog?.id}`)}
      style={{ cursor: 'pointer' }}
    >
      <ImageContainer>
        <ImageComponent
          fluid
          src={'/sisalto' + blog?.Image?.url ?? profit}
          alt={Image ? Image.name : 'an example alt text for placeholder image'}
        />
      </ImageContainer>
      <div className="mt-3">
        {/* <Text dimmed>
          {blog?.published_at ? fiFormatDate(blog.published_at) : ''}
        </Text> */}
      </div>
      <BlogTitleContainer>
        <Text dimmed>
          <strong>{blog?.PostId}</strong>
        </Text>
      </BlogTitleContainer>
      {!blog?.Content ? (
        ''
      ) : (
        <ReactMarkdown
          children={blog?.Content.substring(0, 150).concat('...')}
          components={{
            p: (props: any) => <Text dimmed {...props} />,
          }}
        />
      )}
    </Col>
  );
};
