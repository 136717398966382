import { Col, Container, Image, Row } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { H1 } from '../Headings';
import { PageSection } from '../Sections';
import { Text } from '../Text';
import styled from 'styled-components';
import { useBlogQuery } from '../../api/strapi';
import confused from '../../assets/confused.png';

const StyledMarkdown = styled(ReactMarkdown)`
  margin-top: 2rem;
`;

export const SingleBlog = () => {
  const { id } = useParams();
  const { data } = useBlogQuery(id ?? '');

  return (
    <Container fluid>
      <PageSection>
        <Row className="d-flex justify-content-center mb-5">
          <Col sm={10}>
            <H1>{data?.PostId}</H1>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center">
          <Col sm={7}>
            {!data?.Content ? (
              ''
            ) : (
              <StyledMarkdown
                children={data?.Content}
                components={{
                  p: (props: any) => <Text size="md" dimmed {...props} />,
                }}
              />
            )}
          </Col>
          <Col sm={3} className="text-center">
            <Image
              fluid
              width={250}
              src={'/sisalto' + data?.Image?.url ?? confused}
              alt={
                data?.Image
                  ? data?.Image.name
                  : 'an example alt text for placeholder image'
              }
            />
          </Col>
        </Row>
      </PageSection>
    </Container>
  );
};
