import {
  Action,
  combineReducers,
  configureStore,
  PreloadedState,
  ThunkAction,
} from '@reduxjs/toolkit';
import inventoryReducer from '../features/inventory/inventorySlice';
import authReducer from '../features/auth/authSlice';
import generatedReducer from '../features/generated/generatedSlice';
import blogReducer from '../features/blog/blogSlice';
import configurationReducer from '../features/configuration/configurationSlice';
import modalReducer from '../features/modal/modalSlice';
import { fixedApi } from '../api/fixed';
import { strapiApi } from '../api/strapi';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootApi } from '../api';
import { configurationListenerMiddleware } from '../features/configuration/middleware';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'configuration'],
};

const reducers = {
  auth: authReducer,
  inventory: inventoryReducer,
  generated: generatedReducer,
  blog: blogReducer,
  modal: modalReducer,
  configuration: configurationReducer,
  [rootApi.reducerPath]: rootApi.reducer,
  [strapiApi.reducerPath]: strapiApi.reducer,
  // [fixedApi.reducerPath]: fixedApi.reducer,
};

const rootReducerV2 = combineReducers(reducers);

export const persistedReducer = persistReducer(persistConfig, rootReducerV2);

export const storeV2 = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .prepend()
      .concat(
        rootApi.middleware,
        fixedApi.middleware,
        strapiApi.middleware,
        configurationListenerMiddleware.middleware
      ),
});

export const persistor = persistStore(storeV2);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducerV2,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducerV2>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
