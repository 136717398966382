import { BsLinkedin } from 'react-icons/bs';
import styled from 'styled-components';

const StyledLink = styled.a`
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;
  color: var(--fl-white);
  background-color: var(--dark-blue);
  font-size: 12px;
  &:hover {
    color: var(--fl-white);
    background-color: var(--dark-blue2);
  }
`;

export const SocialButton = () => {
  return (
    <div className="d-flex flex-row gap-1 align-items-center">
      <BsLinkedin size={22} color="#2675f9" />
      <StyledLink href="https://www.linkedin.com/company/72721578" target="_blank" rel="nofollow noopener">
        Seuraa
      </StyledLink>
    </div>
  );
};
