import classNames from 'classnames';
import React from 'react';
import './text.css';

type TextSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '1xl' | '2xl' | '3xl';
type TextWeights = 'normal' | 'semibold' | 'bold';

type Props = {
  size?: TextSizes;
  dimmed?: boolean;
  mdGrey?: boolean;
  weight?: TextWeights;
  children: React.ReactNode;
};

export const Text = ({ size, dimmed, mdGrey, weight, children }: Props) => {
  const className = classNames(['text-base'], {
    'text-xs': size === 'xs',
    'text-sm': size === 'sm',
    'text-md': size === 'md',
    'text-lg': size === 'lg',
    'text-xl': size === 'xl',
    'text-1xl': size === '1xl',
    'text-2xl': size === '2xl',
    'text-3xl': size === '3xl',
    'text-grey': dimmed,
    'text-md-grey': mdGrey,
    'text-normal': weight === 'normal',
    'text-semibold': weight === 'semibold',
    'text-bold': weight === 'bold',
  });

  return React.createElement('p', { className: className }, children);
};
