import { useBillingEventsQuery } from '../../api/fixed';
import { H1 } from '../Headings';
import { PageSection } from '../Sections';
import { Text } from '../Text';
import { Form, Table } from 'react-bootstrap';
import { BillingEvent, BillingEventData } from '@smarterbiz/fixedlegal-shared';
import { fiFormatDate } from '../../helpers/date';
import { styled } from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import { StyledInput } from '../Form/Input';
import { useState } from 'react';

type Props = {
  events: BillingEventData[];
};

type TableChoices = 'table1' | 'table2';

export const BillingEvents = () => {
  const date = new Date();
  const fallback = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`;

  const [billingEventsFrom, setBillingEventsFrom] = useState<string>(fallback);
  const [alsoBilled, setAlsoBilled] = useState(true);
  const [tableChoice, setTableChoice] = useState<TableChoices>('table1');

  const { data } = useBillingEventsQuery({
    from: billingEventsFrom,
    alsoBilled,
  });

  if (data) {
    let dateArray = data.map((event) => {
      return event.createdOn;
    });

    dateArray.sort((a, b) => {
      return (
        new Date(a.toString()).getTime() - new Date(b.toString()).getTime()
      );
    });
  }
  return (
    <PageSection className="m-5">
      <H1>Laskutustapahtumat</H1>
      <StyledBillingOptionsRowForm>
        <Form.Group controlId="billingEventsFrom">
          <ReactDatePicker
            selected={new Date(billingEventsFrom)}
            onChange={(date: Date) => {
              // check if date exists, prevents input from crashing the app
              if (date) {
                setBillingEventsFrom(
                  `${date.getFullYear()}-${
                    date.getMonth() + 1
                  }-${date.getDate()}`
                );
              }
            }}
            dateFormat="dd.MM.yyyy"
            locale="fi"
            customInput={<StyledInput />}
          />
        </Form.Group>
        <Form.Group controlId="alsoBilled">
          <Form.Check
            type="checkbox"
            checked={alsoBilled}
            onChange={() => setAlsoBilled(!alsoBilled)}
            label="Näytä laskuttamattomat tapahtumat"
          />
        </Form.Group>
      </StyledBillingOptionsRowForm>
      <StyledBillingOptionsRowForm>
        <Form.Group>
          <Form.Check
            type="radio"
            label="Laskutustaulu 1"
            checked={tableChoice === 'table1'}
            value={'table1'}
            onChange={(e) =>
              setTableChoice(e.currentTarget.value as TableChoices)
            }
          />
          <Form.Check
            type="radio"
            label="Laskutustaulu 2"
            checked={tableChoice === 'table2'}
            value={'table2'}
            onChange={(e) =>
              setTableChoice(e.currentTarget.value as TableChoices)
            }
          />
        </Form.Group>
      </StyledBillingOptionsRowForm>
      {tableChoice === 'table1' ? (
        <BillingEventTable1 events={data ?? []} />
      ) : null}
      {tableChoice === 'table2' ? (
        <BillingEventTable2 events={data ?? []} />
      ) : null}
    </PageSection>
  );
};

const BillingEventTable1 = ({ events }: Props) => {
  const sortableEvents = [...events];
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Yrityksen nimi</th>
            <th>Y-tunnus</th>
            <th>Sähköpostiosoite</th>
            <th>Operaattoritunnus</th>
            <th>Verkkolaskuosoite</th>
          </tr>
        </thead>
        <tbody>
          {sortableEvents
            .sort(
              (a, b) =>
                new Date(b.createdOn).getTime() -
                new Date(a.createdOn).getTime()
            )
            .map((event) => (
              <tr key={event.id}>
                <td>
                  <Text>{event.companyName}</Text>
                </td>
                <td>
                  <Text>{event.companyId}</Text>
                </td>
                <td>
                  <Text>{event.email}</Text>
                </td>
                <td>
                  <Text>{event.einvoiceOperator}</Text>
                </td>
                <td>
                  <Text>{event.einvoiceAddress}</Text>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {events.length === 0 ? (
        <div className="d-flex justify-content-center my-4">
          <Text weight="bold">Ei laskutustapahtumia</Text>
        </div>
      ) : null}
    </>
  );
};

const BillingEventTable2 = ({ events }: Props) => {
  const sortableEvents = [...events];
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Yrityksen nimi</th>
            <th>Y-tunnus</th>
            <th>Laskutustyyppi</th>
            <th>Tilauspäivämäärä</th>
            <th>Käyttöjakson loppu</th>
            <th>Laskutuskauden alku</th>
            <th>Laskutuskauden loppu</th>
            <th>Laskutuspäivämäärä</th>
          </tr>
        </thead>
        <tbody>
          {sortableEvents
            .sort(
              (a, b) =>
                new Date(b.createdOn).getTime() -
                new Date(a.createdOn).getTime()
            )
            .map((event) => (
              <tr key={event.id}>
                <td>
                  <Text>{event.companyName}</Text>
                </td>
                <td>
                  <Text>{event.companyId}</Text>
                </td>
                <td>
                  <Text>{BillingEvent[event.event]}</Text>
                </td>
                <td>
                  <Text>{fiFormatDate(event.createdOn)}</Text>
                </td>
                <td>
                  <Text>
                    {event.usagePeriodEnd
                      ? fiFormatDate(event.usagePeriodEnd)
                      : 'Ei käyttöjakson loppua'}
                  </Text>
                </td>
                <td>
                  <Text>
                    {event.billingPeriodStart
                      ? fiFormatDate(event.billingPeriodStart)
                      : 'Ei laskutuskauden alkua'}
                  </Text>
                </td>
                <td>
                  <Text>
                    {event.billingPeriodEnd
                      ? fiFormatDate(event.billingPeriodEnd)
                      : 'Ei laskutuskauden loppua'}
                  </Text>
                </td>
                <td>
                  <Text>
                    {event.billedOn
                      ? fiFormatDate(event.billedOn)
                      : 'Ei laskutettu'}
                  </Text>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {events.length === 0 ? (
        <div className="d-flex justify-content-center my-4">
          <Text weight="bold">Ei laskutustapahtumia</Text>
        </div>
      ) : null}
    </>
  );
};

const StyledBillingOptionsRowForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding-left: 5px;
`;
