import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContractContextData,
  ContractGenerationData,
  ContractOverview,
} from '@smarterbiz/fixedlegal-shared';
import { fixedApi } from '../../api/fixed';

export enum ConfigurationMode {
  CONTRACT = 'CONTRACT',
  APPENDICE = 'APPENDICE',
}

export interface InventoryState {
  configurationMode: ConfigurationMode;
  contracts: ContractOverview[];
  selectedContractId: string | undefined;
  selectedContract: ContractContextData | undefined;
  appendices: ContractOverview[];
  selectedAppendiceId: string | undefined;
  quotaStatusMessage: string | null;
  contractGenerationParams: ContractGenerationData | undefined;
}

const initialState: InventoryState = {
  configurationMode: ConfigurationMode.CONTRACT,
  contracts: [],
  selectedContractId: undefined,
  selectedContract: undefined,
  appendices: [],
  selectedAppendiceId: undefined,
  quotaStatusMessage: null,
  contractGenerationParams: undefined,
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setSelectedContract(state: InventoryState, action: PayloadAction<string>) {
      state.selectedContractId = action.payload;
    },
    setSelectedAppendix(state: InventoryState, action: PayloadAction<string>) {
      state.selectedAppendiceId = action.payload;
    },
    changeConfigurationMode(
      state: InventoryState,
      action: PayloadAction<ConfigurationMode>
    ) {
      state.configurationMode = action.payload;
      state.selectedContractId = undefined;
      state.selectedAppendiceId = undefined;
      state.selectedContract = undefined;
    },
    setContractGenerationParams(
      state: InventoryState,
      action: PayloadAction<ContractGenerationData>
    ) {
      state.contractGenerationParams = action.payload;
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      fixedApi.endpoints.contracts.matchFulfilled,
      (state, { payload }) => {
        state.contracts = payload;
      }
    );
    builder.addMatcher(
      fixedApi.endpoints.appendixes.matchFulfilled,
      (state, { payload }) => {
        state.appendices = payload;
      }
    );
    builder.addMatcher(
      fixedApi.endpoints.contract.matchFulfilled,
      (state, { payload }) => {
        state.selectedContract = payload;
      }
    );
  },
});

export const {
  changeConfigurationMode,
  setSelectedContract,
  setSelectedAppendix,
  setContractGenerationParams,
  resetState,
} = inventorySlice.actions;

export default inventorySlice.reducer;
