import { Inclusiontype } from '@smarterbiz/fixedlegal-shared';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useInfoModal } from '../../../features/modal/hooks';

type Props = {
  inclusion: Inclusiontype;
};

export const InclusionInfo = ({ inclusion }: Props) => {
  const dispatchInfoModal = useInfoModal();

  switch (inclusion) {
    case 'DPA_SCCS':
      return (
        <AiOutlineInfoCircle
          size="2rem"
          color="#2675f9"
          onClick={() =>
            dispatchInfoModal({
              title: 'Sopijakumppanin sijaintimaa',
              content:
                'Sopijakumppanin sijaintimaa on maa, jossa sen sopimuksen osapuolena oleva yhtiö sijaitsee. EU-maita ovat: Alankomaat, Belgia, Bulgaria, Espanja, Irlanti, Italia, Itävalta, Kreikka, Kroatia, Kypros, Latvia, Liettua, Luxemburg, Malta, Portugali, Puola, Ranska, Romania, Ruotsi, Saksa, Slovakia, Slovenia, Suomi, Tanska, Tšekki, Unkari ja Viro',
            })
          }
        />
      );
    case 'SENSITIVE_PERSONAL_DATA_INCLUDED':
      return (
        <AiOutlineInfoCircle
          size="2rem"
          color="#2675f9"
          onClick={() =>
            dispatchInfoModal({
              title: 'Erityiset henkilötiedot',
              content:
                'Biometriset tiedot henkilöiden tunnistamiseksi tarkoittavat esim. sormenjälkiä sormenjälkitunnistuksella toimivia laitteita varten. Esim. silloin, kun henkilöstöhallintopalveluun tallennetaan tieto yksittäisten työntekijöiden kuulumisesta ammattiliittoon, palvelussa käsitellään erityisiä henkilötietoja.',
            })
          }
        />
      );
    case 'FREQUENCY_OF_PERSONAL_DATA_TRANSFER':
      return (
        <AiOutlineInfoCircle
          size="2rem"
          color="#2675f9"
          onClick={() =>
            dispatchInfoModal({
              title: 'Henkilötietojen luovutus',
              content:
                'Kertaeränä siirto on kyseessä esim. silloin, kun konsultti tekee datanalaysointiprojektin, jonka alussa hän saa analysoitavan data. Jatkuvasta siirrosta on kyse esim. rekrytointi- ja työntekijöiden tietoja tallettavissa järjestelmissä. ',
            })
          }
        />
      );
    case 'TYPE_OF_PERSONAL_DATA_PROCESSING_ACTIVITIES':
      return (
        <AiOutlineInfoCircle
          size="2rem"
          color="#2675f9"
          onClick={() =>
            dispatchInfoModal({
              title: 'Henkilötietojen käsittelytapa',
              content:
                'Aktiivista käsittelyä on esim. konsultin tekemä datanalaysointiprojekti. Passiivista säilytystä on tyypillisesti esim. rekrytointi- ja työntekijöiden tietoja tallentavissa järjestelmissä sekä asiakastietojärjestelmissä. ',
            })
          }
        />
      );
    case 'CATEGORIES_OF_PERSONAL_DATAS':
      return (
        <AiOutlineInfoCircle
          size="2rem"
          color="#2675f9"
          onClick={() =>
            dispatchInfoModal({
              title: 'Henkilötiedot',
              content:
                'Jos sopimuksen kohteena on esim. rekrytointijärjestelmä SaaS-palveluna, mieti, mitä työnhakijoiden henkilötietoja palveluun tallentuu (nimi, yhteystiedot jne.). Valitse kaikki henkilötietojoukot, joita siirretään sovituissa palveluissa toiselle sopijapuolelle. Sen sijaan kummankin sopijapuolen käsitellessä henkilötietoja erikseen omiin tarkoituksiinsa, kyse ei ole sopimuksen kohteena olevasta käsittelystä eikä sinun tule valita mitään henkilötietojoukkoa sen perusteella. Tästä esimerkki on, kun toimittajalla on asiakkaansa eli sopijakumppaninsa edustajan yhteyshenkilöt omassa asiakas- ja markkinointirekisterissään.',
            })
          }
        />
      );
    case 'CATEGORIES_OF_SENSITIVE_PERSONAL_DATAS':
      return (
        <AiOutlineInfoCircle
          size="2rem"
          color="#2675f9"
          onClick={() =>
            dispatchInfoModal({
              title: 'Erityiset henkilötiedot',
              content:
                'Biometriset tiedot henkilöiden tunnistamiseksi tarkoittavat esim. sormenjälkiä sormenjälkitunnistuksella toimivia laitteita varten. Esim. silloin, kun henkilöstöhallintopalveluun tallennetaan tieto yksittäisten työntekijöiden kuulumisesta ammattiliittoon, palvelussa käsitellään erityisiä henkilötietoja.',
            })
          }
        />
      );
    case 'PERSONAL_DATA_PROCESSING_PURPOSES':
      return (
        <AiOutlineInfoCircle
          size="2rem"
          color="#2675f9"
          onClick={() =>
            dispatchInfoModal({
              title: 'Henkilötietojen käsittelytarkoitukset',
              content:
                'Jos henkilötietojen käsittelyliite on esim. osa sopimusta, jolla asiakas hankkii toimittajalta asiakashallintajärjestelmän, valitse myynti tai markkinointi. Yleishallinto tarkoittaa esim. kirjanpitoa. Palveluiden toimitus loppuasiakkaalle tarkoittaa alihankintaa ja muita tilanteita, joissa toimittajan palvelua käytetään toimitukseen loppuasiakkaalle. Ohjelmistojen käytön mahdollistaminen tarkoittaa tilanteita, joissa henkilötiedoille ei ole tarkempaa käyttötarkoitusta, kuten tietojen säilytyspalvelut, joihin tallennetaan sekalaisia tietoja, joihin voi kuulua myös henkilötietoja. ',
            })
          }
        />
      );

    case 'CATEGORIES_OF_DATA_SUBJECTS':
      return (
        <AiOutlineInfoCircle
          size="2rem"
          color="#2675f9"
          onClick={() =>
            dispatchInfoModal({
              title: 'Henkilöryhmät',
              content:
                'Jos sopimuksen kohteena on esim. rekrytointijärjestelmä SaaS-palveluna, valitse työnhakijat. Valitse kaikki henkilöryhmät, joiden henkilötietoja siirretään sovituissa palveluissa toiselle sopijapuolelle. Sen sijaan kummankin sopijapuolen käsitellessä henkilötietoja erikseen omiin tarkoituksiinsa, kyse ei ole sopimuksen kohteena olevasta käsittelystä eikä sinun tule valita mitään ryhmää sen perusteella. Tästä esimerkki on, kun toimittajalla on asiakkaansa eli sopijakumppaninsa edustajan yhteyshenkilöt omassa asiakas- ja markkinointirekisterissään.',
            })
          }
        />
      );
    default:
      return null;
  }
};
