import styled from 'styled-components';

type Props =  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  type: "button" | "submit" | "reset" | undefined;
  text: string;
};

const Base = ({ text, ...props }: Props) => {
  return <button {...props}>{text}</button>;
};

export const TextButton = styled(Base)`
  border: 0;
  background: none;
  font-weight: 600;
`;
