import React, { Component, ErrorInfo } from 'react';
import { ErrorComponent } from './ErrorComponent';

type Props = {
  children: React.ReactNode;
};

type State = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

export class ErrorBoundary extends Component<Props, State> {
  // https://reactjs.org/docs/error-boundaries.html
  state = { error: null, errorInfo: null };

  static getDerivedStateFromError(error: Error) {
    return { error: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  resetError = () => {
    this.setState({ error: null, errorInfo: null });
  };

  shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>): boolean {
    return nextState.error !== nextProps.error;
  }

  render(): React.ReactNode {
    return this.state.error ? (
      <ErrorComponent error={this.state.error} errorInfo={this.state.errorInfo} resetError={this.resetError} />
    ) : (
      this.props.children
    );
  }
}
