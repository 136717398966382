export const widthSmall = 576;
export const widthMedium = 768;
export const widthLarge = 992;
export const widthXlarge = 1200;
export const widthXXlarge = 1400;

export const mediaSmallMin = `screen and (min-width: ${widthSmall}px)`;
export const mediaMediumMin = `screen and (min-width: ${widthMedium}px)`;
export const mediaLargeMin = `screen and (min-width: ${widthLarge}px)`;
export const mediaXLargeMin = `screen and (min-width: ${widthXlarge}px)`;
export const mediaXXLargeMin = `screen and (min-width: ${widthXXlarge}px)`;

export const mediaSmallMax = `screen and (max-width: ${widthSmall}px)`;
export const mediaMediumMax = `screen and (max-width: ${widthMedium}px)`;
export const mediaLargeMax = `screen and (max-width: ${widthLarge}px)`;
export const mediaXLargeMax = `screen and (max-width: ${widthXlarge}px)`;
export const mediaXXLargeMax = `screen and (max-width: ${widthXXlarge}px)`;