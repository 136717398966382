import {
  ContractScope,
  Inclusiontype,
  Lang,
} from '@smarterbiz/fixedlegal-shared';
import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { ContractTerm } from '../ContractTerm';
import { useFormikContext } from 'formik';
import { ContractConfigurationFormType } from './types';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Text } from '../../Text';
import { useInfoModal } from '../../../features/modal/hooks';

const CONTRACT_TYPE_TEXT =
  'Voit luoda tavanomaisen allekirjoitettavan sopimuksen valitsemalla allekirjoitettava sopimus. Yleiset ehdot soveltuvat puolestaan tilanteeseen, jossa haluat julkaista ehdot internetsivuilla ja hyväksyttää ne käyttäjällä sähköisesti ilman allekirjoituksia. Voit käyttää yleisiä ehtoja myös tilanteessa, jossa haluat liittää ne sopimuskumppaniltasi saamaasi sopimukseen liitteeksi. Fixed Legalin -ehdot ovat esimerkki yleisten ehtojen muodossa olevasta sopimuksesta.';
const SERVICE_LEVEL_AGREEMENT_TEXT =
  'Palvelutaso voi esimerkiksi olla sitoumus, että palvelu on saatavilla 99% ajasta arkipäivinä Suomessa klo. 8-16 välisenä aikana. Jos et halua liittää sopimukseen liitettä palvelutasoista (sinulla ei esimerkiksi ole palvelutasoliitettä), valitse ei.';

type Props = {
  index: number;
  item: ContractScope;
};

const ContractOptionRadio = ({ index, item }: Props) => {
  const { setFieldValue, setValues, initialValues, values } =
    useFormikContext<ContractConfigurationFormType>();
  const dispatchInfoModal = useInfoModal();

  const [selectedTerm, setSelectedTerm] = useState<string>(
    item.defaultValue as string
  );

  const scopeName = item.type;
  const isFinnishLanguageSelected = values.lang === Lang.FI;

  // case where a finnish contact is selected and globality is selected
  //  -> set next question (contract globality) to be disabled
  const isFinnishGlobalitySelected =
    isFinnishLanguageSelected && item.type === 'GLOBALITY';

  // check if ai audience is set to myself
  const isAiAudienceSetForMySelfSelected = values.scopes.some(
    (scope) => scope.type === 'AI_AUDIENCE' && scope.inclusion === 'MYSELF'
  );

  // case where ai contract is selected and audience is set to myself
  //  -> set next question (ai allowed use) to be disabled
  const isAiAudienceForMySelfSelected =
    isAiAudienceSetForMySelfSelected && item.type === 'AI_ALLOWED_USE';

  const optionIsDisabled =
    isFinnishGlobalitySelected || isAiAudienceForMySelfSelected;

  const setInitialPartyValues = () => {
    setFieldValue('party1', initialValues.party1);
    setFieldValue('party2', initialValues.party2);
    setFieldValue('party1Signatures', initialValues.party1Signatures);
    setFieldValue('party2Signatures', initialValues.party2Signatures);
  };

  const handleTermOnChange = (id: string) => {
    // if a scope is for terms, we set which term type it is for selected term
    setSelectedTerm(id);
    switch (id) {
      case 'CONTINUOUS':
        setFieldValue('continuousTerm', true);
        setFieldValue('fixedTerm', false);
        setFieldValue('subscriptionTerm', false);
        break;
      case 'FIXED':
        setFieldValue('continuousTerm', false);
        setFieldValue('fixedTerm', true);
        setFieldValue('subscriptionTerm', false);
        break;
      case 'SUBSCRIPTION':
        setFieldValue('continuousTerm', false);
        setFieldValue('fixedTerm', false);
        setFieldValue('subscriptionTerm', true);
        break;
      default:
        return;
    }
  };

  const handleAiAudienceOnChange = () => {
    const indexOfNextScope = values.scopes.findIndex(
      (scope) => scope.type === 'AI_ALLOWED_USE'
    );
    setFieldValue(`scopes[${indexOfNextScope}].inclusion`, 'PERMISSIVE');
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    setFieldValue(`scopes[${index}].inclusion`, id);

    if (e.target.id === 'GENERAL_T_AND_CS') {
      setInitialPartyValues();
    }

    if (scopeName === Inclusiontype.TERM) {
      // if a scope is for terms, we set which term type it is for selected term
      handleTermOnChange(id);
    }
    if (scopeName === Inclusiontype.AI_AUDIENCE) {
      if (e.target.id === 'MYSELF') {
        handleAiAudienceOnChange();
      }
    }
  };

  return (
    <Form.Group as={Row} controlId="formBias">
      <Col md={12}>
        <div className="d-flex justify-content-between align-items-center mt-4 flex-row">
          <ContractDescription dimmed={optionIsDisabled ? 'yes' : 'no'}>
            <Text>{item.desc}</Text>
          </ContractDescription>
          {item.type === 'AGREEMENT_TYPE' ? (
            <AiOutlineInfoCircle
              size="2rem"
              color="#2675f9"
              onClick={() =>
                dispatchInfoModal({
                  title: 'Sopimustyyppi',
                  content: CONTRACT_TYPE_TEXT,
                })
              }
            />
          ) : null}
          {item.type === 'SLA' ? (
            <AiOutlineInfoCircle
              size="2rem"
              color="#2675f9"
              onClick={() =>
                dispatchInfoModal({
                  title: 'Palvelutasot',
                  content: SERVICE_LEVEL_AGREEMENT_TEXT,
                })
              }
            />
          ) : null}
        </div>
        <Row>
          {item.values.map((value) => {
            return (
              <InputContainer key={value.key} xs={10} md={6}>
                <Form.Group controlId={value.key}>
                  <Form.Check
                    type="radio"
                    name={scopeName}
                    id={value.key}
                    label={<StyledFormLabel>{value.desc}</StyledFormLabel>}
                    onChange={handleOnChange}
                    disabled={optionIsDisabled}
                    defaultChecked={item.defaultValue === value.key}
                  />
                </Form.Group>
              </InputContainer>
            );
          })}
          {scopeName === 'TERM' ? <ContractTerm term={selectedTerm} /> : null}
        </Row>
      </Col>
    </Form.Group>
  );
};

export default ContractOptionRadio;

export const ContractDescription = styled.div<{ dimmed?: 'yes' | 'no' }>`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 0.5em;
  color: ${(props) => (props.dimmed === 'yes' ? '#999999' : 'inherit')};
`;

export const InputContainer = styled(Col)`
  padding-top: 5px;
  padding-bottom: 12px;
`;

export const StyledFormLabel = styled(Form.Label)`
  font-family: var(--font-avenir);
  padding-left: 8px;
`;
