import { Formik, FormikHelpers, Form } from 'formik';
import { Col, Container, Row, FormGroup, FormLabel } from 'react-bootstrap';
import { Button } from '../Button';
import { H2 } from '../Headings';
import { Text } from '../Text';
import {
  PasswordChange,
  ResetPasswordInit,
} from '@smarterbiz/fixedlegal-shared';
import { FormikTextInput } from '../Form/FormikInput';
import { FixedLink } from '../Link';
import { LoginActionsContainer, LoginContainer } from './styled';
import { useInitUpdatePasswordMutation } from '../../api/auth';
import { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-hot-toast';

type OmittedResetPasswordInit = Omit<ResetPasswordInit, 'token'>;

export const LoginForgot = () => {
  const captchaKey = '6LfuRGkgAAAAAAsSlfiXafQdD94_jV62mrAbcf02';
  const initialValues: OmittedResetPasswordInit = {
    email: '',
  };

  const captchaRef = useRef<ReCAPTCHA>(null);
  const [request] = useInitUpdatePasswordMutation();

  return (
    <LoginContainer>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (
          values: OmittedResetPasswordInit,
          { setSubmitting }: FormikHelpers<OmittedResetPasswordInit>
        ) => {
          try {
            const captchaToken = await captchaRef.current?.executeAsync();
            captchaRef.current?.reset();

            setSubmitting(false);
            const requestData: ResetPasswordInit = {
              email: values.email,
              token: captchaToken!,
            };
            await request(requestData).unwrap();
            toast.success('Salasanalinkki lähetetty');
          } catch (error) {
            toast.error('Virhe uuden salasanan tilaamisessa');
          }
        }}
      >
        {() => (
          <Form>
            <Row className="w-100 mt-4 mb-5 justify-content-center text-center">
              <H2>TILAA UUSI SALASANA</H2>
            </Row>
            <Row className="w-100 justify-content-center align-items-center">
              <Col md={7}>
                <Text size="md">
                  Anna sähköpostiosoitteesi. Lähetämme sinulle linkin salasanan
                  vaihtamiseksi. Tarkistathan roskapostisi, jos et saanut sitä.
                </Text>
              </Col>
            </Row>
            <FormGroup
              controlId="forgotPassword"
              as={Row}
              className="w-100 mt-4 mb-5 justify-content-center align-items-center"
            >
              <Col sm={12} md={3}>
                <FormLabel>
                  <Text size="md">Sähköpostiosoitteesi:</Text>
                </FormLabel>
              </Col>
              <Col sm={12} md={4}>
                <FormikTextInput name="email" placeholder="Sähköpostiosoite" />
              </Col>
            </FormGroup>
            <LoginActionsContainer>
              <div>
                <Button type="submit">Lähetä</Button>
                <FixedLink to="/kirjaudu">Siirry kirjautumissivulle</FixedLink>
              </div>
            </LoginActionsContainer>
          </Form>
        )}
      </Formik>
      <div className="mt-3">
        <ReCAPTCHA size="invisible" ref={captchaRef} sitekey={captchaKey} />
      </div>
    </LoginContainer>
  );
};
