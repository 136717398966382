import React from 'react';
import styled, { CSSProp, DefaultTheme } from 'styled-components';
import * as Styles from './styled';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  type?: 'button' | 'submit' | 'reset' | undefined;
  buttonsize?: 'xs' | 'sm' | 'md' | 'lg';
  css?: CSSProp;
  children: React.ReactNode;
};

type BtnRef = HTMLButtonElement;

const ForwardedButton = React.forwardRef<BtnRef, Props>((props, ref) => (
  <button ref={ref} {...props}>
    {props.children}
  </button>
));

export const Button = styled(ForwardedButton)<Pick<Props, 'buttonsize'>>`
  ${Styles.buttonBase};
  ${(props) => (props.buttonsize ? Styles[props.buttonsize] : null)};

  ${(props) => props.disabled && `cursor: not-allowed`};

  &:hover {
    background-color: var(--dark-blue);
    color: var(--fl-white);
    box-shadow: var(--shadow);
    -webkit-transition: all 0.1s ease-in;
    -moz-transition: all 0.1s ease-in;
    -o-transition: all 0.1s ease-in;
    -ms-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
  }
  &:focus {
    outline-color: var(--dark-blue);
    outline-offset: 2px;
  }
`;

export const BlockButton = styled(ForwardedButton)<Props>`
  width: 100%;
  display: block;

  padding: 10px;
  border: 0;
  border-radius: 5px;
  background-color: var(--fl-white);
  color: var(--dark-blue);
  box-shadow: var(--shadow);
  font-family: var(--font-avenir);
  font-weight: var(--regular);
`;
