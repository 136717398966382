import { Field, useFormikContext } from 'formik';
import { FormGroup, Row, FormLabel, Col } from 'react-bootstrap';
import { FormikTextInput } from '../Form/FormikInput';
import { Text } from '../Text';
import styled from 'styled-components';
import { RegistrationFormType } from './Registration';
import { useEffect } from 'react';

export const EInvoicingFields = () => {
  const ctx = useFormikContext<RegistrationFormType>();
  const wantsEmailInvoice = ctx.values.wantsEmailInvoice;

  useEffect(() => {
    if (ctx.values.wantsEmailInvoice) {
      ctx.setFieldValue('eInvoiceAddress', '');
      ctx.setFieldValue('eInvoiceOperatorId', '');
      ctx.setFieldTouched('eInvoiceAddress', false);
      ctx.setFieldTouched('eInvoiceOperatorId', false);
      return;
    }
  }, [ctx.values]);

  return (
    <>
      <FormGroup as={Row} className="mb-3" controlId="formBasicCompanyBilling">
        <FormLabel column md={5}>
          <Text size="md">
            <RequiredSpan>Yrityksen verkkolaskuosoite:</RequiredSpan>
          </Text>
        </FormLabel>
        <Col md={6} xs={12}>
          <FormikTextInput
            placeholder={
              wantsEmailInvoice ? '' : 'Esim.001201201311'
            }
            name="eInvoiceAddress"
            disabled={wantsEmailInvoice}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row} className="mb-3" controlId="formBasicCompanyOperator">
        <FormLabel column md={5}>
          <Text size="md">
            <RequiredSpan>Operaattoritunnus:</RequiredSpan>
          </Text>
        </FormLabel>
        <Col md={6} xs={12}>
          <FormikTextInput
            placeholder={wantsEmailInvoice ? '' : 'Operaattoritunnus'}
            name="eInvoiceOperatorId"
            disabled={wantsEmailInvoice}
          />
        </Col>
      </FormGroup>
      <FormGroup
        as={Row}
        className="mb-3 pt-4"
        controlId="formBasicCompanyBill"
      >
        <Col xs={1} md={5} className="text-center">
          <Field type="checkbox" name="wantsEmailInvoice" />
        </Col>
        <Col xs={11} md={6}>
          <Text>Haluan verkkolaskun sijaan sähköpostilaskun</Text>
        </Col>
      </FormGroup>
    </>
  );
};

const RequiredSpan = styled.span`
  &:after {
    content: '*';
  }
`;
