import React from 'react';
import { FormControl, FormControlProps } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledInput = styled(FormControl)`
  font-size: 16px;
  background-color: var(--dark-grey-2);
  color: var(--dark-grey);
  border: 0;
`;

export type InputProps = FormControlProps;

export const Input = (props: InputProps) => {
  return <StyledInput {...props} />;
};
