import localforage from 'localforage';

const forage = localforage;

// use localStorage as storage strategy
forage.setDriver(localforage.LOCALSTORAGE);

forage.config({
  driver: localforage.LOCALSTORAGE,
  name: 'fixed-legal',
  version: 0.1,
});

export default forage;