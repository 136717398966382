export enum Scope {
  SHORT = 'SHORT',
  MEDIUM = 'MEDIUM',
  LONG = 'LONG',
}

export enum Bias {
  PARTY1 = 'PARTY1',
  NEUTRAL = 'NEUTRAL',
  PARTY2 = 'PARTY2',
}

export enum SalaryType {
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY',
  PROVISION = 'PROVISION',
}

export enum EmploymentInclusionOfBenefits {
  YES = 'YES',
  NO = 'NO',
}

export enum EmploymentFixedTermTerminationRight {
  YES = 'YES',
  NO = 'NO',
}

export enum EmploymentRegularWorkHourTypeBlueCollar {
  FIXED = 'FIXED',
  MIN_TO_MAX = 'MIN_TO_MAX',
  SHIFT = 'SHIFT',
}

export enum EmploymentRegularWorkHourTypeWhiteCollar {
  FIXED = 'FIXED',
  MIN_TO_MAX = 'MIN_TO_MAX',
  FLEXI_START_AND_END = 'FLEXI_START_AND_END',
}

export enum GroundsForTemporaryEmployment {
  SUBSTITUTION = 'SUBSTITUTION',
  PROJECT = 'PROJECT',
  SEASONALITY = 'SEASONALITY',
  TRAINEESHIP = 'TRAINEESHIP',
  OTHER_TEXT_FIELD = 'OTHER_TEXT_FIELD',
}

export enum EmploymentType {
  BLUE_COLLAR = 'BLUE_COLLAR',
  WHITE_COLLAR = 'WHITE_COLLAR',
}

export enum EmploymentTerm {
  FIXED = 'FIXED',
  CONTINUOUS = 'CONTINUOUS',
}

export enum EmploymentWorkPremises {
  EMPLOYER = 'EMPLOYER',
  EMPLOYEE = 'EMPLOYEE',
}

export enum EmploymentExtraVacation {
  YES = 'PAID',
  NO = 'UNPAID',
  NONE = 'NONE',
}

export enum EmploymentFullFlexi {
  YES = 'YES',
  NO = 'NO',
}

export enum EmploymentNonCompetition {
  YES = 'YES',
  NO = 'NO',
}

export enum EmploymentTes {
  YES = 'YES',
  NO = 'NO',
}

export enum Globality {
  FINLAND = 'FINLAND',
  NORDIC = 'NORDIC',
  INTERNATIONAL = 'INTERNATIONAL',
}

export enum AiAudience {
  MYSELF = 'MYSELF',
  EMPLOYEES = 'EMPLOYEES',
}

export enum AiAllowedUse {
  PERMISSIVE = 'PERMISSIVE',
  RESTRICTIVE = 'RESTRICTIVE',
}

export enum Sla {
  YES = 'YES',
  NO = 'NO',
}

export enum Term {
  CONTINUOUS = 'CONTINUOUS',
  FIXED = 'FIXED',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum PartiesOfConfidentiality {
  MUTUAL = 'MUTUAL',
  ONE_SIDED = 'ONE_SIDED',
}

export enum AgreementType {
  BILATERAL = 'BILATERAL',
  GENERAL_T_AND_CS = 'GENERAL_T_AND_CS',
  GENERAL_T_AND_CS_PURCHASE = 'GENERAL_T_AND_CS_PURCHASE',
}

export enum EmploymentBenefit {
  CAR = 'CAR',
  PHONE = 'PHONE',
  FOOD = 'FOOD',
}

export enum CodeOfConduct {
  REGULAR = 'REGULAR',
  SUPPLIER = 'SUPPLIER',
}

export enum Appendix {
  PRICE_LIST = 'PRICE_LIST',
  DATA_PROTECTION_APPENDIX = 'DATA_PROTECTION_APPENDIX',
  SERVICE_DESCRIPTION = 'SERVICE_DESCRIPTION',
  PRODUCT_DESCRIPTION = 'PRODUCT_DESCRIPTION',
  SERVICE_LEVELS = 'SERVICE_LEVELS',
  SECURITY_APPENDIX = 'SECURITY_APPENDIX',
  CODE_OF_CONDUCT = 'CODE_OF_CONDUCT',
  PROJECT_PLAN = 'PROJECT_PLAN',
  SPECIFICATIONS = 'SPECIFICATIONS',
  OFFER = 'OFFER',
  RFP = 'RFP',
  REQUIREMENTS = 'REQUIREMENTS',
  PERSONNEL = 'PERSONNEL',
  NO_APPENDICES = 'NO_APPENDICES',
}

export enum BasicPriceAppendixSeniorityBasedPricingPercentage {
  SENIOR = 'SENIOR',
  JUNIOR = 'JUNIOR',
  TRAINEE = 'TRAINEE',
}

export enum BasicPriceAppendixIncludedResourceType {
  MANAGER = 'MANAGER',
  TEAM_LEAD = 'TEAM_LEAD',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  PROJECT_COORDINATOR = 'PROJECT_COORDINATOR',
  ASSISTANT = 'ASSISTANT',
  CONSULTANT = 'CONSULTANT',
  SPECIALIST = 'SPECIALIST',
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYEE_A = 'EMPLOYEE_A',
  EMPLOYEE_B = 'EMPLOYEE_B',
  EMPLOYEE_C = 'EMPLOYEE_C',
  EMPLOYEE_D = 'EMPLOYEE_D',
}

export enum BasicPriceAppendixPricingType {
  TIMEANDMATERIALS = 'TIMEANDMATERIALS',
  SUBSCRIPTION = 'SUBSCRIPTION',
  FIXED = 'FIXED',
  CAP = 'CAP',
}
export enum DpaSccs {
  OUTSIDE_EU = 'OUTSIDE_EU',
  INSIDE_EU = 'INSIDE_EU',
}
export enum SensitivePersonalDataIncluded {
  YES = 'YES',
  NO = 'NO',
}
export enum FrequencyOfPersonalDataTransfer {
  ONEOFF = 'ONEOFF',
  ONGOING = 'ONGOING',
}
export enum TypeOfPersonalDataProcessingActivities {
  ACTIVE = 'ACTIVE',
  PASSIVE = 'PASSIVE',
}
export enum BasicPriceAppendixSeniorityBasedPricingIncluded {
  YES = 'YES',
  NO = 'NO',
}
export enum BasicPriceAppendixFixedPricePaymentSchedule {
  SIGNING = 'SIGNING',
  MID_DELIVERY = 'MID_DELIVERY',
  COMPLETION = 'COMPLETION',
}
export enum CategoriesOfDataSubject {
  EMPLOYEES = 'EMPLOYEES',
  EMERGENCY_CONTACTS_OF_EMPLOYEES = 'EMERGENCY_CONTACTS_OF_EMPLOYEES',
  JOB_APPLICANTS = 'JOB_APPLICANTS',
  CONSUMERS = 'CONSUMERS',
  B2B_CUSTOMERS ='B2B_CUSTOMERS',
  B2B_PARTNERS = 'B2B_PARTNERS',
  WEBSITE_VISITORS = 'WEBSITE_VISITORS',
  SHAREHOLDERS = 'SHAREHOLDERS',
  BUILDING_VISITORS = 'BUILDING_VISITORS',
  R_D_PARTICIPANTS = 'R_D_PARTICIPANTS',
  RAFFLE_PARTICIPANTS = 'RAFFLE_PARTICIPANTS',
  PATIENTS = 'PATIENTS',
  MISCELLANEOUS_DATA_SUBJECTS = 'MISCELLANEOUS_DATA_SUBJECTS'

}
export enum CategoriesOfPersonalData {
  NAME = 'NAME',
  GENDER = 'GENDER',
  CONTACT_INFORMATION = 'CONTACT_INFORMATION',
  AGE = 'AGE',
  SOCIAL_SECURITY_NUMBER = 'SOCIAL_SECURITY_NUMBER',
  TITLE_ETC = 'TITLE_ETC',
  PHOTOS_ETC = 'PHOTOS_ETC',
  EMPLOYEE_DATA = 'EMPLOYEE_DATA',
  JOB_APPLICANT_DATA = 'JOB_APPLICANT_DATA',
  PURCHASE_HISTORY_ETC = 'PURCHASE_HISTORY_ETC',
  INTERESTS_ETC = 'INTERESTS_ETC',
  CREDIT_CARD = 'CREDIT_CARD',
  FINANCIAL_INFORMATION = 'FINANCIAL_INFORMATION',
  WEBSITE_DATA = 'WEBSITE_DATA',
  LOGS = 'LOGS',
  MISCELLANEOUS_PERSONAL_DATA = 'MISCELLANEOUS_PERSONAL_DATA'
}
export enum CategoriesOfSensitivePersonalData {
  TRADE_UNION = 'TRADE_UNION',
  HEALTH = 'HEALTH',
  BIOMETRIC = 'BIOMETRIC',
  POLITICAL_ETC = 'POLITICAL_ETC',
  RACE = 'RACE'
}
export enum PersonalDataProcessingPurpose {
  SALES = 'SALES',
  COMMUNICATIONS = 'COMMUNICATIONS',
  HR= 'HR',
  R_D = 'R_D',
  RECRUITMENT = 'RECRUITMENT',
  ADMIN = 'ADMIN',
  PROVISION_OF_SERVICES = 'PROVISION_OF_SERVICES',
  IT_INFRASTRUCTURE = 'IT_INFRASTRUCTURE'

}

export enum Inclusiontype {
  SCOPE = 'SCOPE',
  BIAS = 'BIAS',
  EMPLOYMENT_INCLUSION_OF_BENEFITS = 'EMPLOYMENT_INCLUSION_OF_BENEFITS',
  EMPLOYMENT_TES = 'EMPLOYMENT_TES',
  EMPLOYMENT_FIXED_TERM_TERMINATION_RIGHT = 'EMPLOYMENT_FIXED_TERM_TERMINATION_RIGHT',
  EMPLOYMENT_REGULAR_WORK_HOUR_TYPE_BLUE_COLLAR = 'EMPLOYMENT_REGULAR_WORK_HOUR_TYPE_BLUE_COLLAR',
  EMPLOYMENT_REGULAR_WORK_HOUR_TYPE_WHITE_COLLAR = 'EMPLOYMENT_REGULAR_WORK_HOUR_TYPE_WHITE_COLLAR',
  EMPLOYMENT_TYPE = 'EMPLOYMENT_TYPE',
  EMPLOYMENT_TERM = 'EMPLOYMENT_TERM',
  EMPLOYMENT_WORK_PREMISES = 'EMPLOYMENT_WORK_PREMISES',
  EMPLOYMENT_EXTRA_VACATION = 'EMPLOYMENT_EXTRA_VACATION',
  EMPLOYMENT_FULL_FLEXI = 'EMPLOYMENT_FULL_FLEXI',
  EMPLOYMENT_NON_COMPETITION = 'EMPLOYMENT_NON_COMPETITION',
  GROUNDS_FOR_TEMPORARY_EMPLOYMENT = 'GROUNDS_FOR_TEMPORARY_EMPLOYMENT',
  SALARY_TYPE = 'SALARY_TYPE',
  GLOBALITY = 'GLOBALITY',
  AI_AUDIENCE = 'AI_AUDIENCE',
  AI_ALLOWED_USE = 'AI_ALLOWED_USE',
  TERM = 'TERM',
  SLA = 'SLA',
  PARTIES_OF_CONFIDENTIALITY = 'PARTIES_OF_CONFIDENTIALITY',
  AGREEMENT_TYPE = 'AGREEMENT_TYPE',
  EMPLOYMENT_BENEFITS = 'EMPLOYMENT_BENEFITS',
  APPENDICES = 'APPENDICES',
  COC = 'COC',
  BASIC_PRICE_APPENDIX_PRICING_TYPE = 'BASIC_PRICE_APPENDIX_PRICING_TYPE',
  BASIC_PRICE_APPENDIX_SENIORITY_BASED_PRICING_INCLUDED = 'BASIC_PRICE_APPENDIX_SENIORITY_BASED_PRICING_INCLUDED',
  BASIC_PRICE_APPENDIX_SENIORITY_BASED_PRICING_PERCENTAGES = 'BASIC_PRICE_APPENDIX_SENIORITY_BASED_PRICING_PERCENTAGES',
  BASIC_PRICE_APPENDIX_INCLUDED_RESOURCE_TYPES = 'BASIC_PRICE_APPENDIX_INCLUDED_RESOURCE_TYPES',
  BASIC_PRICE_APPENDIX_FIXED_PRICE_PAYMENT_SCHEDULES = 'BASIC_PRICE_APPENDIX_FIXED_PRICE_PAYMENT_SCHEDULES',
  DPA_SCCS = 'DPA_SCCS',
  SENSITIVE_PERSONAL_DATA_INCLUDED = 'SENSITIVE_PERSONAL_DATA_INCLUDED',
  FREQUENCY_OF_PERSONAL_DATA_TRANSFER = 'FREQUENCY_OF_PERSONAL_DATA_TRANSFER',
  TYPE_OF_PERSONAL_DATA_PROCESSING_ACTIVITIES = 'TYPE_OF_PERSONAL_DATA_PROCESSING_ACTIVITIES',
  CATEGORIES_OF_DATA_SUBJECTS = 'CATEGORIES_OF_DATA_SUBJECTS',
  CATEGORIES_OF_PERSONAL_DATAS = 'CATEGORIES_OF_PERSONAL_DATAS',
  CATEGORIES_OF_SENSITIVE_PERSONAL_DATAS = 'CATEGORIES_OF_SENSITIVE_PERSONAL_DATAS',
  PERSONAL_DATA_PROCESSING_PURPOSES = 'PERSONAL_DATA_PROCESSING_PURPOSES'
}

export interface Inclusion {
  type: Inclusiontype;
  inclusion: (
    | Scope
    | Bias
    | SalaryType
    | EmploymentInclusionOfBenefits
    | EmploymentTes
    | EmploymentFixedTermTerminationRight
    | EmploymentRegularWorkHourTypeBlueCollar
    | EmploymentType
    | EmploymentRegularWorkHourTypeWhiteCollar
    | GroundsForTemporaryEmployment
    | EmploymentTerm
    | EmploymentWorkPremises
    | EmploymentExtraVacation
    | EmploymentFullFlexi
    | EmploymentNonCompetition
    | Globality
    | AiAudience
    | AiAllowedUse
    | Term
    | Sla
    | PartiesOfConfidentiality
    | AgreementType
    | CodeOfConduct
    | EmploymentBenefit
    | Appendix
    | BasicPriceAppendixPricingType
    | BasicPriceAppendixSeniorityBasedPricingIncluded
    | BasicPriceAppendixSeniorityBasedPricingPercentage
    | BasicPriceAppendixIncludedResourceType
    | BasicPriceAppendixFixedPricePaymentSchedule
    | DpaSccs
    | SensitivePersonalDataIncluded
    | FrequencyOfPersonalDataTransfer
    | TypeOfPersonalDataProcessingActivities
    | CategoriesOfDataSubject
    | CategoriesOfPersonalData
    | CategoriesOfSensitivePersonalData
    | PersonalDataProcessingPurpose
    
    
  )[];
}

export interface SelectedInclusion {
  type: Inclusiontype;
  inclusion:
    | Scope
    | Bias
    | SalaryType
    | EmploymentInclusionOfBenefits
    | EmploymentTes
    | EmploymentFixedTermTerminationRight
    | EmploymentRegularWorkHourTypeBlueCollar
    | EmploymentType
    | EmploymentRegularWorkHourTypeWhiteCollar
    | GroundsForTemporaryEmployment
    | EmploymentTerm
    | EmploymentWorkPremises
    | EmploymentExtraVacation
    | EmploymentFullFlexi
    | EmploymentNonCompetition
    | Globality
    | AiAudience
    | AiAllowedUse
    | Term
    | Sla
    | PartiesOfConfidentiality
    | AgreementType
    | CodeOfConduct
    | EmploymentBenefit
    | Appendix
    | BasicPriceAppendixPricingType
    | BasicPriceAppendixSeniorityBasedPricingIncluded
    | BasicPriceAppendixSeniorityBasedPricingPercentage
    | BasicPriceAppendixIncludedResourceType
    | BasicPriceAppendixFixedPricePaymentSchedule
    | DpaSccs
    | SensitivePersonalDataIncluded
    | FrequencyOfPersonalDataTransfer
    | TypeOfPersonalDataProcessingActivities
    | CategoriesOfDataSubject
    | CategoriesOfPersonalData
    | CategoriesOfSensitivePersonalData
    | PersonalDataProcessingPurpose;
    
}

export interface ContractScope {
  type: Inclusiontype;
  desc: string;
  values: {
    key: string;
    desc: string;
    overviewDesc?: string;
  }[];
  defaultValue: string[] | string;
  multiselect?: boolean;
  priority: number;
}
