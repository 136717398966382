import React from 'react';
import styled, { css, CSSProp, DefaultTheme } from 'styled-components';
import { mediaLargeMax, mediaXLargeMin, mediaXXLargeMax, mediaXXLargeMin } from '../../theme/media';

export const containerBaseStyles = css`
  height: 100%;
  overflow: hidden;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media ${mediaLargeMax} {
    padding-top: 2rem;
  }

  /* @media ${mediaXLargeMin} {
    padding-top: 5rem;
  } */

  /* @media ${mediaXXLargeMax} {
    padding-top: 3rem;
  } */

  /* @media ${mediaXXLargeMin} {
    padding-top: 5rem;
    padding-bottom: 5rem;
  } */
`;

const SectionContainer = styled.section`
  ${containerBaseStyles};
`;

type Props = React.HTMLAttributes<HTMLElement> & {
  css?: CSSProp;
  children: React.ReactNode;
};

export const PageSection = ({ children, ...rest }: Props) => {
  return <SectionContainer {...rest}>{children}</SectionContainer>;
};
