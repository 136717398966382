import { ContractContextData } from '@smarterbiz/fixedlegal-shared';
import styled from 'styled-components/macro';
import { StrapiContractInfo } from '../../fe-common';
import { mediaLargeMax } from '../../theme/media';
import ReactMarkdown from 'react-markdown';
import { Text } from '../Text';

type Props = {
  selectedContractInfo?: StrapiContractInfo;
  showPrice: 'YES' | 'NO';
};

export const ContractInfo = ({ selectedContractInfo, showPrice }: Props) => {
  if (!selectedContractInfo) {
    return null;
  }
  const formattedPrice = selectedContractInfo.Price
    ? selectedContractInfo.Price.toLocaleString('fi-FI', {
        useGrouping: false,
        minimumFractionDigits: 2,
        currency: 'eur',
      })
    : 0;

  return (
    <div>
      {selectedContractInfo && (
        <>
          <Title>{selectedContractInfo.ContractTitle}</Title>
          <Description>
            <ReactMarkdown
              children={selectedContractInfo.Info}
              components={{
                p: (props: any) => <Text size="sm" dimmed {...props} />,
              }}
            />
            {showPrice === 'YES' ? (
              // depending on showPrice prop, if the user is authenticated, contract price is not shown
              // if user is not authenticated, the contract price is shown.
              <PriceContainer>
                <Text size="sm" dimmed>
                  Tee tutustumishintaan:
                </Text>
                <Price>{formattedPrice}€</Price>
              </PriceContainer>
            ) : null}
          </Description>
        </>
      )}
    </div>
  );
};

const Title = styled.h3`
  line-height: 1.2;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 3rem;
  padding: 1rem;
  color: var(--dark-grey);
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-style: normal;

  @media ${mediaLargeMax} {
    font-size: 1.2rem;
  }
`;

const Description = styled.div`
  padding: 1rem;
  text-align: center;
  color: var(--grey);
`;

const PriceContainer = styled.div`
  padding-top: 2rem;
  text-align: center;
`;

const Price = styled.p`
  font-size: 18px;
  color: var(--dark-grey);
`;
