import { Form, Formik } from 'formik';
import { Head } from '../components/Seo';
import { useUpdatePasswordRequestMutation } from '../api/auth';
import { ResetPasswordRequest } from '@smarterbiz/fixedlegal-shared';
import { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Col, Container, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { H2 } from '../components/Headings';
import { Text } from '../components/Text';
import { FormikTextInput } from '../components/Form/FormikInput';
import { styled } from 'styled-components';
import { Button } from '../components/Button';
import { passwordChangeSchema } from '../helpers/validationSchemas';
import { PageViews } from '@piwikpro/react-piwik-pro';

type NewPassword = {
  newPassword: string;
  repeatPassword: string;
};

const NewPassword = () => {
  PageViews.trackPageView('NewPasswordPage');
  const urlParams = useParams<{ id: string }>();
  const captchaKey = '6LfuRGkgAAAAAAsSlfiXafQdD94_jV62mrAbcf02';
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [request] = useUpdatePasswordRequestMutation();
  const confirmId = urlParams.id ?? '';

  return (
    <>
      <Head title="Reset Password" />
      <StyledContainer>
        <Formik<NewPassword>
          initialValues={{ newPassword: '', repeatPassword: '' }}
          validationSchema={passwordChangeSchema}
          onSubmit={async (values) => {
            try {
              const captchaToken = await captchaRef.current?.executeAsync();
              captchaRef.current?.reset();
              const requestData: ResetPasswordRequest = {
                confirmId: confirmId,
                newPassword: values.newPassword,
                token: captchaToken!,
              };
              await request(requestData).unwrap();
              toast.success('Uusi salasana asetettu onnistuneesti');
            } catch (error) {
              toast.error('Virhe uuden salasanan asettamisessa');
            }
          }}
        >
          {() => (
            <Form>
              <Row className="w-100 mt-4 mb-5 justify-content-center text-center">
                <H2>ANNA UUSI SALASANA</H2>
              </Row>
              <FormGroup
                controlId="forgotPassword"
                as={Row}
                className="w-100 mt-4 justify-content-center align-items-center"
              >
                <Col sm={12} md={3}>
                  <FormLabel>
                    <Text size="md">Uusi salasana:</Text>
                  </FormLabel>
                </Col>
                <Col sm={12} md={4}>
                  <FormikTextInput
                    type="password"
                    name="newPassword"
                    placeholder="Uusi salasana"
                  />
                </Col>
              </FormGroup>
              <FormGroup
                controlId="forgotPassword"
                as={Row}
                className="w-100 mb-5 justify-content-center align-items-center"
              >
                <Col sm={12} md={3}>
                  <FormLabel>
                    <Text size="md">Kirjoita salasana uudelleen:</Text>
                  </FormLabel>
                </Col>
                <Col sm={12} md={4}>
                  <FormikTextInput
                    type="password"
                    name="repeatPassword"
                    placeholder="Uusi salasana uudelleen"
                  />
                </Col>
              </FormGroup>
              <Row className="justify-content-center">
                <Button type="submit">Lähetä</Button>
              </Row>
            </Form>
          )}
        </Formik>
        <div className="mt-3">
          <ReCAPTCHA size="invisible" ref={captchaRef} sitekey={captchaKey} />
        </div>
      </StyledContainer>
    </>
  );
};

export default NewPassword;

export const StyledContainer = styled(Container)`
  height: 100%;
  max-width: 1096px;
  max-height: 694px;
  margin-top: 5rem;
  padding: 1rem;
`;
