import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Col, Image, Row } from 'react-bootstrap';
import Footer from '../Footer';
import { Text } from '../Text';
import Raketti from '../../assets/rakettifixed.png';
import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';
import Steps from '../Steps';
import { H1, H2, H3 } from '../Headings';
import { HeroSection, PageSection } from '../Sections';

export const HomeComponent = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeroSection>
        <Row className="justify-content-center">
          <Col md={6} className="mr-5">
            <H1
              css={`
                margin-bottom: 2rem;
              `}
            >
              Haluatko diilit nopeasti maaliin sopivilla ehdoilla?
            </H1>
            <Text size="lg" dimmed>
              Laadi yrityksesi liiketoimintaa edistävät sopimukset vaivattomasti
              verkossa. Se on helppoa, edullista ja tehokasta
            </Text>
            <Text size="lg" dimmed>
              Ainutlaatuinen sopimusten räätälöinti vain muutaman hiiren klikkauksen päässä
            </Text>
            <HotfixBtnWrapper>
              <Button
                type="button"
                buttonsize="lg"
                onClick={() => navigate('/tee-sopimus')}
              >
                Kokeile sitoumuksetta
              </Button>
            </HotfixBtnWrapper>
          </Col>
          <Col md={4} className="text-center">
            <Image
              fluid
              width="315px"
              src={Raketti}
              alt="Fixed Legal sopimukset etusivu"
            />
          </Col>
        </Row>
      </HeroSection>
      <PageSection>
        <Row className="mb-5 text-center">
          <H2>Mistä haluat sopia?</H2>
        </Row>
        <Row className="justify-content-center">
          <Col xs={10} md={10}>
            <Span>
              <Text size="lg">Salassapidosta?</Text>
            </Span>
            <Span>
              <Text size="lg">
                Asiantuntijapalvelusta kuten konsultointiprojektista?
              </Text>
            </Span>
            <Span>
              <Text size="lg">
                Henkilöstönvuokrauksesta eli ns. bodyshoppingista?
              </Text>
            </Span>
            <Row>
              <Col xs={8} md={8}>
                <Text size="lg">
                  Fixed Legalilla voit tehdä nämä sopimukset ja monia muita
                  muutamassa minuutissa.
                </Text>
              </Col>
              <Col xs={8} md={3}>
                <HotfixBtnWrapper>
                  <Button
                    buttonsize="lg"
                    onClick={() => navigate('/tee-sopimus')}
                  >
                    Luo sopimus
                  </Button>
                </HotfixBtnWrapper>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageSection>
      <PageSection css={bg}>
        <Row className="justify-content-center">
          <GridCol md={5}>
            <div className="mb-4">
              <H3>RÄÄTÄLÖITY</H3>
            </div>
            <Text size="lg">
              Saat enemmän kuin yleiset sopimuspohjat. Palvelumme huomioi
              yrityksesi tarpeet. Voit räätälöidä kuhunkin tilanteeseen juuri
              oikean sopimuksen Fixed Legalin ainutlaatuisella palvelulla.  
            </Text>
          </GridCol>
          <GridCol md={5}>
            <div className="mb-4">
              <H3>HELPPO</H3>
            </div>
            <Text size="lg">
              Palvelumme on luotu käyttäjäkokemus edellä. Sinun ei tarvitse
              ymmärtää juridiikkaa luodaksesi sopimuksia palvelussamme.
            </Text>
          </GridCol>
          <GridCol md={5}>
            <div className="mb-4">
              <H3>LUOTETTAVA</H3>
            </div>
            <Text size="lg">
              Kaikki sisältömme ovat kokeneiden juristien ammattimaisesti
              laatimia. Tuotamme ainoastaan sopimuksia, joiden laadusta voimme
              mennä takuuseen.
            </Text>
          </GridCol>
          <GridCol md={5}>
            <div className="mb-4">
              <H3>EDULLINEN</H3>
            </div>
            <Text size="lg">
              Hintamme ovat edulliset ja hinnoittelumme on aina kiinteää,
              selkeää ja ennakoitavaa. Pysyt budjetissa ja säästät rahaa.
            </Text>
          </GridCol>
        </Row>
      </PageSection>
      <Steps />
      <Footer />
    </>
  );
};

const HotfixBtnWrapper = styled.div`
  margin-top: 3rem;
`;
const Span = styled.span`
  margin: 2rem;
`;

const GridCol = styled(Col)`
  padding: 2rem;
`;

const bg = css<{}>`
  background-color: var(--fl-bg-secondary);
`;
