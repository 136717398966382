import { Route, Routes } from 'react-router-dom';
import { ContractReady, OrderConfirmation } from '../components/Contract';
import { ContractSummary } from '../components/Contract/ContractSummary';
import { LoginForgot } from '../components/Login';
import AuthRoute from './AuthRoute';
import Admin from '../components/Admin';
import { Profile } from '../components/Profile';
import { SingleBlog } from '../components/Blog';
import { EmailConfirmation } from '../components/EmailConfirmation';
import { NotFound } from '../components/NotFound';
import { UserInfo } from '../components/UserInfo';
import { PasswordReset } from '../components/PasswordReset';
import { EditAgreement } from '../components/UserAgreement';
import BlogPage from '../pages/Blog';
import AboutPage from '../pages/About';
import PricingPage from '../pages/Pricing';
import ProductPage from '../pages/Product';
import LoginPage from '../pages/Login';
import RegistrationPage from '../pages/Registration';
import BillingEventsPage from '../pages/admin/BillingEvents';
import DocumentsPage from '../pages/Documents';
import AdminRoute from './AdminRoute';
import { Layout } from '../components/Layout';
import { lazy } from 'react';
import RouteLoader from './RouteLoader';
import NewPassword from '../pages/NewPassword';
import UserListingPage from '../pages/admin/UserListing';
import IndexRouteResolver from './IndexRouteResolver';
import ContractRouteResolver from './ContractRouteResolver';
import { PublicRoute } from './PublicRoute';

// TODO - make rest of the pages lazy
const PrivacyPolicyPage = lazy(() => import('../pages/PrivacyPolicy'));
const DataBankPage = lazy(() => import('../pages/DataBank'));

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="*" element={<NotFound />} />
        <Route index element={<IndexRouteResolver />} />
        <Route
          path="kirjaudu"
          element={
            <PublicRoute redirectTo="/">
              <LoginPage />
            </PublicRoute>
          }
        />
        <Route path="unohtunut-salasana" element={<LoginForgot />} />
        <Route path="unohtunut-salasana/:id" element={<NewPassword />} />
        <Route path="rekisteroidy" element={<RegistrationPage />} />
        <Route path="palvelu" element={<ProductPage />} />
        <Route path="hinnoittelu" element={<PricingPage />} />
        <Route path="meista" element={<AboutPage />} />
        <Route path="blogi" element={<BlogPage />} />
        <Route path="blogi/:id" element={<SingleBlog />} />
        <Route path="tee-sopimus" element={<ContractRouteResolver />} />
        <Route
          path="tee-sopimus/:id/yhteenveto"
          element={<ContractSummary />}
        />
        <Route
          path="tee-sopimus/:id/valmis"
          element={
            <AuthRoute>
              <ContractReady />
            </AuthRoute>
          }
        />
        <Route
          path="tietosuojaseloste"
          element={
            <RouteLoader>
              <PrivacyPolicyPage />
            </RouteLoader>
          }
        />
        <Route
          path="tietopankki"
          element={
            <RouteLoader>
              <DataBankPage />
            </RouteLoader>
          }
        />
        <Route
          path="dokumentit"
          element={
            <AuthRoute>
              <DocumentsPage />
            </AuthRoute>
          }
        />
        <Route
          path="vaihda-salasana"
          element={
            <AuthRoute>
              <PasswordReset />
            </AuthRoute>
          }
        />
        <Route
          path="profiili"
          element={
            <AuthRoute>
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="info"
          element={
            <AuthRoute>
              <UserInfo />
            </AuthRoute>
          }
        />
        <Route
          path="muokkaa-sopimus"
          element={
            <AuthRoute>
              <EditAgreement />
            </AuthRoute>
          }
        />
        <Route
          path="admin"
          element={
            <AdminRoute>
              <Admin />
            </AdminRoute>
          }
        />
        <Route path="laskutustapahtumat" element={<BillingEventsPage />} />
        <Route path="kayttajalistaus" element={<UserListingPage />} />
        <Route path="confirm/:id" element={<EmailConfirmation />} />
        <Route path="tilaus-lahetetty" element={<OrderConfirmation />} />
      </Route>
    </Routes>
  );
};
