import { ContractOverview } from '@smarterbiz/fixedlegal-shared';
import { useMemo } from 'react';

/**
 * Returns the tags of contracts as unique list
 *
 * @param contracts - list of contract or appendices overviews
 * @returns array of unique tags
 */
function useContractTags(contracts: ContractOverview[]) {
  const tags = useMemo(() => {
    const tagSet = contracts.reduce(
      (prev: Set<string>, cur: ContractOverview) => {
        const { tags } = cur;
        tags.forEach((tag) => prev.add(tag));
        return prev;
      },
      new Set<string>()
    );
    // convert set of uniques to array
    const uniques: string[] = Array.from(tagSet);

    return uniques;
  }, [contracts]);

  return tags;
}

export default useContractTags;
