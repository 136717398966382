import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContractGenerationData,
  GeneratedContract,
} from '@smarterbiz/fixedlegal-shared';
import { fixedApi } from '../../api/fixed';

export interface GeneratedState {
  generatedContracts: GeneratedContract[];
  contractGenerationParams: ContractGenerationData | undefined;
}

const initialState: GeneratedState = {
  generatedContracts: [],
  contractGenerationParams: undefined,
};

export const generatedSlice = createSlice({
  name: 'generated',
  initialState,
  reducers: {
    setContractGenerationParams(
      state: GeneratedState,
      action: PayloadAction<ContractGenerationData>
    ) {
      state.contractGenerationParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      fixedApi.endpoints.myDocuments.matchFulfilled,
      (state, { payload }) => {
        state.generatedContracts = payload;
      }
    );
  },
});

export const { setContractGenerationParams } = generatedSlice.actions;

export default generatedSlice.reducer;
