export interface User {
    status?: UserStatus;
    email: string;
    name: string;
    phone?: string;
    title?: string;
    companyId: string;
    companyName: string;
    companyAddress: string[];
    billingEmail?: string;
    eInvoiceOperatorId?: string;
    eInvoiceAddress?: string;
    paymentInAdvance: boolean;
    agreementType: UserAgreementType;
    agreementTypeAfterExpiry?: UserAgreementType;
    billingPeriodStart?: string;
    billingPeriodEnd?: string;
    usagePeriodEnd?: string;
    credits?: number;
    contractsLeft?: number;
    role?: UserRole;
}

export interface UserUpdate {
    name: string;
    phone?: string;
    title?: string;
    companyName?: string;
    companyAddress: string[];
    billingEmail?: string;
    eInvoiceOperatorId?: string;
    eInvoiceAddress?: string;
}

export interface ContractUpdate {
    agreementType: UserAgreementType;
    paymentInAdvance: boolean;
}

export enum UserRole {
    BASIC = '0',
    ADMIN = '1',
}

export enum UserAgreementType {
    NO_PACKAGE = '0',
    LITE = '1',  // 3 agreements/kk
    ESSENTIAL = '2', // 9 agreements/kk
    UNLIMITED = '3' // Unlimited agreements/kk
}

export interface Login {
    email: string;
    password: string;
}

export interface PasswordChange {
    oldPassword: string;
    newPassword: string;
}

export interface ConfirmEmailLink {
    confirmId: string;
}

export interface ResetPasswordInit {
    email: string;
    token: string;
}

export interface ResetPasswordRequest {
    confirmId: string;
    newPassword: string;
    token: string;
}

export interface SignUp {
    user: User,
    password: string;
}

export enum UserStatus {
    ACTIVE = '0',
    DEACTIVATED = '1',
    PENDING = '2',
}