import React from 'react';
import styled, { css } from "styled-components";
import { mediaLargeMax } from "../../theme/media";
import { HeadingType } from "./types";

export const headingBase = css`
  line-height: 1.2;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
`;

export const StyledH1 = styled.h1<HeadingType>`
  ${headingBase};
  font-family: 'Spartan Bold';
  font-size: 40px;
  color: ${props => props.dimmed ? "#4d5357" : null};

  @media ${mediaLargeMax} {
    font-size: 32px;
  }
`;

export const StyledH2 = styled.h2<HeadingType>`
  ${headingBase};
  font-family: 'Spartan Bold';
  font-size: 32px;
  color: ${(props) => (props.dimmed ? '#4d5357' : null)};

  @media ${mediaLargeMax} {
    font-size: 24px;
  }
`;

export const StyledH3 = styled.h3<HeadingType>`
  ${headingBase};
  font-family: 'Spartan Bold';
  font-size: 28px;
  color: ${(props) => (props.dimmed ? '#4d5357' : null)};

  /* @media ${mediaLargeMax} {
    font-size: 20px;
  } */
`;

export const StyledH4 = styled.h4<HeadingType>`
  ${headingBase};
  font-family: 'Spartan Bold';
  font-size: 24px;
  color: ${(props) => (props.dimmed ? '#4d5357' : null)};

  @media ${mediaLargeMax} {
    font-size: 16px;
  }
`;