import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBlogById, fetchBlogs } from './blogAPI';

export const fetchAllBlogs = createAsyncThunk('blog/fetchBlogs', async () => {
  const data = await fetchBlogs();
  return data;
});

export const fetchSingleBlog = createAsyncThunk(
  'inventory/fetchContractByIdAndLang',
  async (params: { id: string; }, thunkApi) => {
    const state = thunkApi.getState();
    console.log('state via thunkApi', state);
    const data = await fetchBlogById(params.id);
    return data;
  }
);
