import About from '../components/About';
import { PageViews } from '@piwikpro/react-piwik-pro';
import { Head } from '../components/Seo';

const AboutPage = () => {
  PageViews.trackPageView('AboutPage');
  return (
    <>
      <Head title="Meistä" />
      <About />
    </>
  );
};

export default AboutPage;
