import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { ModalState, ModalTypes } from './modalSlice';
import { FC } from 'react';
import InfoModal from './InfoModal';
import StrapiModal from './StrapiModal';

type ModalComponentProps = FC<ModalState>;
type TModalComponents = {
  [key in ModalTypes | any]: ModalComponentProps;
};
const ModalComponents: TModalComponents = {
  INFO_MODAL: InfoModal,
  STRAPI_MODAL: StrapiModal,
};

const ModalRoot = () => {
  const { modalType, modalProps } = useAppSelector(
    (state: RootState) => state.modal
  );

  if (!modalType || !modalProps) {
    return null;
  }

  const TargetModal = ModalComponents[modalType];

  return <TargetModal {...modalProps} />;
};

export default ModalRoot;
