export type StrapiPageContent = {
  id: number;
  Content: string;
  Page: string;
  published_at: Date;
  created_at: Date;
  updated_at: Date;
};

export type StrapiContractInfo = {
  id: number;
  AdditionalInfo: string | null;
  ContractId: string;
  ContractTitle: string;
  Info: string;
  Price?: number;
  created_at: string;
  published_at: string;
  updated_at: string;
};

type StrapiImage = {
  id: number;
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: any | null; // ?
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: string | null;
  provider: string;
  provider_metadata: any | null; // ?
  published_at: string;
  updated_at: string;
};

export type StrapiBlog = {
  id: number;
  Content: string;
  Order: number;
  PostId: string;
  created_at: string;
  published_at: string;
  updated_at: string;
  Image?: StrapiImage;
};

export type AppendixPricingModel =
  | 'CAP'
  | 'TIMEANDMATERIALS'
  | 'SUBSCRIPTION'
  | 'FIXED';

export enum BillingPeriod {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export type Nullable<T> = { [K in keyof T]: T[K] | null };
export type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null;
};