import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { H1 } from '../Headings';
import { Text } from '../Text';
import { SummaryChecklist } from './ContractSummary';
import { mediaXLargeMax } from '../../theme/media';
import styled from 'styled-components';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';

const StyledDiv = styled.div`
  margin: 6rem;

  @media ${mediaXLargeMax} {
    margin: 1rem;
  }
`;

export const ContractReady = () => {
  const params = useParams() as { id: string };
  const { configuration } = useAppSelector((state: RootState) => state);
  const generationParams = configuration.configuredContract;
  const configurationMode = configuration.configurationMode;

  return (
    <StyledDiv>
      <Row className="justify-content-center">
        <Col lg={6}>
          <H1>VALMIS!</H1>
        </Col>
        <Col lg={6}></Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col lg={6}>
          <Text size="md">Nyt voit ladata luomasi dokumentin</Text>
          <div className="mb-4">
            <a href={`/api/v2/generated-contracts/${params.id}`} download>
              <Text size="md">LATAA WORD (DOCX)</Text>
            </a>
          </div>
          <Text size="md">
            Dokumentti on ladattavissa vähintään 30 päivän ajan Dokumenttisi
            -osiossa.
          </Text>
        </Col>
      </Row>
      <Text size="md">Lisätietoja:</Text>
      <Row>
        <Col md={6}>
          <Text size="md">
            Olet luonut dokumentin alla olevilla ominaisuuksilla
          </Text>
        </Col>
        <Col md={6}>
          {configurationMode === "CONTRACT" ? (
            <Text size="md">
              Valitsit sopimuksen liiteluetteloon alla olevat liitteet.
              Suosittelemme tekemään ne seuraavaksi.
            </Text>
          ) : null}
        </Col>
      </Row>
      {!!generationParams ? (
        <SummaryChecklist contractGenerationParams={generationParams} />
      ) : null}
    </StyledDiv>
  );
};
