import { UserRole } from '@smarterbiz/fixedlegal-shared';
import { useMeQuery } from '../../api/auth';
import { Navigate } from 'react-router-dom';
import UserListing from '../../components/UserListing.tsx/UserListing';

const UserListingPage = () => {
  const { data: user } = useMeQuery();

  if (user && user?.role !== UserRole.ADMIN) {
    return <Navigate to="/" />;
  }

  return <UserListing />;
};

export default UserListingPage;
