type HoneypotProps = {
  honeySetter: (value: string) => void;
};

export const Honeypot = ({ honeySetter }: HoneypotProps) => {
  return (
    <div style={{ display: 'none' }}>
      <input
        type="text"
        id="verifyEmail"
        name="verifyEmail"
        placeholder="Vahvista sähköposti"
        onChange={(e) => honeySetter(e.target.value)}
      />
    </div>
  );
};
