import { Col, Image, Row, Spinner } from 'react-bootstrap';
import HandShake from '../../assets/handshake.png';
import Footer from '../Footer';
import { Text } from '../Text';
import ReactMarkdown from 'react-markdown';
import { H1, H2 } from '../Headings';
import { PageSection } from '../Sections';
import styled, { css } from 'styled-components/macro';
import { usePageContentQuery } from '../../api/strapi';
import { ExternalLink } from '../Link';

const bg = css`
  background-color: var(--fl-bg-secondary);
`;

const InfoContainer = styled(Row)`
  padding: 1rem;
  padding-bottom: 5rem;
`;

export const About = () => {
  const { isLoading, data, isError } = usePageContentQuery('3');
  const linkedinLink = 'https://www.linkedin.com/company/72721578';

  return (
    <>
      <PageSection css={bg}>
        <Row className="justify-content-center p-3">
          <Col md={5}>
            <H1>Fixed Legal</H1>
            <div className="mt-5">
              {isLoading && !isError ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <ReactMarkdown
                  children={data?.Content ?? ''}
                  components={{
                    p: (props: any) => <Text size="md" {...props} />,
                  }}
                />
              )}
              {isError && !isLoading ? (
                <Text>Sisältöä ei voitu ladata</Text>
              ) : null}
            </div>
          </Col>
          <Col md={5} className="text-center">
            <Image
              width="300px"
              className="mt-5"
              src={HandShake}
              alt="Sopimusmalli ja sopimuksen teko"
              fluid
            />
          </Col>
        </Row>
      </PageSection>
      <PageSection>
        <Row className="justify-content-center p-3">
          <Col md={5}>
            <H2>Ota yhteyttä</H2>
          </Col>
          <Col md={5}></Col>
        </Row>
        <InfoContainer className="justify-content-center">
          <Col md={5}>
            <span className="p-2">
              <Text size="md">
                Voit lähettää meille sähköpostia osoitteeseen
                info(at)fixed-legal.com
              </Text>
            </span>
            <span>
              <Text size="md">
                Seuraamalla Fixed Legalia{' '}
                <ExternalLink
                  href={linkedinLink}
                  target="_blank"
                  rel="nofollow noopener"
                >
                  LinkedInissä
                </ExternalLink>{' '}
                saat tiedon uusista
                blogikirjoituksistamme
              </Text>
            </span>
          </Col>
          <Col md={5}>
            <span className="p-2">
              <Text size="md">
                Puuttuuko Fixed Legalista tarvitsemasi dokumentti tai
                ominaisuus?
              </Text>
            </span>
            <span>
              <Text size="md">
                Voit lähettää meille toiveesi ja palautteesi sähköpostilla.
                Kuuntelemme asiakkaitamme ja kehitämme palveluamme jatkuvasti
                asiakkaidemme tarpeiden pohjalta.
              </Text>
            </span>
          </Col>
        </InfoContainer>
      </PageSection>
      <Footer />
    </>
  );
};
