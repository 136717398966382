import { useCallback, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  CapPricing,
  FixedPricing,
  SubscriptionPricing,
} from '../components/Contract/PricingModel';
import { Text } from '../components/Text';
import { AppendixPricingModel } from '../fe-common';

function useAppendixPricingModel() {
  const [pricingModel, setPricingModel] =
    useState<AppendixPricingModel>('TIMEANDMATERIALS');

  const pricingComponent = useCallback(() => {
    if (pricingModel === 'TIMEANDMATERIALS') {
      return (
        <Row className="justify-content-center">
          <Col md={10} className="m-2">
            <Form.Group as={Row}>
              <Col className="pt-4" md={10}>
                <Text>Lisää tähän TIMEANDMATERIALS teksti</Text>
              </Col>
            </Form.Group>
          </Col>
        </Row>
      );
    } else if (pricingModel === 'CAP') {
      return <CapPricing />;
    } else if (pricingModel === 'SUBSCRIPTION') {
      return <SubscriptionPricing />;
    } else if (pricingModel === 'FIXED') {
      return <FixedPricing />;
    }
    return <></>;
  }, [pricingModel]);

  return { pricingModel, pricingComponent, setPricingModel };
}

export default useAppendixPricingModel;
