import { Product } from '../components/Product';
import { Head } from '../components/Seo';
import { PageViews } from '@piwikpro/react-piwik-pro';

const ProductPage = () => {
  PageViews.trackPageView('ProductPage');
  return (
    <>
      <Head title="Palvelumme" />
      <Product />
    </>
  );
};

export default ProductPage;
