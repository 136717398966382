import React from 'react';
import { ContractScope } from '@smarterbiz/fixedlegal-shared';
import { FormGroup, Row, Col } from 'react-bootstrap';
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';
import { ContractConfigurationFormType } from './types';
import { Label } from '../../Form/Label';

type Props = {
  item: ContractScope;
};

const ContractOptionCheckboxes = ({ item }: Props) => {
  const { values } = useFormikContext<ContractConfigurationFormType>();

  return (
    <div className="my-4">
      <FieldArray
        name="scopes"
        render={(helpers: ArrayHelpers) => {
          return item.values.map((value, i) => (
            <FormGroup as={Row} key={value.key} controlId={value.key}>
              <Col xs={4} className="text-center">
                <input
                  id={value.key}
                  type="checkbox"
                  name={`scopes.${i}.type`}
                  className="form-check-input"
                  value={value.key}
                  defaultChecked={
                    (item.defaultValue as string[]).indexOf(value.key) !== -1
                      ? true
                      : false
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const id = values.scopes
                      ? values.scopes.findIndex(
                          (scope) => scope.inclusion === value.key
                        )
                      : 0;

                    if (e.target.checked) {
                      helpers.push({ type: item.type, inclusion: value.key });
                    } else {
                      helpers.remove(id);
                    }
                  }}
                />
              </Col>
              <Col xs={7}>
                <Label>{value.desc}</Label>
              </Col>
            </FormGroup>
          ));
        }}
      />
    </div>
  );
};

export default ContractOptionCheckboxes;
