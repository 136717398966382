import React, { forwardRef } from 'react';
import {
  ErrorMessage,
  FieldHookConfig,
  useField,
  useFormikContext,
} from 'formik';
import styled, { CSSProp, DefaultTheme } from 'styled-components/macro';
import { Input, StyledInput } from './Input';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  ref?: any;
  hideValue?: boolean;
  css?: CSSProp;
  children?: React.ReactNode;
};

type InputProps = Props & FieldHookConfig<string>;

const ErrorContainer = styled.div`
  width: 100%;
  min-height: 1rem;
  max-height: fit-content;
  padding-top: 5px;
`;

const ErrorText = styled.p`
  font-size: 16px;
  font-family: var(--font-avenir);
  line-height: 15px;
  color: var(--fl-warning-primary);
`;

/**
 * @summary Number input to be used within formik forms
 * @note Requires FormikProvider to work
 */
export const FormikNumberInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField({ ...props, name: props.name });

    return (
      <NumberFormat
        getInputRef={ref}
        {...field}
        allowNegative={false}
        decimalScale={0}
        customInput={StyledInput}
        style={{
          border: meta.error && meta.touched ? '1px solid #96120B' : 'none',
        }}
        disabled={props.disabled}
        onValueChange={(values: NumberFormatValues) => {
          const { floatValue } = values;
          setFieldValue(props.name, Number(floatValue));
        }}
      />
    );
  }
);

export const FormikTextInput = ({ hideValue, ...props }: InputProps) => {
  const [field, meta] = useField({ ...props, name: props.name });

  return (
    <>
      <InputWrapper>
        <Input
          {...field}
          type={props.type}
          style={{
            border: meta.error && meta.touched ? '1px solid #96120B' : 'none',
            color: hideValue ? 'transparent' : '',
          }}
          disabled={props.disabled}
          placeholder={props.placeholder}
          value={field.value || ''}
        />
      </InputWrapper>
      <InputWrapper>
        <ErrorContainer>
          {meta.error && meta.touched ? (
            <ErrorText>{meta.error}</ErrorText>
          ) : null}
        </ErrorContainer>
      </InputWrapper>
    </>
  );
};

export const StyledLabel = styled.label`
  padding-left: 0.5rem;
  font-family: var(--font-avenir);
`;

const InputWrapper = styled.div`
  margin-bottom: 0.3rem;
`;

const StyledErrorMessage = styled.p`
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: var(--fl-warning-primary);
  font-family: var(--font-avenir);
`;

export const FormikCheckInput = ({ children, ...props }: InputProps) => {
  const [field, meta] = useField({ ...props, name: props.name });

  return (
    <>
      <input
        {...field}
        id={props.id}
        type={props.type}
        style={props.style}
        disabled={props.disabled}
        className="form-check-input"
      />
      <StyledLabel htmlFor={props.id}>{children}</StyledLabel>
      <ErrorMessage component={StyledErrorMessage} name={props.name} />
    </>
  );
};
