import styled from 'styled-components';

type Props = {
  bold?: boolean;
};

const StyledErrorMessage = styled.p<Props>`
  color: var(--fl-warning-primary);
  font-family: var(--font-avenir);
  font-weight: ${(props) => (props.bold ? 'var(--bold)' : 'var(--regular)')};
  font-size: 16px;
`;

export default StyledErrorMessage;
