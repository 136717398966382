import { Col, Row, Image } from 'react-bootstrap';
import { Text } from '../Text';
import Check from '../../assets/checkmark.png';
import Profit from '../../assets/profit.png';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import ReactMarkdown from 'react-markdown';
import Steps from '../Steps';
import { H2 } from '../Headings';
import { PageSection } from '../Sections';
import { usePageContentsQuery } from '../../api/strapi';
import { useMemo } from 'react';

const StyledLink = styled(Link)`
  color: var(--blue);
`;

const bg = css<{}>`
  background-color: var(--fl-bg-secondary);
`;

export const Product = () => {
  const { data } = usePageContentsQuery();

  const typesData = useMemo(() => {
    return data?.find((content) => content.id === 4);
  }, [data]);

  const featsData = useMemo(() => {
    return data?.find((content) => content.id === 5);
  }, [data]);

  const dealsData = useMemo(() => {
    return data?.find((content) => content.id === 6);
  }, [data]);

  return (
    <>
      <PageSection css={bg}>
        <Row className="justify-content-center">
          <Col xl={10} className="text-center">
            <H2>
              Ainutlaatuinen sopimusten ja muiden liiketoiminnan dokumenttien
              laadintapalvelu
            </H2>
          </Col>
          <Col xl={10} className="ml-4 align-items-center">
            <Row style={{ marginTop: '5rem' }}>
              <Col xs={2} sm={2} md={2} className="d-flex justify-content-end pb-2">
                <Image
                  src={Check}
                  width="30px"
                  height="30px"
                  alt="Fixed Legal valintaruutu"
                />
              </Col>
              <Col className="mt-2">
                <Text size="md">
                  Säästä aikaa ja rahaa - saat pätevät sopimukset helposti,
                  nopeasti ja edullisesti
                </Text>
              </Col>
            </Row>
            <Row className="mt-4 align-items-center">
              <Col xs={2} sm={2} md={2} className="d-flex justify-content-end pb-2">
                <Image
                  src={Check}
                  width="30px"
                  height="30px"
                  alt="Fixed Legal valintaruutu"
                />
              </Col>
              <Col className="mt-2">
                <Text size="md">
                  Vie diilit sujuvasti maaliin sopivilla ehdoilla - saat ainutlaatuisen ehtojen räätälöinnin tarpeisiisi
                </Text>
              </Col>
            </Row>
            <Row className="mt-4 align-items-center">
              <Col xs={2} sm={2} md={2} className="d-flex justify-content-end pb-2">
                <Image
                  src={Check}
                  width="30px"
                  height="30px"
                  alt="Fixed Legal valintaruutu"
                />
              </Col>
              <Col className="mt-2">
                <Text size="md">
                  Vakuuta sopimuskumppanisi ammattimaisilla ja pätevillä
                  sopimuksilla
                </Text>
              </Col>
            </Row>
            <Row className="mt-4 align-items-center">
              <Col xs={2} sm={2} md={2} className="d-flex justify-content-end pb-2">
                <Image
                  src={Check}
                  width="30px"
                  height="30px"
                  alt="Fixed Legal valintaruutu"
                />
              </Col>
              <Col className="mt-2">
                <Text size="md">Hallitse ja vähennä sopimusriskejä</Text>
              </Col>
            </Row>
            <Row className="mt-4 align-items-center">
              <Col xs={2} sm={2} md={2} className="d-flex justify-content-end pb-2">
                <Image
                  src={Check}
                  width="30px"
                  height="30px"
                  alt="Fixed Legal valintaruutu"
                />
              </Col>
              <Col className="mt-2">
                <Text size="md">
                  Pysy ajan hermolla sopimuksilla, jotka päivitetään lakien ja sopimuskäytäntöjen muuttuessa
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageSection>
      <PageSection>
        <Row className="mt-5 mb-5 text-center justify-content-center">
          <H2>Mitä Fixed Legal sisältää?</H2>
        </Row>
        <Row className="justify-content-center">
          <Col md={5}>
            <ReactMarkdown
              children={typesData?.Content ?? 'Sisältöä ei voitu ladata'}
              components={{
                p: (props: any) => <Text size="md" dimmed {...props} />,
              }}
            />
          </Col>
          <Col md={5}>
            <ReactMarkdown
              children={featsData?.Content ?? 'Sisältöä ei voitu ladata'}
              components={{
                p: (props: any) => <Text size="md" dimmed {...props} />,
              }}
            />
          </Col>
        </Row>
      </PageSection>
      <PageSection css={bg}>
        <Row className="justify-content-center">
          <Col md={5}>
            <div className="pb-4">
              <H2>Kaikenlaisiin diileihin</H2>
            </div>
            <ReactMarkdown
              children={dealsData?.Content ?? 'Sisältöä ei voitu ladata'}
              components={{
                p: (props: any) => <Text size="md" dimmed {...props} />,
              }}
            />
            <div className="pt-4">
              <Text size="md">
                <StyledLink to="/tee-sopimus">
                  Kokeile palvelua tästä
                </StyledLink>
              </Text>
            </div>
          </Col>
          <Col md={5}>
            <Image style={{ maxWidth: '500px' }} width="100%" src={Profit} />
          </Col>
        </Row>
      </PageSection>
      <Steps />
      <Footer />
    </>
  );
};
