import { GeneratedContract } from '@smarterbiz/fixedlegal-shared';
import { Text } from '../Text';
import { fiFormatDate } from '../../helpers/date';
import styled from 'styled-components';

type Props = {
  documents: GeneratedContract[];
};

export const DocumentsList = ({ documents }: Props) => {
  return (
    <>
      {documents
        .sort(
          (a, b) =>
            new Date(b.generatedOn).getTime() -
            new Date(a.generatedOn).getTime()
        )
        .map((doc, i) => (
          <ListItem key={`generated-contract-${i}`}>
            <Text size="sm">
              {doc.name} {fiFormatDate(doc.generatedOn)}
            </Text>
            <div className="ml-4">
              <a href={`/api/v2/generated-contracts/${doc.id}`} download>
                LATAA WORD (DOCX)
              </a>
            </div>
          </ListItem>
        ))}
    </>
  );
};

const ListItem = styled.div`
  padding-bottom: 1.9rem;
`;
