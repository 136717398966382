import { css } from 'styled-components';
import { mediaLargeMax, mediaSmallMax } from '../../theme/media';
import { mediaSmallMin } from '../../theme/media';
import { mediaMediumMax } from '../../theme/media';

export const buttonBase = css`
  min-width: fit-content;
  min-height: 50px;
  padding: 10px;
  width: 95px;
  border: 0;
  border-radius: 45px;
  background-color: var(--fl-white);
  color: var(--dark-blue);
  box-shadow: var(--shadow);
  font-family: var(--font-avenir);
  font-weight: var(--regular);
`;

const smButtonPadding = css`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`;

const mdButtonPadding = css`
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

const lgButtonPadding = css`
  padding-right: 3.5rem;
  padding-left: 3.5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
`;

export const xs = css`
  font-size: 14px;
  ${smButtonPadding}
`;

export const sm = css`
  font-size: 18px;
  ${smButtonPadding}
`;

export const md = css`
  font-size: 20px;
  border-radius: 143px;
  ${mdButtonPadding};

  @media ${mediaLargeMax} {
    font-size: 18px;
    ${smButtonPadding};
  }
`;

export const lg = css`
  font-size: 22px;
  padding: 30px;
  border-radius: 143px;
  ${lgButtonPadding}
  @media ${mediaLargeMax} {
    font-size: 18px;
    ${smButtonPadding}
  }
`;
