import {
  BillingEventData,
  BillingEventRequest,
  ContractContextData,
  ContractGenerationData,
  ContractOverview,
  GeneratedContract,
} from '@smarterbiz/fixedlegal-shared';
import { authApi, authTags } from './auth';
import { rootApi } from '.';

export const fixedTags = {
  contracts: 'Contracts',
  documents: 'Documents',
};

const fixedApiWithTags = rootApi.enhanceEndpoints({
  addTagTypes: Object.values(fixedTags),
});

export const fixedApi = fixedApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    contracts: builder.query<ContractOverview[], void>({
      providesTags: [fixedTags.contracts],
      query: () => ({
        url: '/contracts',
        method: 'GET',
      }),
      transformResponse: (contracts: ContractOverview[]) => {
        const withoutAppendixes = contracts.filter(
          // for now only return finnish contracts
          (contract) => !contract.isAppendix && contract.lang === 'FI'
        );
        return withoutAppendixes;
      },
    }),
    appendixes: builder.query<ContractOverview[], void>({
      query: () => ({
        url: '/contracts',
        method: 'GET',
      }),
      transformResponse: (appendixes: ContractOverview[]) => {
        const withoutContracts = appendixes.filter(
          // for now only return finnish appendixes
          (contract) => contract.isAppendix && contract.lang === 'FI'
        );
        return withoutContracts;
      },
    }),
    contract: builder.query<ContractContextData, { id: string; lang: string }>({
      query: ({ id, lang }) => ({
        url: `/contracts/${id}/${lang}`,
        method: 'GET',
      }),
    }),
    createContract: builder.mutation<GeneratedContract, ContractGenerationData>(
      {
        invalidatesTags: [fixedTags.documents],
        query: (data) => ({
          url: `/v2/contracts/${data.id}`,
          method: 'POST',
          body: data,
        }),
        async onQueryStarted(arg, api) {
          await api.queryFulfilled;
          // sets user data as invalid, meaning it is refreshed with new data it is called past this point
          api.dispatch(authApi.util.invalidateTags([authTags.user]));
        },
      }
    ),
    myDocuments: builder.query<GeneratedContract[], void>({
      providesTags: [fixedTags.documents],
      query: () => ({
        url: '/v2/generated-contracts',
        method: 'GET',
      }),
    }),
    billingEvents: builder.query<BillingEventData[], BillingEventRequest>({
      query: (query) => ({
        url: `/admin/billing/events?from=${query.from}&alsoBilled=${query.alsoBilled}`,
        method: 'GET',
      }),
    }),
    contractErrors: builder.query<Record<string, any>, void>({
      query: () => ({
        url: '/admin/errorlist',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyContractQuery,
  useContractsQuery,
  useAppendixesQuery,
  useCreateContractMutation,
  useMyDocumentsQuery,
  useLazyBillingEventsQuery,
  useBillingEventsQuery,
  useContractErrorsQuery,
} = fixedApi;
