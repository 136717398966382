import axios from 'axios';

export const fetchBlogs = async () => {
  const response = await axios.get('/sisalto/blogs');

  return response.data;
};

export const fetchBlogById = async (id?: string) => {
  const response = await axios.get(`/sisalto/blogs/${id}`);

  return response.data;
};
