import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

export const HeroSection = ({ children }: Props) => {
  return <StyledHeroSection>{children}</StyledHeroSection>;
};

const StyledHeroSection = styled.section`
  min-height: calc(100vh - 30vh);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: var(--fl-bg-secondary);
`;
