import { Helmet, HelmetProps } from 'react-helmet-async';

type Props = Partial<HelmetProps>;

export const Head = ({ title }: Props) => {
  return (
    <Helmet>
      <meta
        name="description"
        content="Luo pätevät liike-elämän sopimukset helposti, nopeasti ja edullisesti. Salassapitosopimus, konsulttisopimus ja monia muita. Fixed Legal tarjoaa ainutlaatuisen sopimusten räätälöinnin yrityksesi tarpeisiin. Kokeile sitoumuksetta."
      />
      <meta
        name="keywords"
        content="fixed legal,sopimus,sopimuspohja,sopimusmalli,salassapitosopimus,konsulttisopimus,toimitussopimus,alihankintasopimus"
      />
      <meta name="author" content="Fixed Legal" />
      {title ? (
        <title>Fixed-legal | {title}</title>
      ) : (
        <title>Fixed-legal</title>
      )}
    </Helmet>
  );
};
