import { Modal as BootstrapModal, Spinner } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { ModalProps, closeModal } from './modalSlice';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { StyledModal, StyledModalTitle } from './Styled';
import { usePageContentQuery } from '../../api/strapi';

const StrapiModal = ({ ...rest }: ModalProps) => {
  const dispatch = useAppDispatch();
  const { isLoading, data, isError } = usePageContentQuery(rest.strapiId);

  return (
    <StyledModal
      aria-labelledby="modal"
      show={rest.visible}
      onHide={() => dispatch(closeModal())}
      size="lg"
      centered
    >
      <BootstrapModal.Header closeButton className="border-0">
        <StyledModalTitle>{rest.title ?? null}</StyledModalTitle>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : null}
        {isError ? (
          <ReactMarkdown
            children="Sisältöä ei voitu ladata"
            components={{
              p: (props: any) => <Text size="md" dimmed {...props} />,
            }}
          />
        ) : null}
        {data ? (
          <ReactMarkdown
            children={data.Content}
            components={{
              p: (props: any) => <Text size="md" dimmed {...props} />,
            }}
          />
        ) : null}
      </BootstrapModal.Body>
      <BootstrapModal.Footer className="border-0 d-flex flex-row justify-content-between">
        <Button onClick={() => dispatch(closeModal())}>Sulje</Button>
      </BootstrapModal.Footer>
    </StyledModal>
  );
};

export default StrapiModal;
