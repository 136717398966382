import { useAuth } from '../redux/hooks';
import ProtectedContractsPage from '../pages/contracts/ProtectedContracts';
import ContractsPage from '../pages/contracts/Contracts';

const ContractRouteResolver = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <ProtectedContractsPage />;
  }
  return <ContractsPage />;
};

export default ContractRouteResolver;
