import { UserRole } from '@smarterbiz/fixedlegal-shared';
import { useMeQuery } from '../../api/auth';
import { BillingEvents } from '../../components/BillingEvents';
import { Navigate } from 'react-router-dom';

const BillingEventsPage = () => {
  const { data: user } = useMeQuery();
  if (user && user?.role !== UserRole.ADMIN) {
    return <Navigate to="/" />;
  }

  return <BillingEvents />;
};

export default BillingEventsPage;
