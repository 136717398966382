import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

// TODO: axios is not used anymore, remove it
const api = axios.create();

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err: AxiosError<Error>) => {
    return Promise.reject(err);
  }
);

export default api;

export const urls = {
  fixed: '/api',
  strapi: '/sisalto',
} as const;

const unAuthorizedModalPayload = {
  modalType: 'INFO_MODAL',
  modalProps: {
    visible: true,
    title: 'Istuntosi on vanhentunut',
    content: 'Sinun täytyy kirjautua sisään, jotta voit jatkaa.',
  },
};

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, opts) => {
  const rawFetchBaseQuery = fetchBaseQuery({
    baseUrl: urls.fixed,
  });

  const result = await rawFetchBaseQuery(args, api, opts);

  const req = args as FetchArgs;

  if (req.url === '/checkAuth') {
    if (result.error && result.error.status === 401) {
      api.dispatch({ type: 'auth/setAuthStatus', payload: false });
      api.dispatch({
        type: 'modal/openModal',
        payload: unAuthorizedModalPayload,
      });
    }
  }

  return result;
};

export const rootApi = createApi({
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
});
