import styled from 'styled-components';

const ListItem = styled.li``;

const UnorderedList = styled.ul`
  ::marker {
    color: blue !important;
  }
`;

export { UnorderedList, ListItem };
