import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  StrapiBlog,
  StrapiContractInfo,
  StrapiPageContent,
} from '../fe-common';
import { urls } from '.';

export const strapiApi = createApi({
  reducerPath: 'strapiApi',
  baseQuery: fetchBaseQuery({
    baseUrl: urls.strapi,
    // baseUrl: 'https://www.fixed-legal.com' + urls.strapi,
  }),
  endpoints: (builder) => ({
    contractInfo: builder.query<StrapiContractInfo[], string>({
      query: (id) => `/contract-infos?ContractId=${id}`,
    }),
    pageContents: builder.query<StrapiPageContent[], void>({
      query: () => '/page-contents',
    }),
    pageContent: builder.query<StrapiPageContent, string>({
      query: (id) => `/page-contents/${id}`,
    }),
    blogs: builder.query<StrapiBlog[], void>({
      query: () => '/blogs',
    }),
    blog: builder.query<StrapiBlog, string>({
      query: (id) => `/blogs/${id}`,
    }),
  }),
});

export const {
  useContractInfoQuery,
  usePageContentsQuery,
  useLazyPageContentQuery,
  usePageContentQuery,
  useBlogsQuery,
  useBlogQuery,
  useLazyBlogQuery,
} = strapiApi;
