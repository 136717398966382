import axios from 'axios';
import { useEffect, useState } from 'react';
import { Col, Container, Image, Row, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import checkmark from '../../assets/checkmark.png';
import { H3 } from '../Headings';
import { Text } from '../Text';

const StyledConfirmationContainer = styled(Col)`
  margin-top: 5rem;
`;

const StyledConfirmationContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1rem;
  width: 100%;
`;

export const EmailConfirmation = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const urlParams = useParams<{ id: string }>();

  useEffect(() => {
    if (urlParams.id) {
      axios
        .post('/api/signup/confirm', { confirmId: urlParams.id })
        .then(() => {
          setLoading(false);
        })
        .catch((err: Error) => {
          setError(err);
          setLoading(false);
        });
    }
  }, []);

  // if (error) {
  //   return <p>{error.message}</p>;
  // }

  return (
    <Container>
      <Row className="justify-content-center">
        <StyledConfirmationContainer xs={11} md={10} lg={6} xl={6}>
          <StyledConfirmationContent>
            {loading ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <div>
                <H3>Sähköpostiosoitteesi on nyt vahvistettu!</H3>
                <Text>
                  Voit jatkaa palveluun kirjautumalla sisään. Dokumenttisi ovat
                  ladattavissa Dokumenttini-osiossa kirjautumisen jälkeen.
                </Text>
                <div className="mt-5">
                  <Image src={checkmark} width="120px" fluid />
                </div>
              </div>
            )}
          </StyledConfirmationContent>
        </StyledConfirmationContainer>
      </Row>
    </Container>
  );
};
