import { ErrorMessage } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { FormikNumberInput } from '../../Form/FormikInput';
import { Text } from '../../Text';
import { useInfoModal } from '../../../features/modal/hooks';
import { Parties } from './ContractTerm';

const subscriptionContractText =
  `Sopimus voi esim. olla voimassa ensialkuun 24 kuukauden vähimmäisjakson sopimuksen
  allekirjoituksesta. Sen aikana sopimusta ei voida irtisanoa päättymään ennen vähimmäisjakson eli 24 kuukauden loppua. Tämän jälkeen sopimus uusiutuu automaattisesti jaksottain esimerkiksi 12 kuukauden jaksoissa niin kauan, kunnes sopimus
  irtisanotaan päättymään jakson loppuun. Kun irtisanomisaika on 6 kuukautta, sopijapuolen tulee esimerkkitapauksessa antaa ilmoitus sopimuskumppanilleen viimeistään 6
  kuukautta ennen 24 kuukautta kestävän vähimmäisjakson loppua tai 12 kuukautta kestävän
  uusiutumisjakson loppua. Sopimus päättyy tällöin kuluvan jakson lopussa.`.replace(
    /\s+/g,
    ' '
  );

const subscriptionText =
  `Määritä sekä sopimusjaksojen että irtisanomisajan pituus. Sopimus
  uusiutuu jaksoittain (esim. 12kk kerrallaan), kunnes se
  irtisanotaan. Kun kumpi tahansa on antanut irtisanomisilmoituksen,
  sopimus päättyy kuluvan jakson loppuun. Irtisanomisaika määrittelee,
  kuinka monta kuukautta ennen kuluvan jakson loppua
  irtisanomisilmoitus tulee antaa.
`.replace(/\s+/g, ' ');

const ErrorText = styled.p`
  color: var(--fl-warning-primary);
`;

type Props = {
  parties: Parties;
  isDisabled: boolean;
};

export const SubscriptionTerm = ({ parties, isDisabled }: Props) => {
  const dispatchInfoModal = useInfoModal();

  return (
    <Col md={10} className="m-2">
      <Form.Group as={Row}>
        <Col className="pt-4" md={10}>
          <Text>{subscriptionText}</Text>
        </Col>
        <Col className="pt-4" md={2}>
          <AiOutlineInfoCircle
            size="2rem"
            color="#2675f9"
            onClick={() =>
              dispatchInfoModal({
                title: 'Jaksoittain uusiutuva',
                content: subscriptionContractText,
              })
            }
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col md={12} lg={6} xl={4}>
          <Form.Label htmlFor="initialTermInMonths">
            Syötä 1. jakson kesto allekirjoittamispäivästä:
          </Form.Label>
        </Col>
        <Col xs={8} md={8} lg={2} className="btn-group-vertical">
          <FormikNumberInput name="initialTermInMonths" disabled={isDisabled} />
        </Col>
        <Col className="btn-group-vertical">
          <Text>Kuukautta</Text>
        </Col>
        <ErrorMessage component={ErrorText} name="initialTermInMonths" />
      </Form.Group>
      <Form.Group as={Row}>
        <Col md={12} lg={6} xl={4}>
          <Form.Label htmlFor="renewalTermInMonths">
            Syötä seuraavien jaksojen kesto edellisen jakson lopusta:
          </Form.Label>
        </Col>
        <Col xs={8} md={8} lg={2} className="btn-group-vertical">
          <FormikNumberInput name="renewalTermInMonths" disabled={isDisabled} />
        </Col>
        <Col className="btn-group-vertical">
          <Text>Kuukautta</Text>
        </Col>
        <ErrorMessage component={ErrorText} name="renewalTermInMonths" />
      </Form.Group>
      <br />
      <Form.Group as={Row}>
        <Col md={12} lg={6} xl={4}>
          <Form.Label htmlFor="noticePeriodParty1InDays">
            {parties.party1}: syötä irtisanomisaika ennen kuluvan jakson loppua
          </Form.Label>
        </Col>
        <Col xs={8} md={8} lg={2} className="btn-group-vertical">
          <FormikNumberInput
            name="noticePeriodParty1InDays"
            disabled={isDisabled}
          />
        </Col>
        <Col className="btn-group-vertical">
          <Text>Päivää</Text>
        </Col>
        <ErrorMessage component={ErrorText} name="noticePeriodParty2InDays" />
      </Form.Group>
      <Form.Group as={Row}>
        <Col md={12} lg={6} xl={4}>
          <Form.Label htmlFor="noticePeriodParty2InDays">
            {parties.party2}: syötä irtisanomisaika ennen kuluvan jakson loppua
          </Form.Label>
        </Col>
        <Col xs={8} md={8} lg={2} className="btn-group-vertical">
          <FormikNumberInput
            name="noticePeriodParty2InDays"
            disabled={isDisabled}
          />
        </Col>
        <Col className="btn-group-vertical">
          <Text>Päivää</Text>
        </Col>
        <ErrorMessage component={ErrorText} name="noticePeriodParty2InDays" />
      </Form.Group>
    </Col>
  );
};
