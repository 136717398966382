import { Lang } from '@smarterbiz/fixedlegal-shared';
import {
  object,
  number,
  string,
  boolean,
  ref,
  mixed,
  array,
  ObjectSchema,
} from 'yup';

export const LoginSchema = object().shape({
  email: string()
    .email('Sähköpostiosoite pitää olla oikeassa muodossa')
    .required('Anna sähköpostiosoite')
    .transform((value) => value),
  password: string().required('Anna salasana'),
});

const address = string()
  .min(3, 'Kadunnimessä täytyy olla vähintään 3 merkkiä')
  .max(80)
  .required('Lisää lähiosoite');
const addressCity = string()
  .min(2, 'Kaupungissa täytyy olla vähintään 2 merkkiä')
  .max(80)
  .required('Lisää kaupunki');
const addressPostcode = string()
  .min(3, 'Postinumerossa täytyy olla vähintään 3 merkkiä')
  .max(80)
  .required('Lisää postinumero');

const addressCityPostCode = string()
  .min(8, 'Postinumerossa ja kaupungissa täytyy olla vähintään 8 merkkiä')
  .max(80, 'Postinumerossa ja kaupungissa saa olla enintään 80 merkkiä')
  .required('Lisää postinumero ja kaupunki');

export const AccountSchema = object().shape({
  phone: string()
    .min(6, 'Puhelinnumeron täytyy sisältää vähintään 6 merkkiä')
    .max(20, 'Puhelinnumero voi sisältää enintään 20 merkkiä')
    .required('Lisää puhelinnumero'),
  firstName: string()
    .required('Lisää etunimesi')
    .max(50, 'Etunimi voi sisältää enintään 50 merkkiä'),
  lastName: string()
    .required('Lisää sukunimesi')
    .max(50, 'Sukunimi voi sisältää enintään 50 merkkiä'),
  email: string().email().required('Lisää sähköpostisi'),
  companyName: string().required('Lisää nimi'),
  companyId: string()
    .min(9, 'Y-tunnuksen täytyy sisältää vähitään 9 merkkiä')
    .max(20, 'Y-tunnus voi sisältää enintään 20 merkkiä')
    .required('Lisää y-tunnus'),
  address: address,
  addressPostcodeCity: addressCityPostCode,
  eInvoiceAddress: string().when('wantsEmailInvoice', {
    is: false,
    then: string()
      .min(10, 'Verkkolaskuosoitteen täytyy sisältää vähintään 10 merkkiä')
      .max(30, 'Verkkolaskuosoite voi sisältää enintään 30 merkkiä')
      .required('Lisää verkkolaskuosoite'),
    otherwise: string(),
  }),
  eInvoiceOperatorId: string().when('wantsEmailInvoice', {
    is: false,
    then: string()
      .min(5, 'Operaattoritunnuksen täytyy sisältää vähintään 5 merkkiä')
      .max(30, 'Operaattoritunnus voi sisältää enintään 30 merkkiä')
      .required('Lisää operaattoritunnus'),
    otherwise: string(),
  }),
  password: string()
    .min(4, 'Salasanan täytyy olla vähintään 4 merkkiä pitkä')
    .max(40, 'Salasana voi olla enintään 40 merkkiä pitkä')
    .required('Anna salasana'),
  repeatPassword: string()
    .oneOf([ref('password'), undefined], 'Salasanat eivät täsmää')
    .required('Syötä salasana uudelleen'),
  agreeTerms: boolean().oneOf([true], 'Sinun täytyy hyväksyä käyttöehdot'),
  agreeDataProcessing: boolean().oneOf(
    [true],
    'Sinun täytyy vahvistaa lukeneesi tietosuojaseloste'
  ),
});

export const passwordChangeSchema = object({
  newPassword: string()
    .min(4, 'Salasanan täytyy olla vähintään 4 merkkiä pitkä')
    .max(40, 'Salasana voi olla enintään 40 merkkiä pitkä')
    .required('Anna salasana'),
  repeatPassword: string()
    .oneOf([ref('newPassword'), undefined], 'Salasanat eivät täsmää')
    .required('Syötä salasana uudelleen'),
});

export const ProfileSchema = object().shape({
  companyName: string(),
  address: address,
  addressCityPostCode: addressCityPostCode,
  firstName: string()
    .min(2, 'Etunimessä täytyy olla vähintään 2 merkkiä')
    .max(30)
    .required('Lisää nimesi'),
  lastName: string()
    .min(2, 'Sukunimessä täytyy olla vähintään 2 merkkiä')
    .max(30)
    .required('Lisää sukunimesi'),
});

export const ChangePasswordSchema = object({
  currentPassword: string().required('Syötä salasanasi'),
  newPassword: string().required('Syötä uusi salasana'),
  repeatNewPassword: string()
    .oneOf([ref('newPassword'), undefined], 'Uudet salasanat eivät täsmää')
    .required('Syötä uusi salasana uudelleen'),
});

const party1Validation = object().shape({
  name: string().when('validate', {
    is: true,
    then: string().required('Lisää nimi'),
  }),
  address: string().when('validate', {
    is: true,
    then: string().required('Lisää lähiosoite'),
  }),
  addressCityCode: string().when('validate', {
    is: true,
    then: string().required('Lisää postinumero ja kaupunki'),
  }),
});

const party2Validation = object().shape({
  name: string().when('validate', {
    is: true,
    then: string().required('Lisää nimi'),
  }),
  address: string().when('validate', {
    is: true,
    then: string().required('Lisää lähiosoite'),
  }),
  addressCityCode: string().when('validate', {
    is: true,
    then: string().required('Lisää postinumero ja kaupunki'),
  }),
});

// partyn allekirjoitukset
const partySignatureValidations = object().shape({
  signeeName: string().when('signLater', {
    is: (signLater: boolean) => {
      if (!signLater) {
        return true;
      }
    },
    then: string().required('Lisää allekirjoittajan nimi'),
  }),
  signeeTitle: string().when('signLater', {
    is: (signLater: boolean) => {
      if (!signLater) {
        return true;
      }
    },
    then: string().required('Lisää allekirjoittajan titteli'),
  }),
  signeeAddress: string().when('signLater', {
    is: (signLater: boolean) => {
      if (!signLater) {
        return true;
      }
    },
    then: string().required('Lisää allekirjoituspaikka'),
  }),
});

const scopes = array(
  object({
    type: string().optional(),
    inclusion: string().optional(),
  })
);

export const appendiceSchema = object({
  scopes: scopes,
});

const noticePeriodParty1InMonthsSchema = number().when(
  ['contractHasTerms', 'continuousTerm'],
  {
    // if contractHasTerms is true and continuous OR subscription is true, then noticePeriodParty1InMonths will be validated
    is: (contractHasTerms: boolean, continuousTerm: boolean) => {
      if (contractHasTerms && continuousTerm) {
        return true;
      }
    },
    then: number()
      .moreThan(0, 'Irtisanomisajan täytyy olla enemmän kuin 0 kuukautta')
      .required('Lisää irtisanomisaika'),
    // if conditions are not met, validation is ignored
    otherwise: (schema: any) => schema.notRequired(),
  }
);

const noticePeriodParty2InMonthsSchema = number().when(
  ['contractHasTerms', 'continuousTerm'],
  {
    // if contractHasTerms is true and continuous OR subscription is true, then noticePeriodParty1InMonths will be validated
    is: (contractHasTerms: boolean, continuousTerm: boolean) => {
      if (contractHasTerms && continuousTerm) {
        return true;
      }
    },
    then: number()
      .moreThan(0, 'Irtisanomisajan täytyy olla enemmän kuin 0 kuukautta')
      .required('Lisää irtisanomisaika'),
    // if conditions are not met, validation is ignored
    otherwise: (schema: any) => schema.notRequired(),
  }
);

const fixedTermInMonthsSchema = number().when(
  ['contractHasTerms', 'fixedTerm'],
  {
    is: (contractHasTerms: boolean, fixedTerm: boolean) => {
      if (contractHasTerms && fixedTerm) {
        return true;
      }
    },
    then: number()
      .moreThan(0, 'Määräajan kesto täytyy olla enemmän kuin 0 kuukautta')
      .required('Lisää määräajan kesto'),
    // if conditions are not met, validation is ignored
    otherwise: (schema: any) => schema.notRequired(),
  }
);

const initialTermInMonthsSchema = number().when(
  ['contractHasTerms', 'subscriptionTerm'],
  {
    is: (contractHasTerms: boolean, subscriptionTerm: boolean) => {
      if (contractHasTerms && subscriptionTerm) {
        return true;
      }
    },
    then: number()
      .moreThan(0, '1. jakson kesto täytyy olla enemmän kuin 0 kuukautta')
      .required('Lisää 1. jakson kesto'),
    // if conditions are not met, validation is ignored
    otherwise: (schema: any) => schema.notRequired(),
  }
);

const renewalTermInMonthsSchema = number().when(
  ['contractHasTerms', 'subscriptionTerm'],
  {
    is: (contractHasTerms: boolean, subscriptionTerm: boolean) => {
      if (contractHasTerms && subscriptionTerm) {
        return true;
      }
    },
    then: number()
      .moreThan(
        0,
        'Seuraavien jaksojen kesto täytyy olla enemmän kuin 0 kuukautta'
      )
      .required('Lisää seuraavien jaksojen kesto'),
    // if conditions are not met, validation is ignored
    otherwise: (schema: any) => schema.notRequired(),
  }
);

export const contractSchema = object({
  lang: mixed<Lang>()
    .oneOf(Object.values(Lang))
    .required('Valitse sopimuksen kieli'),
  relation: mixed<'supplier' | 'customer'>()
    .oneOf(['supplier', 'customer'])
    .default('supplier')
    .required('Valitse sopimuksen suhde'),
  scopes: scopes,
  noticePeriodParty1InMonths: noticePeriodParty1InMonthsSchema,
  noticePeriodParty2InMonths: noticePeriodParty2InMonthsSchema,
  fixedTermInMonths: fixedTermInMonthsSchema,
  initialTermInMonths: initialTermInMonthsSchema,
  renewalTermInMonths: renewalTermInMonthsSchema,
  party1: party1Validation,
  party2: party2Validation,
  party1Signatures: partySignatureValidations,
  party2Signatures: partySignatureValidations,
});

export const contractSchemaWithoutSignatures: ObjectSchema<any> = object({
  lang: mixed<Lang>()
    .oneOf(Object.values(Lang))
    .required('Valitse sopimuksen kieli'),
  relation: mixed<'supplier' | 'customer'>()
    .oneOf(['supplier', 'customer'])
    .default('supplier')
    .required('Valitse sopimuksen suhde'),
  scopes: scopes,
  noticePeriodParty1InMonths: noticePeriodParty1InMonthsSchema,
  noticePeriodParty2InMonths: noticePeriodParty2InMonthsSchema,
  fixedTermInMonths: fixedTermInMonthsSchema,
  initialTermInMonths: initialTermInMonthsSchema,
  renewalTermInMonths: renewalTermInMonthsSchema,
});

export const saasContractSchema: ObjectSchema<any> = object({
  lang: mixed<Lang>()
    .oneOf(Object.values(Lang))
    .required('Valitse sopimuksen kieli'),
  relation: mixed<'supplier' | 'customer'>()
    .oneOf(['supplier', 'customer'])
    .default('supplier')
    .required('Valitse sopimuksen suhde'),
  scopes: scopes,
  noticePeriodParty1InMonths: noticePeriodParty1InMonthsSchema,
  noticePeriodParty2InMonths: noticePeriodParty2InMonthsSchema,
  fixedTermInMonths: fixedTermInMonthsSchema,
  initialTermInMonths: initialTermInMonthsSchema,
  renewalTermInMonths: renewalTermInMonthsSchema,
});
