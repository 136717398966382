import {
  Modal as BootstrapModal,
  ModalProps,
  ModalTitle,
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { Button } from '../Button';
import { Text } from '../Text';

const StyledModal = styled(BootstrapModal)``;

const StyledModalTitle = styled(ModalTitle)`
  color: var(--blue);
`;

type Props = ModalProps & {
  title: string;
  body: string;
  isOpen: boolean;
  handleClose: () => void;
  withCallback?: {
    cb: () => void;
    buttonText: string;
  };
};

export const Modal = ({
  title,
  body,
  isOpen,
  handleClose,
  withCallback,
  ...rest
}: Props) => {
  return (
    <StyledModal
      aria-labelledby="modal"
      show={isOpen}
      onHide={handleClose}
      centered
      {...rest}
    >
      <BootstrapModal.Header closeButton className="border-0">
        <StyledModalTitle>{title}</StyledModalTitle>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <ReactMarkdown
          children={body}
          components={{
            p: (props: any) => <Text size="md" dimmed {...props} />,
          }}
        />
      </BootstrapModal.Body>
      <BootstrapModal.Footer className="border-0 d-flex flex-row justify-content-between">
        {withCallback ? (
          <Button onClick={() => withCallback.cb()}>
            {withCallback.buttonText}
          </Button>
        ) : null}
        <Button onClick={handleClose}>Sulje</Button>
      </BootstrapModal.Footer>
    </StyledModal>
  );
};
